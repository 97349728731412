<template>
  <Modal
    :model-value="modelValue"
    v-on:update:model-value="value => $emit('update:modelValue', value)"
    title="Delete worker results"
  >
    <div class="control is-expanded" v-if="versions.length">
      <label class="label">Select a worker version</label>
      <span class="select is-fullwidth">
        <select v-model="selectedVersion">
          <option value="" disabled>Worker version</option>
          <option value="__all__">All worker versions</option>
          <option v-for="[id, name] in versions" :key="id" :value="id">
            {{ name }}
          </option>
        </select>
      </span>
    </div>
    <div class="control is-expanded">
      <label class="label">{{ versions.length ? 'Or type' : 'Type' }} a worker version UUID</label>
      <input
        type="text"
        placeholder="00000000-0000-0000-0000-000000000000"
        class="input"
        v-model="versionId"
        :class="{ 'is-danger': versionIdError.length }"
        :disabled="loading || null"
      />
    </div>
    <p
      class="help is-danger"
      v-for="err in versionIdError"
      :key="err"
    >
      {{ err }}
    </p>
    <span v-if="pickedWorkerVersion && !versionIdError.length">
      <hr />
      <span>
        You are about to delete all results produced by
        <strong v-if="pickedWorkerVersion === '__all__'">
          every worker version
        </strong>
        <template v-else>
          the chosen worker version
        </template>
        <template v-if="selection">
          under all selected elements
        </template>
        <template v-if="element">
          under element
          <strong>{{ typeName(element.type) }} {{ element.name }} (included)</strong>
        </template>
        <template v-else-if="corpus">
          in project <strong>{{ corpus.name }}</strong>
        </template>.
        <br />
        Child elements of these results will also be deleted recursively.
      </span>
      <br />
      <span>This action is irreversible.</span>
    </span>
    <template v-slot:footer="{ close }">
      <button class="button" v-on:click="close">Cancel</button>
      <button
        class="button is-danger"
        :class="{ 'is-loading': loading }"
        :disabled="!pickedWorkerVersion || versionIdError.length > 0 || null"
        v-on:click="performDelete"
      >
        Delete
      </button>
    </template>
  </Modal>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { corporaMixin } from '@/mixins.js'
import { UUID_REGEX } from '@/config'
import Modal from '@/components/Modal.vue'

export default {
  mixins: [
    corporaMixin
  ],
  components: {
    Modal
  },
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    corpusId: {
      type: String,
      required: true
    },
    elementId: {
      type: String,
      default: null
    },
    selection: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: false,
    selectedVersion: '',
    versionId: '',
    versionIdError: []
  }),
  computed: {
    ...mapGetters('auth', ['isVerified']),
    // canAdmin is already defined in corporaMixin
    ...mapGetters('elements', { canAdminElement: 'canAdmin' }),
    ...mapState('elements', ['elements']),
    element () {
      if (!this.elementId) return
      return this.elements[this.elementId] || null
    },
    hasAdminPrivilege () {
      /*
       * Either these actions are shown for an element and the user has admin rights on the element,
       * or the user has admin rights on the corpus.
       */
      return this.isVerified && (
        (this.elementId && this.canAdminElement(this.elementId)) ||
        (this.corpus && this.canAdmin(this.corpus))
      )
    },
    versions () {
      if (!this.corpus || !this.corpus.worker_versions) return []
      return Object.entries(this.corpus.worker_versions).map(([id, version]) => {
        const ref = !version.revision.refs.length ? version.revision.hash.substring(0, 8) : version.revision.refs.map(ref => ref.name).join('/')
        return [id, version.worker.name + ' (' + ref + ') - ' + version.revision.message]
      })
    },
    // The worker version ID deduced from the two separate input fields.
    pickedWorkerVersion () {
      // The manually input version ID takes priority if it is valid.
      if (this.versionId && !this.versionIdError.length) return this.versionId
      return this.selectedVersion
    }
  },
  methods: {
    async performDelete () {
      if (!this.hasAdminPrivilege || this.versionIdError.length) return
      this.loading = true
      const payload = {
        corpus: this.corpusId,
        element_id: this.elementId,
        use_selection: this.selection
      }
      // Do not include the worker version ID if "all versions" was selected
      if (this.pickedWorkerVersion !== '__all__') payload.worker_version_id = this.pickedWorkerVersion
      try {
        await this.$store.dispatch('corpora/deleteWorkerResults', payload)
        this.$emit('update:modelValue', false)
      } catch (err) {
        if (err.response?.status === 400 && err.response.data && err.response.data.worker_version_id) this.versionIdError = err.response.data.worker_version_id
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    corpus: {
      immediate: true,
      handler (newValue) {
        if (newValue && newValue.id && newValue.worker_versions === undefined) {
          this.$store.dispatch('corpora/listWorkerVersions', { corpusId: newValue.id, recursive: true })
        }
      }
    },
    versionId (newValue) {
      if (newValue.trim().length && !UUID_REGEX.test(newValue.trim())) {
        this.versionIdError = [`'${newValue}' is not a valid UUID.`]
      } else {
        this.versionIdError = []
        // Reset the <select> because an UUID was manually typed
        this.selectedVersion = ''
      }
    }
  }
}
</script>

<style land="sass" scoped>
.dropdown-content > .dropdown-item {
  display: block;
  white-space: nowrap;
}
a.dropdown-item[disabled] {
  color: lightgray !important;
  cursor: not-allowed;
}
.page-select {
  height: 1.75rem;
}
.dropdown-menu.focused {
  display: block;
}
</style>
