export interface HelpItem {
  title?: string
  content: string[]
}

export const SEARCH_HELP: HelpItem[] = [
  {
    title: 'WARNING',
    content: [
      'By default, <code>john</code> matches the <strong>exact</strong> word.',
      'To search for a word in a sentence use <code>*john*</code>'
    ]
  },
  {
    title: 'The OR operator (by default)',
    content: [
      '<code>john OR doe</code> matches all text that contains either <code>john</code> OR <code>doe</code>',
      '<code>john doe</code> does the same thing'
    ]
  },
  {
    title: 'The AND operator',
    content: [
      '<code>john AND doe</code> matches text that contains both <code>john</code> AND <code>doe</code>'
    ]
  },
  {
    title: '- negates a single word',
    content: [
      '<code>-john</code> matches all text that does not contain <code>john</code>'
    ]
  },
  {
    title: '" wraps words together as a phrase',
    content: [
      '<code>"john doe"</code> matches all exact occurrences of the phrase <code>john doe</code>'
    ]
  },
  {
    title: '* enables prefix queries',
    content: [
      '<code>jo*</code> matches text containing words starting with <code>jo</code> (<code>john</code>, <code>josh</code>, <code>joe</code> etc.)'
    ]
  },
  {
    title: '( and ) indicate precedence',
    content: [
      '<code>john bob AND doe</code> looks for text containing <code>john AND doe</code> OR <code>bob AND doe</code>',
      '<code>john (bob AND doe)</code> looks for text containing <code>john</code> OR <code>bob AND doe</code>'
    ]
  },
  {
    title: 'Notes:',
    content: [
      'By default, <code>jean-sebastien</code> is considered two separated words.',
      'To search for the exact name, use quotes: <code>"jean-sebastien"</code>'
    ]
  }
]

export const KEYBOARD_HELP: HelpItem[] = [
  {
    content: [
      '<kbd>?</kbd> Keyboard shortcuts help',
      '<kbd>Esc</kbd> Close any modal dialog'
    ]
  },
  {
    title: 'Element details',
    content: [
      '<kbd>Ctrl</kbd> + <kbd><i class="icon-arrow-left"></i></kbd> Previous element in first parent',
      '<kbd>Ctrl</kbd> + <kbd><i class="icon-arrow-right"></i></kbd> Next element in first parent'
    ]
  },
  {
    title: 'Annotation',
    content: [
      '<kbd>Esc</kbd> Cancel drawing a polygon',
      '<kbd>Enter</kbd> In an annotation form, create or update an element'
    ]
  },
  {
    title: 'Children tree',
    content: [
      '<kbd>m</kbd> Load more children elements'
    ]
  }
]
