<template>
  <tr>
    <td>
      <router-link :to="{ name: 'entity-details', params: { id: entity.id } }">{{ entity.name }}</router-link>
    </td>
    <td>
      <p v-for="[key, value] in sortedMetas" :key="key">
        <strong>{{ key }}</strong>: {{ value }}
      </p>
      <template v-if="!sortedMetas.length">&mdash;</template>
    </td>
    <td>
      <WorkerRunSummary
        v-if="entity.worker_run"
        multiline
        :worker-run-details="entity.worker_run"
      />
      <WorkerVersionDetails
        v-else-if="entity.worker_version_id"
        :worker-version-id="entity.worker_version_id"
      />
      <template v-else>&mdash;</template>
    </td>
  </tr>
</template>

<script>
import { orderBy } from 'lodash'
import WorkerRunSummary from '@/components/Process/Workers/WorkerRuns/WorkerRunSummary.vue'
import WorkerVersionDetails from '@/components/Process/Workers/Versions/Details.vue'

export default {
  components: {
    WorkerRunSummary,
    WorkerVersionDetails
  },
  props: {
    entity: {
      type: Object,
      required: true
    }
  },
  computed: {
    sortedMetas () {
      if (!this.entity.metas) return []
      return orderBy(Object.entries(this.entity.metas), ([key]) => key)
    }
  }
}
</script>
