<template>
  <main class="container is-fluid">
    <h1 class="title">Search in project</h1>

    <div class="columns is-multiline">
      <div class="column is-one-quarter-desktop is-full">
        <span class="select is-fullwidth mb-4">
          <select v-model="selectedCorpusId">
            <option value="" selected disabled>Choose a project</option>
            <option v-for="corpus in indexableCorpora" :key="corpus.id" :value="corpus.id">{{ corpus.name }}</option>
          </select>
        </span>
        <SearchForm
          v-if="corpusId"
          :corpus-id="corpusId"
          :all-facets="documents?.facets"
          :loading="loading"
          v-model="query"
        />
      </div>
      <div class="column">
        <Paginator
          :response="documents"
          bottom-bar
          v-model:page="page"
          v-slot="{ results }"
        >
          <div class="columns is-multiline">
            <div
              class="column is-one-quarter-desktop is-one-third"
              v-for="document in results"
              :key="document.id"
            >
              <SearchResult
                :document="document"
              />
            </div>
          </div>
        </Paginator>
      </div>
    </div>
  </main>
</template>

<script>
import { isEqual } from 'lodash'
import { mapState, mapMutations } from 'vuex'
import { UUID_REGEX } from '@/config'
import { corporaMixin } from '@/mixins'
import Paginator from '@/components/Paginator.vue'
import SearchForm from '@/components/Search/Form'
import SearchResult from '@/components/Search/Result'

export default {
  components: {
    Paginator,
    SearchForm,
    SearchResult
  },
  mixins: [
    corporaMixin
  ],
  props: {
    corpusId: {
      type: String,
      default: '',
      validator: value => value === '' || UUID_REGEX.test(value)
    }
  },
  mounted () {
    this.setDocuments(null)
    if (this.selectedCorpusId) this.search()
    // Support the older search URLs that used ?corpus=… instead of a path parameter
    else if (this.$route.query?.corpus) this.selectedCorpusId = this.$route.query?.corpus
  },
  beforeRouteUpdate (to) {
    this.search(to.params.corpusId, to.query)
  },
  data: () => ({
    loading: false
  }),
  computed: {
    ...mapState('search', ['documents']),
    indexableCorpora () {
      if (!this.corpora) return []
      return Object.values(this.corpora).filter(c => c.indexable)
    },
    selectedCorpusId: {
      get () {
        return this.corpusId
      },
      set (corpusId) {
        const newRoute = { ...this.$route, params: {} }
        if (corpusId) newRoute.params = { corpusId }
        if (!isEqual(this.$route.params, newRoute.params)) this.$router.push(newRoute)
      }
    },
    query: {
      get () {
        return this.$route.query
      },
      set (query) {
        if (!isEqual(this.$route.query, query)) this.$router.push({ ...this.$route, query })
        // If the search button is hit and the query hasn't changed, re-run the search
        else this.search(this.corpusId, query)
      }
    },
    page: {
      get () {
        return Number.parseInt(this.query.page, 10) || 1
      },
      set (page) {
        this.query = { ...this.query, page }
      }
    }
  },
  methods: {
    ...mapMutations('search', ['setDocuments']),
    async search (corpus, query = this.query) {
      // corpus is undefined when clicking 'Search' in the NavBar
      if (typeof corpus === 'undefined') return
      if (!corpus) corpus = this.corpusId
      if (this.loading || !UUID_REGEX.test(corpus)) return
      if (!query.query?.trim()) query = { ...query, query: '*', only_facets: true }
      else query = { ...query, only_facets: false }
      this.loading = true
      try {
        await this.$store.dispatch('search/corpus', {
          ...query,
          corpus
        })
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    corpusId (newValue) {
      // Block using a corpus ID that does not exist or that is not indexable
      if (newValue && !this.corpus?.indexable) this.selectedCorpusId = null
      // Reset results when clicking 'Search' in the NavBar
      if (!newValue) this.setDocuments(null)
    }
  }
}
</script>
