import { TEXT_ORIENTATIONS } from '@/config'

export const highlight = (text, words) => {
  /*
   * Highlight words in a text
   * Returns text chunks with hl property (highlighted)
   *
   * text: string
   * words: array of strings
   */
  let matchPositions = words.map(word => {
    word = word.trim()
    if (!word) return null
    const position = text.toLowerCase().indexOf(word.toLowerCase())
    return position >= 0 ? [position, position + word.length] : null
  })
  // Sort matched positions
  matchPositions = matchPositions.filter(position => position !== null).sort(
    ([startA], [startB]) => startA > startB
  )

  const chunks = []
  let lastMatchPosition = 0
  if (!matchPositions.length) return [{ text, hl: false }]
  matchPositions.forEach(([startPosition, endPosition]) => {
    // Remove included positions
    if (lastMatchPosition >= endPosition) return
    if (lastMatchPosition < startPosition) {
      // Fill unmatched text
      chunks.push({ text: text.slice(lastMatchPosition, startPosition), hl: false })
    }
    if (lastMatchPosition <= startPosition) {
      // Add matched part
      chunks.push({ text: text.slice(startPosition, endPosition), hl: true })
    } else if (lastMatchPosition < endPosition) {
      // Handle deduplication adding only the remaining characters
      chunks.push({ text: text.slice(lastMatchPosition, endPosition), hl: true })
    }
    lastMatchPosition = endPosition
  })
  // Complete with remaining text
  chunks.push({ text: text.slice(lastMatchPosition), hl: false })
  return chunks
}

export const pluralize = (label, count) => count + ' ' + label + (count !== 1 ? 's' : '')

export const ago = from => {
  let diff = (new Date() - from) / 1000
  const steps = [
    [60, 'second'],
    [60, 'minute'],
    [24, 'hour'],
    [30, 'day']
  ]

  for (const [t, name] of steps) {
    if (diff > t) diff = diff / t
    else return Math.floor(diff) + ' ' + name + (Math.floor(diff) > 1 ? 's' : '') + ' ago'
  }
  return from.toISOString().slice(0, 19).replace('T', ' ')
}

export const orientationStyle = (orientation) => {
  // Fall back to default display if text orientation isn't horizontal-lr or horizontal-rl
  const trOrientation = TEXT_ORIENTATIONS[orientation] ?? TEXT_ORIENTATIONS['horizontal-lr']
  const orientationStyle = { 'writing-mode': trOrientation.writing, direction: trOrientation.direction }
  return orientationStyle
}

/**
 * Get the font currently defined in a DOM element, as formatted for the CSS `font` property.
 *
 * @param {Element} element A DOM element to get the font for. Defaults to the document's `<body>`.
 * @returns The currently applied font, as formatted for the CSS `font` property.
 */
export const getElementFont = (element = document.body) => {
  const style = window.getComputedStyle(element)
  const fontWeight = style.getPropertyValue('font-weight') ?? 'normal'
  const fontSize = style.getPropertyValue('font-size') ?? '16px'
  const fontFamily = style.getPropertyValue('font-family') ?? 'serif'
  return `${fontWeight} ${fontSize} ${fontFamily}`
}

/**
 * Computes the size of a string if it was displayed in the browser with a specified font.
 *
 * @param {string} text The text to measure the size of.
 * @param {string} font Font with which the text will be displayed with, formatted as for the CSS `font` property.
 * @returns {TextMetrics} Measurements returned by the `CanvasRenderingContext2D.measureText` method.
 * @see https://stackoverflow.com/a/21015393/5990435
 */
export const getTextSize = (text, font = getElementFont()) => {
  /*
   * Use a canvas element that we do not insert anywhere, so that it never actually is on the page
   * Keep it cached in the function's context for better performance between calls
   */
  if (!getTextSize.canvas) getTextSize.canvas = document.createElement('canvas')
  const context = getTextSize.canvas.getContext('2d')
  context.font = font
  context.textBaseline = 'top'
  return context.measureText(text)
}
