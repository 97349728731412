import axios from 'axios'
import { unique } from '.'

// List transcriptions of an element.
export const listTranscriptions = unique(async ({ id, ...params }) => (await axios.get(`/element/${id}/transcriptions/`, { params })).data)

// Add a manual transcription
export const createTranscription = unique(async ({ id, ...data }) => (await axios.post(`/element/${id}/transcription/`, data)).data)

// Patch a transcription
export const updateTranscription = unique(async ({ id, ...data }) => (await axios.patch(`/transcription/${id}/`, data)).data)

// Delete a transcription
export const deleteTranscription = unique(async id => await axios.delete(`/transcription/${id}/`))
