import { assign, merge } from 'lodash'
import * as api from '@/api'

export const initialState = () => ({
  // { [agentId]: AgentDetails }
  agents: {},
  /*
   * Store all Ponos farms.
   * Initially set to null.
   * { [farmId]: FarmDetails } once farms have been listed.
   */
  farms: null
})

export const mutations = {
  setAgents (state, agents) {
    state.agents = {
      ...state.agents,
      ...agents.reduce((obj, agent) => {
        obj[agent.id] = merge(state.agents[agent.id] || {}, agent)
        return obj
      }, {})
    }
  },
  setFarms (state, farms) {
    state.farms = {
      ...(state.farms || {}),
      ...Object.fromEntries(farms.map(farm => [farm.id, farm]))
    }
  },
  reset (state) {
    assign(state, initialState())
  }
}

export const actions = {
  async retrievePonosAgent ({ commit }, agentId) {
    const data = await api.retrieveAgent(agentId)
    commit('setAgents', [data])
  },

  async listPonosAgents ({ commit, dispatch }, page = 1) {
    // Automatically list all Ponos agents
    const data = await api.listAgents({ page })
    commit('setAgents', data.results)
    if (!data || !data.number || page !== data.number) {
      throw new Error('Pagination failed listing ponos agents')
    }
    if (data.next) await dispatch('listPonosAgents', page + 1)
  },

  async listPonosFarms ({ commit, dispatch }, page = 1) {
    // Automatically list all Ponos farms
    const data = await api.listFarms({ page })
    commit('setFarms', data.results)
    if (!data || !data.number || page !== data.number) {
      throw new Error('Pagination failed listing ponos farms')
    }
    if (data.next) await dispatch('listPonosFarms', page + 1)
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  actions
}
