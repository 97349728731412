<template>
  <div class="field">
    <div class="control" v-on:click="opened = true">
      <!-- Pass v-bind as the last attribute, allowing to override the placeholder or value -->
      <input
        class="input"
        readonly
        :placeholder="placeholder"
        :value="modelValue?.name"
        v-bind="$attrs"
      />
    </div>
    <Modal v-model="opened" :title="placeholder">
      <!-- Uses v-if="opened" to ensure the folders are not loaded before the modal is opened -->
      <div class="menu" v-if="opened">
        <FolderList
          class="tree"
          :corpus-id="corpusId"
          :exclude="exclude"
          :current-folder-id="modelValue?.id"
          v-on:selected-folder="folder => $emit('update:modelValue', folder)"
        />
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Modal from '@/components/Modal.vue'
import FolderList from './FolderList.vue'

export default {
  inheritAttrs: false,
  components: {
    Modal,
    FolderList
  },
  emits: ['update:modelValue'],
  props: {
    corpusId: {
      type: String,
      required: true
    },
    modelValue: {
      type: Object,
      default: null
    },
    // Allow overriding the input's placeholder, but set a default
    placeholder: {
      type: String,
      default: 'Pick a folder…'
    },
    // Exclude specific elements and all of their children from the list
    exclude: {
      type: Array,
      default: null
    }
  },
  data: () => ({
    opened: false
  }),
  computed: {
    ...mapState('folderpicker', ['folders'])
  }
}
</script>
