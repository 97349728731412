<template>
  <li>
    <span
      class="item"
      v-on:mouseover="hover"
      v-on:mouseleave="unhover"
    >
      <div
        class="button is-compact"
        :class="{ 'is-loading': loading }"
        v-if="hasChildren"
        v-on:click="toggle"
        :title="`${node.expanded ? 'Shrink' : 'Expand'} ${typeName(element.type)} ${element.name}`"
      >
        <template v-if="!loading && !isParent">
          <i class="icon-down-open" :class="{ 'expanded': node.expanded }"></i>
        </template>
      </div>
      <router-link
        class="line"
        :to="interactive ? '' : elementRoute(element.id)"
        :title="title"
        :style="hoveredStyle"
        v-on:click="interactiveSelect(element)"
      >
        <span class="has-text-grey mr-1">
          {{ typeName(element.type) }}
        </span>
        <strong>
          {{ element.name }}
        </strong>
      </router-link>
      <span class="is-pulled-right is-inline-flex">
        <a
          v-if="(isHovered || isSelected) && !isParent"
          title="Edit this element"
          class="button is-compact icon-edit has-text-link"
          v-on:click="$emit('edit', element)"
        ></a>
        <a
          v-if="(isHovered || isSelected)"
          title="Add or edit a transcription"
          class="button is-compact icon has-text-link"
          v-on:click="$emit('transcribe', element)"
        >A<sup>+</sup></a>
        <a
          v-if="interactive && !isParent"
          class="button is-compact icon-eye"
          :title="`${isVisible ? 'Hide' : 'Show'} this element`"
          :style="toggledColor"
          v-on:click="toggleVisible"
        ></a>
      </span>
    </span>
    <ul v-if="node.expanded && hasChildren">
      <TreeItem
        v-for="childNode in node.children"
        :key="childNode.element.id"
        :node="childNode"
        :parent-id="parentId"
        :interactive="interactive"
        v-on:edit="$emit('edit', $event)"
        v-on:transcribe="$emit('transcribe', $event)"
      />
    </ul>
    <button
      class="button is-small"
      :class="{ 'is-loading': loading }"
      v-on:click="fetchChildren"
      v-if="hasNext && node.expanded"
    >
      Load more…
    </button>
  </li>
</template>

<script>
/*
 * Represents an element and their direct
 * children on an interactive tree view
 */
import Mousetrap from 'mousetrap'
import { mapState, mapActions, mapMutations } from 'vuex'
import TreeItem from './TreeItem'
import { corporaMixin } from '@/mixins.js'
import { clone } from 'lodash'
import { INTERACTIVE_POLYGON_COLORS } from '@/config'
export default {
  name: 'TreeItem',
  mixins: [
    corporaMixin
  ],
  components: {
    TreeItem
  },
  emits: ['edit', 'transcribe'],
  props: {
    parentId: {
      type: String,
      required: true
    },
    node: {
      // { element: Element, children: node[] }
      type: Object,
      required: true
    },
    interactive: {
      // Allow interactions between elements in the tree and the main element image
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: false
  }),
  mounted () {
    if (this.isParent) Mousetrap.bind('m', this.fetchChildren)
  },
  unmounted () {
    Mousetrap.unbind('m')
  },
  computed: {
    ...mapState('annotation', {
      annotationEnabled: 'enabled',
      annotationTool: 'tool'
    }),
    ...mapState('annotation', ['visible', 'hoveredId', 'selectedElement']),
    ...mapState('elements', ['childrenPagination', 'parents']),
    element () {
      return this.node.element || {}
    },
    elementType () {
      return this.element && this.getType(this.element.type)
    },
    hasChildren () {
      return this.isParent || this.element.has_children === true
    },
    isParent () {
      // This node is the main tree node
      return this.element.id === this.parentId
    },
    canTranscribe () {
      const type = this.getType(this.element.type)
      return type && type.allowed_transcription
    },
    corpusId () {
      return this.element.corpus.id
    },
    visibleIds () {
      return this.visible[this.parentId] || []
    },
    isVisible () {
      // This node is part of visible elements
      return this.visibleIds.includes(this.element.id) && this.interactive
    },
    isHovered () {
      // Node is hovered by the mouse, either from the tree or the interactive image
      return this.hoveredId && this.hoveredId === this.element.id
    },
    isSelected () {
      // Select the parent element by default e.g. no children is selected or the selected element is not created yet
      if (this.selectedElement === null || this.selectedElement.id === 'created-polygon') return this.isParent
      return this.interactive && this.selectedElement.id === this.element.id
    },
    hoveredStyle () {
      // Router link background is colored when element can be selected from the tree
      if ((!this.isHovered || !this.isVisible) && !this.isSelected) return
      return {
        'background-color': INTERACTIVE_POLYGON_COLORS.tree.hovered,
        border: this.isSelected ? 'solid 1px #ddd' : null
      }
    },
    title () {
      const action = this.interactive ? 'Select' : 'Explore'
      const { type, name } = this.element
      return `${action} ${this.typeName(type)} ${name}`
    },
    page () {
      return this.childrenPagination[this.element.id]
    },
    hasNext () {
      // All children have not been loaded
      return Boolean(this.page && this.page.next)
    },
    toggledColor () {
      return `color: ${this.isVisible ? `${INTERACTIVE_POLYGON_COLORS.element.visible};` : 'lightgrey;'}`
    }
  },
  methods: {
    ...mapActions('tree', ['toggleNode']),
    ...mapActions('elements', ['nextChildren']),
    ...mapMutations('notifications', ['notify']),
    ...mapMutations('annotation', ['setVisible', 'cleanVisible', 'setHoveredId', 'selectElement']),
    ...mapMutations('annotation', { setAnnotationTool: 'setTool' }),
    async toggle () {
      if (this.loading) return
      this.loading = true
      // Toggle this tree node and fetch children
      try {
        await this.toggleNode({ parentId: this.parentId, elementId: this.element.id })
      } catch (err) {
        const nodeInfo = `${this.element.name} - ${this.element.id}`
        this.notify({ type: 'error', text: `An error occurred fetching children of node "${nodeInfo}".` })
      } finally {
        this.loading = false
      }
    },
    hover () {
      if (this.isHovered) return
      this.setHoveredId(this.element.id)
    },
    unhover () {
      if (!this.isHovered) return
      this.setHoveredId(null)
    },
    elementRoute (id) {
      return { name: 'element-details', params: { id } }
    },
    toggleVisible () {
      this.setVisible({
        parentId: this.parentId,
        id: this.element.id,
        visible: !this.isVisible
      })
    },
    interactiveSelect (element) {
      if (!this.interactive) return
      if (!this.element.zone || !this.element.zone.polygon) {
        this.notify({ type: 'info', text: 'This element has no zone.' })
        this.selectElement(null)
        return
      }
      if (this.annotationEnabled && this.annotationTool !== 'select') {
        // In edition mode, set current tool to selection
        this.setAnnotationTool('select')
      }
      if (
        // The selected element is unselected
        (this.selectedElement && element.id === this.selectedElement.id) ||
        // The parent element is clicked
        element.id === this.parentId
      ) {
        this.selectElement(null)
      } else {
        this.selectElement({
          id: element.id,
          name: element.name,
          zone: { polygon: [...element.zone.polygon] }
        })
        if (this.$route.query.highlight !== element.id) {
          const query = { ...clone(this.$route.query) }
          query.highlight = element.id
          this.$router.replace({ name: this.$route.name, query })
        }
      }
    },
    async fetchChildren () {
      // Fetch remaining children
      if (this.loading) return
      if (this.page && !this.hasNext) return
      this.loading = true
      try {
        await this.nextChildren({ id: this.element.id, hasChildren: true })
      } catch (err) {
        this.notify({ type: 'error', text: `Error fetching next elements for parent "${this.element.id}"` })
      } finally {
        this.loading = false
      }
    },
    transcribe () {
      if (!this.canTranscribe) this.notify({ type: 'info', text: 'This type of element does not accept transcriptions' })
      else this.$emit('transcribe', this.element)
    }
  },
  watch: {
    element: {
      immediate: true,
      handler (newValue, oldValue) {
        /*
         * Automatically load all children if this TreeItem is the parent node of the tree.
         * This is done as a watcher and not just in mounted as it is possible that the element changes without
         * unmounting the component if the user switches between neighbors and the element is already in the store,
         * for example when going back and forth between two elements multiple times,
         * or when the element is in the selection or was in a navigation list.
         */
        if (oldValue?.id === newValue.id || !this.isParent) return
        this.fetchChildren()
      }
    },
    selectedElement: {
      immediate: true,
      handler (newValue, oldValue) {
        /*
         * Open every TreeItem all the way down to a visible element.
         * This uses the fact that the highlighting will load all parents into the `parents` state, and nothing else.
         */
        if (!newValue || (oldValue && newValue === oldValue)) return
        const parents = this.parents[newValue.id]
        if (parents && parents.includes(this.node.element.id) && !this.node.expanded) {
          this.toggle()
          this.fetchChildren()
        }
      }
    }
  }
}
</script>
