import { removeEmptyStrings, errorParser } from '@/helpers'
import * as api from '@/api'
import { assign, isEmpty } from 'lodash'
import { DEFAULT_CORPUS_ATTRS } from '@/config'

export const initialState = () => ({
  corpora: {},
  /*
   * Set to true as soon as the corpora list is loaded.
   * Prevents trying to reload the corpora when there are none available.
   */
  corporaLoaded: false,
  // { [corpusId]: AllowedMetadata[] }
  corpusAllowedMetadata: {},
  // { [corpusId]: EntityTypes[] }
  corpusEntityTypes: {},
  // { [corpusId]: Dataset[] }
  corpusDatasets: {},
  // A single corpus' exports.
  exports: {}
})

const parseTypes = typeList => typeList.reduce((types, type) => {
  types[type.slug] = type
  return types
}, {})

export const mutations = {
  set (state, corporaList) {
    state.corpora = corporaList
      // Turn corpora list into a single { corpus.id: corpus } object
      .reduce((corpora, item) => {
        // Do a shallow copy to avoid issues when calling api.listCorpora multiples times too quickly
        const corpus = { ...item }
        // Turn corpus type lists into { type.slug: type } objects
        corpus.types = parseTypes(corpus.types)

        corpora[corpus.id] = corpus
        return corpora
      }, {})
    state.corporaLoaded = true
  },

  update (state, corpus) {
    const newCorpus = state.corpora[corpus.id] || {}
    assign(newCorpus, corpus)
    if (Array.isArray(newCorpus.types)) newCorpus.types = parseTypes(newCorpus.types)
    state.corpora = { ...state.corpora, [newCorpus.id]: newCorpus }
  },

  updateType (state, { corpus, ...type }) {
    if (!state.corpora[corpus]) throw new Error(`Corpus ${corpus} does not exist`)
    const newCorpus = state.corpora[corpus]
    newCorpus.types = Object.fromEntries([
      // If the type's slug gets updated, we need to filter out by ID instead of slug
      ...Object.entries(newCorpus.types).filter(([, existingType]) => existingType.id !== type.id),
      [type.slug, type]
    ])
    state.corpora = { ...state.corpora, [newCorpus.id]: newCorpus }
  },

  removeType (state, { corpusId, typeId }) {
    if (!state.corpora[corpusId]) throw new Error(`Corpus ${corpusId} does not exist`)
    const newCorpus = { ...state.corpora[corpusId] }
    const typeList = Object.values(newCorpus.types)
    const index = typeList.findIndex(({ id }) => typeId === id)
    if (index < 0) throw new Error(`Type ${typeId} not found in corpus ${corpusId}`)
    typeList.splice(index, 1)
    newCorpus.types = Object.fromEntries(typeList.map(type => [type.slug, type]))
    state.corpora = { ...state.corpora, [newCorpus.id]: newCorpus }
  },

  updateWorkerVersions (state, { corpusId, results }) {
    // Add available worker versions to a corpus without duplicate
    if (!state.corpora[corpusId]) throw new Error(`Corpus ${corpusId} does not exist`)
    const newCorpus = { ...state.corpora[corpusId] }
    newCorpus.worker_versions = {
      ...newCorpus.worker_versions,
      ...Object.fromEntries(results.map(version => [version.id, version]))
    }
    state.corpora = { ...state.corpora, [newCorpus.id]: newCorpus }
  },

  remove (state, corpusId) {
    if (!state.corpora[corpusId]) throw new Error(`Corpus ${corpusId} does not exist`)
    const newCorpora = { ...state.corpora }
    delete newCorpora[corpusId]
    state.corpora = newCorpora
  },

  reset (state) {
    assign(state, initialState())
  },

  setCorpusEntityTypes (state, { corpusId, results }) {
    const updatedList = state.corpusEntityTypes[corpusId] || []
    results.forEach(newType => {
      // Prevent duplicating entity types
      if (!updatedList.some(type => type.name === newType.name)) updatedList.push(newType)
    })
    // Merge corpus entity types
    state.corpusEntityTypes = {
      ...state.corpusEntityTypes,
      [corpusId]: updatedList
    }
  },

  updateCorpusEntityType (state, { corpusId, data }) {
    if (!state.corpusEntityTypes[corpusId]) throw new Error(`Entity Types for corpus ${corpusId} not found`)
    const typesList = [...state.corpusEntityTypes[corpusId]]
    const index = typesList.findIndex(item => item.id === data.id)
    if (index < 0) throw new Error(`Entity Type ${data.id} not found in corpus ${corpusId}`)
    typesList.splice(index, 1, data)
    state.corpusEntityTypes[corpusId] = typesList
  },

  removeCorpusEntityType (state, { corpusId, typeId }) {
    if (!state.corpusEntityTypes[corpusId]) throw new Error(`Entity Types for corpus ${corpusId} not found`)
    const typesList = [...state.corpusEntityTypes[corpusId]]
    const index = typesList.findIndex(item => item.id === typeId)
    if (index < 0) throw new Error(`Entity Type ${typeId} not found in corpus ${corpusId}`)
    typesList.splice(index, 1)
    state.corpusEntityTypes[corpusId] = typesList
  },

  setCorpusAllowedMetadata (state, { corpusId, results }) {
    const updatedList = state.corpusAllowedMetadata[corpusId] || []
    results.forEach(newMeta => {
      // Prevent duplicating allowed metadata
      if (!updatedList.some(meta => meta.type === newMeta.type && meta.name === newMeta.name)) updatedList.push(newMeta)
    })
    // Merge corpus allowed metadata
    state.corpusAllowedMetadata = {
      ...state.corpusAllowedMetadata,
      [corpusId]: updatedList
    }
  },

  updateCorpusAllowedMetadata (state, { corpusId, data }) {
    if (!state.corpusAllowedMetadata[corpusId]) throw new Error(`Allowed metadata for corpus ${corpusId} not found`)
    const mdList = [...state.corpusAllowedMetadata[corpusId]]
    const index = mdList.findIndex(item => item.id === data.id)
    if (index < 0) throw new Error(`Allowed metadata ${data.id} not found in corpus ${corpusId}`)
    mdList.splice(index, 1, data)
    state.corpusAllowedMetadata[corpusId] = mdList
  },

  removeCorpusAllowedMetadata (state, { corpusId, mdId }) {
    if (!state.corpusAllowedMetadata[corpusId]) throw new Error(`Allowed metadata for corpus ${corpusId} not found`)
    const mdList = [...state.corpusAllowedMetadata[corpusId]]
    const index = mdList.findIndex(item => item.id === mdId)
    if (index < 0) throw new Error(`Allowed metadata ${mdId} not found in corpus ${corpusId}`)
    mdList.splice(index, 1)
    state.corpusAllowedMetadata[corpusId] = mdList
  },

  setCorpusDatasets (state, { corpusId, results }) {
    const datasetList = state.corpusDatasets[corpusId] || []
    results.forEach(newDataset => {
      // Prevent duplicating datasets
      if (!datasetList.some(dataset => dataset.id === newDataset.id)) datasetList.push(newDataset)
    })
    // Merge corpus datasets
    state.corpusDatasets = {
      ...state.corpusDatasets,
      [corpusId]: datasetList
    }
  },

  updateCorpusDatasets (state, { corpusId, data }) {
    if (!state.corpusDatasets[corpusId]) throw new Error(`Datasets for corpus ${corpusId} not found`)
    const datasetList = [...state.corpusDatasets[corpusId]]
    const index = datasetList.findIndex(item => item.id === data.id)
    if (index < 0) throw new Error(`Dataset ${data.id} not found in corpus ${corpusId}`)
    datasetList.splice(index, 1, data)
    state.corpusDatasets[corpusId] = datasetList
  },

  removeCorpusDataset (state, { corpusId, datasetId }) {
    if (!state.corpusDatasets[corpusId]) throw new Error(`Datasets for corpus ${corpusId} not found`)
    const datasetList = [...state.corpusDatasets[corpusId]]
    const index = datasetList.findIndex(item => item.id === datasetId)
    if (index < 0) throw new Error(`Dataset ${datasetId} not found in corpus ${corpusId}`)
    datasetList.splice(index, 1)
    state.corpusDatasets[corpusId] = datasetList
  },

  addDefaultCorpus (state, { id, name }) {
    state.corpora = {
      ...state.corpora,
      [id]: {
        id,
        name,
        ...DEFAULT_CORPUS_ATTRS
      }
    }
  },

  setExports (state, exports) {
    state.exports = exports
  }
}

export const actions = {
  /**
   * Fetches a list of available corpora.
   *
   * $_ is Vue's convention for private methods. $_fetch should not be called outside of this module.
   * If something in a corpus needs to be changed, prefer mutations such as update and updateType
   * to prevent stale reads from database replication.
   */
  async $_fetch ({ commit }) {
    try {
      commit('set', await api.listCorpora())
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
      throw err
    }
  },

  async list ({ state, dispatch }) {
    if (isEmpty(state.corpora) && !state.corporaLoaded) await dispatch('$_fetch')
    return state.corpora
  },

  async get ({ state, dispatch }, { id }) {
    if (isEmpty(state.corpora) && !state.corporaLoaded) await dispatch('$_fetch')
    if (!state.corpora[id]) throw new Error(`Corpus with ID ${id} not found`)
    return state.corpora[id]
  },

  async create ({ state, commit, dispatch }, corpus) {
    /*
     * Ensure corpora are loaded before anything happens,
     * because if a corpus is created/updated, all other corpora will not be loaded.
     */
    if (isEmpty(state.corpora) && !state.corporaLoaded) await dispatch('$_fetch')
    const data = await api.createCorpus(corpus)
    commit('update', data)
    return data
  },

  async update ({ state, commit, dispatch }, corpus) {
    if (isEmpty(state.corpora) && !state.corporaLoaded) await dispatch('$_fetch')
    commit('update', await api.updateCorpus(corpus))
  },

  async delete ({ state, commit, dispatch }, { id }) {
    if (isEmpty(state.corpora) && !state.corporaLoaded) await dispatch('$_fetch')
    try {
      await api.destroyCorpus(id)
      commit('remove', id)
    } catch (e) {
      throw new Error(errorParser(e))
    } finally {
      dispatch('jobs/list', null, { root: true })
    }
  },

  /*
   * Get an element type from a corpus.
   * Expects a corpus ID and a type slug.
   */
  async getType ({ dispatch }, { id, slug }) {
    const corpus = await dispatch('get', { id })
    if (!corpus.types[slug]) throw new Error(`Type ${slug} not found on corpus ${id}`)
    return corpus.types[slug]
  },

  async listCorpusEntityTypes ({ state, commit, dispatch }, { corpusId, page = 1 }) {
    // Do not start fetching corpus entity types if they have been retrieved already
    if (page === 1 && state.corpusEntityTypes[corpusId]) return
    const data = await api.listCorpusEntityTypes({ id: corpusId, page })
    commit('setCorpusEntityTypes', { corpusId, results: data.results })
    if (!data || !data.number || page !== data.number) {
      // Avoid any loop
      throw new Error(`Pagination failed listing entity types for corpus "${corpusId}"`)
    }
    // Load other pages
    if (data.next) await dispatch('listCorpusEntityTypes', { corpusId, page: page + 1 })
  },

  async createCorpusEntityType ({ commit }, { ...type }) {
    try {
      const data = await api.createCorpusEntityType({ ...type })
      commit('setCorpusEntityTypes', { corpusId: type.corpus, results: [data] })
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
      throw err
    }
  },

  async updateCorpusEntityType ({ commit }, { corpusId, ...type }) {
    try {
      const data = await api.updateCorpusEntityType({ id: type.id, ...type })
      commit('updateCorpusEntityType', { corpusId, data })
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
      throw err
    }
  },

  async deleteCorpusEntityType ({ commit }, { corpusId, typeId }) {
    try {
      await api.deleteCorpusEntityType({ id: typeId })
      commit('removeCorpusEntityType', { corpusId, typeId })
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
      throw err
    }
  },

  async listAllowedMetadata ({ state, commit, dispatch }, { corpusId, page = 1 }) {
    // Do not start fetching allowed metadata if they have been retrieved already
    if (page === 1 && state.corpusAllowedMetadata[corpusId]) return
    // Automatically list all allowed metadata for a corpus through pagination
    const data = await api.listCorpusAllowedMetadata({ id: corpusId, page })
    commit('setCorpusAllowedMetadata', { corpusId, results: data.results })
    if (!data || !data.number || page !== data.number) {
      // Avoid any loop
      throw new Error(`Pagination failed listing available metadata for corpus "${corpusId}"`)
    }
    // Load other pages
    if (data.next) await dispatch('listAllowedMetadata', { corpusId, page: page + 1 })
  },

  async createAllowedMetadata ({ commit }, { corpusId, ...metadata }) {
    try {
      const data = await api.createAllowedMetadata({ ...metadata, corpusId })
      commit('setCorpusAllowedMetadata', { corpusId, results: [data] })
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
      throw err
    }
  },

  async updateAllowedMetadata ({ commit }, { corpusId, mdId, ...metadata }) {
    try {
      const data = await api.updateAllowedMetadata({ corpusId, id: mdId, ...metadata })
      commit('updateCorpusAllowedMetadata', { corpusId, data })
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
      throw err
    }
  },

  async deleteAllowedMetadata ({ commit }, { corpusId, mdId }) {
    try {
      await api.deleteAllowedMetadata(corpusId, mdId)
      commit('removeCorpusAllowedMetadata', { corpusId, mdId })
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
      throw err
    }
  },

  async createType ({ commit }, { corpus, ...type }) {
    try {
      const data = await api.createType({ corpus, ...type })
      commit('updateType', { corpus, ...data })
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
      if (err.response && err.response.status === 400 && err.response.data) throw err
    }
  },

  async updateType ({ commit }, { corpus, ...type }) {
    try {
      const data = await api.updateType({ corpus, ...type })
      commit('updateType', { corpus, ...data })
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
      if (err.response && err.response.status === 400 && err.response.data) throw err
    }
  },

  async destroyType ({ commit }, { corpusId, typeId }) {
    try {
      await api.destroyType(typeId)
      commit('removeType', { corpusId, typeId })
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
      throw err
    }
  },

  async listCorpusDatasets ({ state, commit, dispatch }, { corpusId, page = 1 }) {
    // Do not start fetching corpus datasets if they have been retrieved already
    if (page === 1 && state.corpusDatasets[corpusId]) return
    const data = await api.listCorpusDataset({ corpusId, page })
    commit('setCorpusDatasets', { corpusId, results: data.results })
    if (!data || !data.number || page !== data.number) {
      // Avoid any loop
      throw new Error(`Pagination failed listing datasets for corpus "${corpusId}"`)
    }
    // Load other pages
    if (data.next) await dispatch('listCorpusDatasets', { corpusId, page: page + 1 })
  },

  async createCorpusDataset ({ commit }, { corpusId, ...dataset }) {
    try {
      const data = await api.createDataset({ ...dataset, corpusId })
      commit('setCorpusDatasets', { corpusId, results: [data] })
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
      throw err
    }
  },

  async updateCorpusDataset ({ commit }, { corpusId, datasetId, ...dataset }) {
    try {
      const data = await api.updateDataset({ id: datasetId, ...dataset })
      commit('updateCorpusDatasets', { corpusId, data })
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
      throw err
    }
  },

  async deleteCorpusDataset ({ commit }, { corpusId, datasetId }) {
    try {
      await api.deleteDataset(datasetId)
      commit('removeCorpusDataset', { corpusId, datasetId })
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
      throw err
    }
  },

  /**
   * Recursively list WorkerVersions for a specific corpus.
   * Used to filter elements by existing versions.
   */
  async listWorkerVersions ({ commit, dispatch }, { corpusId, recursive = true, ...params }) {
    try {
      const resp = await api.listCorpusWorkerVersions({ id: corpusId, ...params })
      commit('updateWorkerVersions', { corpusId, results: resp.results })
      if (recursive && resp.next) dispatch('listWorkerVersions', { corpusId, url: resp.next })
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
    }
  },

  async deleteWorkerResults ({ commit, dispatch }, { corpus, ...params }) {
    try {
      await api.deleteWorkerResults({ corpus, ...removeEmptyStrings(params) })
      commit('notifications/notify', { type: 'success', text: 'WorkerVersion results deletion has been scheduled.' }, { root: true })
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
      if (err.response && err.response.status === 400 && err.response.data) throw err
    } finally {
      dispatch('jobs/list', null, { root: true })
    }
  },

  async listExports ({ commit }, payload) {
    try {
      commit('setExports', await api.listExports(removeEmptyStrings(payload)))
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
    }
  },

  async startExport ({ commit, dispatch }, id) {
    try {
      await api.startExport(id)
      dispatch('jobs/list', null, { root: true })
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
    }
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  actions
}
