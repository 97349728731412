<template>
  <Modal
    :model-value="modelValue"
    v-on:update:model-value="value => $emit('update:modelValue', value)"
    :title="title"
  >
    <Paginator
      :response="exports"
      :loading="loading"
      singular="export"
      plural="exports"
      v-model:page="page"
    >
      <template v-slot:no-results>
        <div class="notification is-warning">
          No exports to display.
        </div>
      </template>
      <template v-slot:default="{ results }">
        <table class="table is-hoverable is-fullwidth">
          <tr>
            <th>Creator</th>
            <th>State</th>
            <th>Updated</th>
            <th>Actions</th>
          </tr>
          <tr v-for="corpusExport in results" :key="corpusExport.id">
            <td>{{ corpusExport.user.display_name }}</td>
            <td>{{ EXPORT_STATES[corpusExport.state] }}</td>
            <td :title="corpusExport.updated">{{ dateAgo(corpusExport.updated) }}</td>
            <td>
              <a
                :href="downloadLink(corpusExport)"
                v-if="downloadLink(corpusExport)"
                target="_blank"
              >
                Download
              </a>
            </td>
          </tr>
        </table>
      </template>
    </Paginator>
    <template v-slot:footer>
      <button
        type="button"
        class="button is-primary"
        :class="{ 'is-loading': loading }"
        :title="buttonTitle"
        :disabled="!canStart || loading || null"
        v-on:click="start"
      >
        Start export
      </button>
      <button
        type="button"
        class="button"
        :class="{ 'is-loading': loading }"
        :disabled="loading || null"
        v-on:click="load(page)"
      >
        Refresh
      </button>
    </template>
  </Modal>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { API_BASE_URL, EXPORT_STATES } from '@/config'
import { ago } from '@/helpers'
import { corporaMixin } from '@/mixins.js'
import Modal from '@/components/Modal.vue'
import Paginator from '@/components/Paginator.vue'

export default {
  mixins: [
    corporaMixin
  ],
  components: {
    Modal,
    Paginator
  },
  emits: ['update:modelValue'],
  props: {
    corpusId: {
      type: String,
      required: true
    },
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    page: 1,
    loading: false,
    EXPORT_STATES
  }),
  computed: {
    ...mapState('corpora', ['exports']),
    title () {
      if (this.corpus && this.corpus.name) return `Exports of project ${this.corpus.name}`
      return 'Project exports'
    },
    canStart () {
      return this.canWrite(this.corpus)
    },
    buttonTitle () {
      if (this.canStart) return
      return 'Contributor access is required to start a project export.'
    }
  },
  methods: {
    ...mapActions('corpora', ['listExports', 'startExport']),
    async load (page = 1) {
      this.loading = true
      try {
        await this.listExports({ id: this.corpusId, page })
      } finally {
        this.loading = false
      }
    },
    async start () {
      if (!this.canStart || this.loading) return
      this.loading = true
      try {
        await this.startExport(this.corpusId)
        await this.load()
      } finally {
        this.loading = false
      }
    },
    downloadLink (corpusExport) {
      if (corpusExport.state !== 'done') return
      return `${API_BASE_URL}/export/${corpusExport.id}/`
    },
    dateAgo (date) {
      return ago(new Date(date))
    }
  },
  watch: {
    modelValue: {
      handler (newValue) {
        if (newValue) this.load(1)
      },
      immediate: true
    },
    page: 'load'
  }
}
</script>
