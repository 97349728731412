<template>
  <main class="container is-fluid content">
    <h1 class="title">Unverified email</h1>
    <p>Your account's e-mail address, <samp>{{ user.email }}</samp>, has not been verified. A verified e-mail is required to access this page. Please check your inbox or ask for help from an administrator.</p>
    <p>
      <router-link :to="{ name: 'corpus-list' }">Homepage</router-link>
      &middot;
      <a v-on:click="goBack">Go back</a>
    </p>
  </main>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('auth', ['user'])
  },
  methods: {
    goBack () {
      window.history.back()
    }
  }
}
</script>
