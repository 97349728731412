import { assign } from 'lodash'
import { TEXT_ORIENTATIONS } from '@/config'

const DEFAULT_TEXT_ORIENTATION = 'horizontal-lr'
const DEFAULT_TOOL = 'select'
const TOOL_NAMES = ['rectangle', 'polygon', DEFAULT_TOOL, 'deletion', 'median', 'type-edit']

export const initialState = () => ({
  /**
   * The currently selected text orientation in the transcription modal/sidebar.
   * @type {string}
   */
  textOrientation: DEFAULT_TEXT_ORIENTATION,
  /**
   * Whether or not the annotation editor is turned on.
   * @type {boolean}
   */
  enabled: false,
  /**
   * The currently selected tool in the annotation editor.
   * This is set to the default tool, 'select', when the editor is turned off.
   * The tool name must be within the TOOL_NAMES array.
   * @type {string}
   */
  tool: DEFAULT_TOOL,
  /**
   * Whether the user enabled batch creation.
   * When this is turned off, the store module will not try to guess
   * any of the element's values from the store state and will instead
   * require more arguments.
   * @type {boolean}
   */
  batchCreation: false,
  /**
   * Whether the user enabled batch deletion (no confirmation modal).
   * @type {boolean}
   */
  batchDeletion: false,
  /**
   * Whether the user enabled batch type edition (no edition modal).
   * @type {boolean}
   */
  batchTypeEdition: false,
  /**
   * Default type slugs by corpus ID, used when the batch creation mode is enabled.
   * @type {[corpusId: string]: string}
   */
  defaultType: {},
  /**
   * Default ML class IDs by corpus ID, used when the batch creation mode is enabled.
   * This can be null to indicate that no class should be selected.
   * @type {[corpusId: string]: string?}
   */
  defaultClass: {},
  /**
   * Element IDs that are visible in the InteractiveImage, grouped by parent element ID.
   * @type {[elementId: string]: string[]}
   */
  visible: {},
  /**
   * The currently hovered element ID.
   * This allows a user to hover the element in the InteractiveImage
   * and see it highlighted in the children tree, and vice-versa.
   * @type {string}
   */
  hoveredId: null,
  /**
   * Represents a copy of an element that has been selected (from the children tree or the interactive image).
   * If annotation/enabled is set, this object is intended to be a temporary and editable copy of
   * the element that does not reflect the real backend data until it is saved.
   * During the creation of a new element (that does not exists in the backend) this object is also used and
   * its id property is set to 'created-polygon'.
   * Always prefer to compare this object by its 'id' key instead of using references.
   */
  selectedElement: null
})

export const mutations = {
  /**
   * Select a text orientation in the transcription modal/sidebar.
   * @param {string} Text orientation found in the `TEXT_ORIENTATIONS` keys.
   */
  setTextOrientation (state, value) {
    if (!(value in TEXT_ORIENTATIONS)) throw new Error(`Unknown text orientation ${value}`)
    state.textOrientation = value
  },

  /**
   * Turn the annotation mode on or off.
   * When the annotation mode is turned off, the selected tool is set to the default.
   * @param {boolean?} value On or off state. When null, the current state will be toggled.
   */
  toggle (state, value = null) {
    if (value === null) state.enabled = !state.enabled
    else state.enabled = Boolean(value)
    // Reset the tool to the default when turned off
    if (!state.enabled) {
      state.tool = DEFAULT_TOOL
      if (state.selectedElement && state.selectedElement.id === 'created-polygon') {
        // Do not keep an unsaved drawn polygon
        state.selectedElement = null
      }
      state.batchDeletion = false
    }
  },

  /**
   * Select a tool in the annotation editor.
   * @param {string} Tool name found in the `TOOL_NAMES` array.
   */
  setTool (state, name) {
    if (!TOOL_NAMES.includes(name)) throw new Error(`Unknown tool ${name}`)
    state.tool = name
    if (name !== 'deletion' && state.batchDeletion === true) state.batchDeletion = false
  },

  /**
   * Turn the batch creation mode on or off.
   * This determines whether or not the create action will allow optional parameters.
   * @param {boolean?} value On or off state.
   */
  setBatchCreation (state, value = null) {
    if (value === null) state.batchCreation = !state.batchCreation
    else state.batchCreation = Boolean(value)
  },

  /**
   * Set the default type slug to use for batch annotations on a specific corpus.
   * @param {{corpusId: string, type: string}} The corpus ID and type.
   */
  setDefaultType (state, { corpusId, type }) {
    if (!corpusId || !type) throw new Error('A corpus ID and a type are required to set a default type.')
    state.defaultType = {
      ...state.defaultType,
      [corpusId]: type
    }
  },

  /**
   * Set the default ML class ID to use for batch annotations on a specific corpus.
   * @param {{corpusId: string, classId: string}} The corpus and ML class IDs.
   */
  setDefaultClass (state, { corpusId, classId }) {
    if (!corpusId) throw new Error('A corpus ID is required to set a default class.')
    state.defaultClass = {
      ...state.defaultClass,
      [corpusId]: classId
    }
  },

  /**
   * Turn the batch deletion mode on or off.
   * @param {boolean?} value On or off state.
   */
  setBatchDeletion (state, value = null) {
    if (value === null) state.batchDeletion = !state.batchDeletion
    else state.batchDeletion = Boolean(value)
  },

  /**
   * Turn the batch type edition mode on or off.
   * @param {boolean?} value On or off state.
   */
  setBatchTypeEdition (state, value = null) {
    if (value === null) state.batchTypeEdition = !state.batchTypeEdition
    else state.batchTypeEdition = Boolean(value)
  },

  /**
   * Show or hide an element.
   * If `visible` is set to true, the element zone will be visible in the InteractiveImage.
   */
  setVisible (state, { parentId, id, visible = true }) {
    if (!state.visible[parentId]) state.visible[parentId] = []
    const index = state.visible[parentId].indexOf(id)
    if (visible && index === -1) state.visible[parentId].push(id)
    else if (!visible && index !== -1) state.visible[parentId].splice(index, 1)
    else return
    state.visible = { ...state.visible }
  },

  setVisibleBulk (state, { parentId, ids, visible = true }) {
    // Update the list of sub-elements shown on the image for this parent.
    if (!state.visible[parentId]) state.visible[parentId] = []
    const updatedList = visible
      // Add element ids without duplicate
      ? [...new Set([...state.visible[parentId], ...ids])]
      // Filter removed ids from existing visible elements
      : state.visible[parentId].filter(id => !ids.includes(id))
    // Update visible list
    state.visible = {
      ...state.visible,
      [parentId]: updatedList
    }
  },

  cleanVisible (state, parentId) {
    // Remove visible attributes of an element children
    delete state.visible[parentId]
    state.visible = { ...state.visible }
  },

  setHoveredId (state, id) {
    state.hoveredId = id
  },

  selectElement (state, object) {
    // Edited element may have a polygon if defined
    if (object !== null && (!object.zone || !object.zone.polygon)) {
      throw new Error('Edited element must be null or have a defined zone.')
    }
    state.selectedElement = object
  },

  reset (state) {
    assign(state, initialState())
  }
}

export const actions = {
  /**
   * Create an annotation: an element with a polygon and an optional classification on a parent element.
   *
   * @param {object} param0 Store context.
   * @param {{corpus: string, name?: string?, type?: string?, classId?: string?, parent: string, polygon: [number, number][]} } param1
   *   Annotation parameters.
   *
   *   When `batchCreation` is enabled, the `name`, `type` and `classId` attributes can be auto-filled
   *   by this action from the `defaultType` and `defaultClass` states, as well as fetching
   *   the next available integer from the element list as a name.
   *
   *   When `batchCreation` is disabled, all attributes are required.
   */
  async create ({ state, getters, commit, dispatch }, { corpus, name, type, classId, parent, polygon, ...payload }) {
    if (!state.enabled) throw new Error('Cannot create annotations when the annotation mode is disabled.')

    // Fail for required arguments
    if (!corpus || !parent || !polygon) {
      throw new Error('The corpus ID, parent ID and polygon are required to create an annotation.')
    }

    // In batch creation mode, guess the type, classId and name.
    if (state.batchCreation) {
      if (!type) {
        type = state.defaultType[corpus]
        // Fail if there is no default type set
        if (!type) throw new Error(`No default type has been set for batch annotation on corpus ${corpus}.`)
      }

      // No error on the classId, since it can be optional
      if (classId === undefined) classId = state.defaultClass[corpus]

      /*
       * Guess the name using a getter.
       * The element creation modal might reuse the default name as a default even in non-batch mode,
       * so this is in a separate getter.
       */
      if (!name) name = getters.defaultName(parent, type)

      // Deselect the element to give feedback to the user, allowing them to start editing a new one with the InteractiveImage
      commit('selectElement', null)
    } else if (!name || !type || classId === undefined) {
      /*
       * Fail if we are not annotating in batch and all the arguments are not set.
       * This is an extra safety measure that makes sure both the modal and panel call this action properly.
       * The classId may be null, so we check explicitly for `undefined`.
       */
      throw new Error('The name, type and classId are required when not annotating in batch.')
    }

    // There is no error handling here because both elements/create and classification/create will show error notifications
    const element = await dispatch('elements/create', {
      corpus,
      name,
      type,
      polygon,
      parent,
      ...payload
    }, { root: true })

    // Make sure the created element is visible in the editor
    commit('setVisible', { parentId: parent, id: element.id, visible: true })

    // Create the optional classification
    if (classId) {
      await dispatch('classification/create', {
        elementId: element.id,
        mlClass: classId
      }, { root: true })
    }

    return element
  },

  async typeEdit ({ state, dispatch }, { element, type }) {
    if (!state.enabled) throw new Error('Cannot edit element types when the annotation mode is disabled.')
    if (state.batchTypeEdition && state.defaultType[element.corpus.id]) {
      type = state.defaultType[element.corpus.id]
      if (type === element.type) return
    } else if (!type) { throw new Error("A valid type value is required to update the element's type.") }
    return dispatch('elements/patch', { id: element.id, type }, { root: true })
  }
}

export const getters = {
  /**
   * Guess the default name to use for a new annotation, given a parent ID and type slug:
   * count the currently known children of the parent element with the annotation's type and add 1.
   * This would yield "line 1", "line 2", "paragraph 1", etc.
   * Note that this might not work well if all the children are not loaded in the children tree.
   */
  defaultName: (state, getters, rootState) => (parentId, type) => {
    const childrenIds = rootState.elements.links[parentId]?.children ?? []
    return (childrenIds.filter(childId => rootState.elements.elements[childId]?.type === type).length + 1).toString()
  },

  /**
   * Whether or not the batch annotation mode is enabled and available for a particular corpus.
   * The batch mode can be enabled without setting a correct default type, so "availability" here
   * defines whether or not an annotation can be truly created in batch mode.
   * If batch mode is unavailable, an error will be thrown by the create action.
   */
  batchCreateAvailable: state => corpusId => state.batchCreation && state.defaultType[corpusId] && state.defaultClass[corpusId] !== undefined
}

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  actions,
  getters
}
