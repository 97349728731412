import { errorParser } from '@/helpers'
import { assign } from 'lodash'
import { SEARCH_FILTER_MAX_TERMS } from '@/config'
import * as api from '@/api'

export const initialState = () => ({
  hasMlClasses: {},

  // All loaded ML classes, per ID
  classes: {}
})

export const mutations = {
  setHasMlClasses (state, { id, value }) {
    /*
     * Since ML classes are retrieved using "lazy" requests to avoid loading thousands of classes,
     * but some components still need to know if there are ML classes on a given corpus,
     * we keep a cache of booleans for each corpus ID stating whether or not there are classes on this corpus.
     */
    if (Object.keys(state.hasMlClasses).includes(id)) return
    state.hasMlClasses = {
      ...state.hasMlClasses,
      [id]: value
    }
  },
  reset (state) {
    assign(state, initialState())
  },
  addMlClass (state, mlClass) {
    state.classes = {
      ...state.classes,
      [mlClass.id]: mlClass
    }
  },
  removeMlClass (state, mlClassId) {
    const newClasses = { ...state.classes }
    delete newClasses[mlClassId]
    state.classes = newClasses
  }
}

export const actions = {
  async listCorpusMlClasses ({ commit }, payload) {
    try {
      if (payload.search) {
        // Prevent 400 errors with too many search terms
        const words = [...new Set(payload.search.split(/\s+/))]
        if (words.length > SEARCH_FILTER_MAX_TERMS) {
          payload.search = words.slice(0, SEARCH_FILTER_MAX_TERMS).join(' ')
        }
      }
      const data = await api.listCorpusMlClasses(payload)
      if (!payload.search) commit('setHasMlClasses', { id: payload.id, value: data.count > 0 })

      // Store ml classes in this store, indexed per id
      data.results.map(mlClass => commit('addMlClass', mlClass))

      return data
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
    }
  },

  async createCorpusMlClass ({ commit }, payload) {
    try {
      const data = await api.createCorpusMlClass(payload)
      commit('addMlClass', data)
      commit('setHasMlClasses', { id: payload.corpusId, value: true })
    } catch (err) {
      const parsedError = errorParser(err)
      if (parsedError === 'The fields name, corpus must make a unique set.') {
        commit('notifications/notify', { type: 'error', text: `Class ${payload.name} already exists in this corpus.` }, { root: true })
        throw err
      } else {
        commit('notifications/notify', { type: 'error', text: parsedError }, { root: true })
      }
    }
  },

  async editCorpusMlClass ({ commit }, payload) {
    try {
      const data = await api.updateCorpusMlClass(payload)
      commit('addMlClass', data)
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
    }
  },

  async deleteCorpusMlClass ({ commit }, payload) {
    try {
      await api.deleteCorpusMlClass(payload)
      commit('removeMlClass', payload.classId)
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
    }
  },

  /**
   * Perform a request to validate the specified classification.
   */
  async validate ({ commit }, { id, elementId }) {
    try {
      const resp = await api.validateClassification(id)
      commit('elements/updateClassification', { elementId, value: resp.data }, { root: true })
      return resp
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
    }
  },

  /**
   * Perform a request to reject the specified classification.
   */
  async reject ({ commit }, { classification, elementId }) {
    try {
      const resp = await api.rejectClassification(classification.id)
      if (resp.status === 204) {
        // Classification was deleted on rejection
        commit('elements/removeClassification', { elementId, value: classification }, { root: true })
        commit('notifications/notify', { type: 'info', text: 'Classification deleted due to rejection.' }, { root: true })
      } else {
        commit('elements/updateClassification', { elementId, value: resp.data }, { root: true })
      }
      return resp
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
    }
  },

  /**
   * Perform a request to create a new classification for the
   * specified page.
   */
  async create ({ commit }, { mlClass, elementId }) {
    try {
      const classification = await api.createClassification({
        ml_class: mlClass,
        element: elementId
      })
      commit('elements/addClassification', { elementId, value: classification }, { root: true })
      return classification
    } catch (err) {
      if (err.response && err.response.status === 400 && err.response.data && err.response.data.non_field_errors) {
        commit('notifications/notify', { type: 'error', text: 'This classification already exists on this element.' }, { root: true })
      } else {
        commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
      }
    }
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  actions
}
