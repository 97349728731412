<template>
  <div class="box">
    <div class="columns is-vcentered">
      <div class="column">
        <p>
          <strong>{{ job.description }}</strong><br />
          <span class="is-capitalized">{{ job.status }}</span>
          <span :title="statusDate" v-if="statusDate">&nbsp;{{ ago(statusDate) }}</span>
        </p>
        <progress
          class="progress is-primary"
          v-if="job.status === 'started'"
          v-bind="progressAttrs"
        >
        </progress>
      </div>
      <div class="column is-narrow" v-if="canDelete">
        <button
          class="button is-danger"
          :class="{ 'is-loading': loading }"
          :disabled="loading || null"
          v-on:click="deleteJob"
        >
          <i class="icon-trash"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ago } from '@/helpers'

export default {
  props: {
    job: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    loading: false
  }),
  computed: {
    statusDate () {
      let stringDate = this.job.enqueued_at
      if (this.job.status === 'started') stringDate = this.job.started_at
      else if (['finished', 'failed'].includes(this.job.status)) stringDate = this.job.ended_at
      return stringDate ? new Date(stringDate) : null
    },
    canDelete () {
      return this.job.status !== 'started'
    },
    progressAttrs () {
      // Leave the `value` attribute unset when progress is not yet set
      if (Number.isFinite(this.job.progress)) return { value: this.job.progress }
      return {}
    }
  },
  methods: {
    async deleteJob () {
      if (!this.canDelete || this.loading) return
      this.loading = true
      try {
        await this.$store.dispatch('jobs/delete', this.job.id)
      } finally {
        this.loading = false
      }
    },
    ago
  }
}
</script>
