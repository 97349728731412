import { clone, assign } from 'lodash'
import { errorParser } from '@/helpers'
import { setUser as SentryUser } from '@sentry/browser'
import * as api from '@/api'

export const initialState = () => ({
  user: null,
  features: {}
})

export const mutations = {
  updateUser (state, user) {
    state.user = clone(user)

    // Use that user on Sentry to identify issues
    SentryUser({
      id: user.id,
      email: user.email
    })
  },
  updateFeatures (state, features) {
    state.features = clone(features)
  },
  reset (state) {
    assign(state, initialState())
  }
}

export const actions = {
  async get ({ commit, dispatch, getters }) {
    try {
      const data = await api.retrieveUser()
      const { features, ...user } = data
      commit('updateUser', user)
      commit('updateFeatures', features)
      /*
       * Fetch all corpora as soon as the authentication is retrieved.
       * Fetching all corpora should be handled by this module, as the corpora list needs to be reloaded
       * whenever the user's authentication state changes; login, logout, registration, or just at frontend startup.
       *
       * While all components could handle calling corpora/list whenever they need a list of corpora, to handle
       * the initial fetching when the frontend loads, this could cause duplicate API requests and is not really
       * necessary considering that *most* components will need this corpora list.
       */
      dispatch('corpora/list', null, { root: true })
      if (getters.hasFeature('selection') && getters.isVerified) dispatch('selection/get', {}, { root: true })
      return data
    } catch (err) {
      // Not authenticated
      if (err.response && err.response.status === 403) {
        commit('updateUser', false)
        if (err.response.data && err.response.data.features) commit('updateFeatures', err.response.data.features)
      } else throw new Error(errorParser(err))
    }
  },
  async register ({ commit, dispatch, getters }, payload) {
    const { features, ...user } = await api.createUser(payload)
    commit('updateUser', user)
    commit('updateFeatures', features)
    // Reset the whole store, except this module
    await dispatch('reset', { exclude: ['auth'] }, { root: true })
    // Fetch the list of corpora again, as it needed by most components and might have changed after registration
    dispatch('corpora/list', null, { root: true })
    if (getters.hasFeature('selection') && getters.isVerified) dispatch('selection/get', {}, { root: true })
  },
  async login ({ commit, dispatch, getters }, payload) {
    try {
      const { features, ...user } = await api.loginUser(payload)
      commit('updateUser', user)
      commit('updateFeatures', features)
      // Reset the whole store, except this module
      await dispatch('reset', { exclude: ['auth'] }, { root: true })
      // Fetch the list of corpora again, as it needed by most components and might have changed due to logging in
      dispatch('corpora/list', null, { root: true })
      if (getters.hasFeature('selection') && getters.isVerified) dispatch('selection/get', {}, { root: true })
    } catch (err) {
      throw new Error(errorParser(err))
    }
  },
  async logout ({ commit, dispatch, getters }) {
    if (!getters.isLoggedOn) return
    await api.logoutUser()
    commit('updateUser', false)
    // Reset the whole store, except this module
    await dispatch('reset', { exclude: ['auth'] }, { root: true })
    // Fetch the list of corpora again, as it needed by most components and might have changed due to logging out
    dispatch('corpora/list', null, { root: true })
  },
  async sendResetEmail (state, payload) {
    try {
      return (await api.sendResetEmail(payload))
    } catch (err) {
      throw new Error(errorParser(err))
    }
  },
  resetPassword (state, payload) {
    return api.resetUserPassword(payload)
  },
  async transkribusLogin ({ state, commit }, payload) {
    try {
      const data = await api.transkribusLoginUser(payload)
      const user = {
        ...state.user,
        ...data
      }
      commit('updateUser', user)
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: 'Invalid Transkribus email or password.' }, { root: true })
    }
  }
}

export const getters = {
  // Returns true if we know whether the user is authenticated or not
  hasInfo: state => {
    return state.user !== null
  },
  isLoggedOn: (state, getters) => {
    return getters.hasInfo && state.user !== false
  },
  isAdmin: (state, getters) => {
    return getters.isLoggedOn && state.user.is_admin
  },
  isVerified: (state, getters) => {
    return getters.isAdmin || (getters.isLoggedOn && state.user.verified_email)
  },
  hasFeature: state => feature => Boolean(state.features && state.features[feature])
}

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  actions,
  getters
}
