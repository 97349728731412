<template>
  <main class="container is-fluid">
    <h1 class="title">
      Entities in
      <router-link :to="parentRoute">{{ parentName }}</router-link>
    </h1>
    <form v-on:submit.prevent="updateRoute">
      <div class="field">
        <label class="label">Filter by name</label>
        <div class="control">
          <input
            type="text"
            class="input"
            placeholder="Entity name (case-insensitive)"
            v-model="nameFilter"
            :disabled="loading || null"
          />
        </div>
      </div>
    </form>
    <Paginator
      :response="entities"
      :loading="loading"
    >
      <template v-slot:default="{ results }">
        <table class="table is-fullwidth is-hoverable">
          <thead>
            <tr>
              <th>Name</th>
              <th>Metadata</th>
              <th>Worker</th>
            </tr>
          </thead>
          <tbody>
            <Row v-for="entity in results" :key="entity.id" :entity="entity" />
          </tbody>
        </table>
      </template>
    </Paginator>
  </main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { corporaMixin } from '@/mixins.js'
import { errorParser } from '@/helpers'
import Paginator from '@/components/Paginator.vue'
import Row from '@/components/Entity/Row'

export default {
  mixins: [
    corporaMixin
  ],
  components: {
    Paginator,
    Row
  },
  props: {
    corpusId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    loading: false,
    error: null,
    nameFilter: ''
  }),
  computed: {
    ...mapState('entity', ['entities']),
    ...mapState('elements', ['elements']),
    element () {
      return this.elements[this.$route.query.parent]
    },
    parentName () {
      if (this.$route.query.parent) return this.element?.name
      else if (this.corpus) return this.corpus.name
      return null
    },
    parentRoute () {
      if (this.$route.query.parent) {
        return {
          name: 'element-details',
          params: { id: this.$route.query.parent }
        }
      }
      return {
        name: 'navigation',
        params: { corpusId: this.corpusId }
      }
    }
  },
  methods: {
    ...mapActions('entity', ['list']),
    updateRoute () {
      const query = { ...this.$route.query }
      // Always reset the page number to 1
      delete query.page
      if (this.nameFilter) query.name = this.nameFilter
      else delete query.name
      this.$router.push({ ...this.$route, query })
    },
    async load (query) {
      this.loading = true
      if (query.parent && !this.element) this.$store.dispatch('elements/get', { id: query.parent })
      this.nameFilter = query.name
      try {
        await this.list({ id: this.corpusId, ...query })
      } catch (err) {
        this.error = errorParser(err)
      } finally {
        this.loading = false
      }
    }
  },
  beforeRouteEnter ({ query }, from, next) {
    next(vm => vm.load(query))
  },
  beforeRouteUpdate ({ query }) {
    this.load(query)
  }
}
</script>
