<template>
  <aside class="menu">
    <a
      v-if="interactive"
      class="is-pulled-right is-inline-flex has-text-link"
      :title="`${displayTitle} visible elements from the tree`"
      v-on:click="toggle"
    >
      {{ displayTitle }}
      <span
        class="icon-eye"
        :class="{ 'strikethrough': imageShow }"
      ></span>
    </a>
    <ul class="tree">
      <li title="Filter children by type">
        <div class="select is-small">
          <select v-model="typeFilter">
            <option value="">Filter by type…</option>
            <option
              v-for="t in orderedTypes"
              :key="t.slug"
              :value="t.slug"
            >
              {{ truncateShort(t.display_name) }}
              ({{ t.treeCount }}/{{ flatTree.length - 1 }})
            </option>
          </select>
        </div>
      </li>
      <li v-if="orderedFilters.length > 1" title="Filter children by worker run or version">
        <div class="select is-small">
          <select v-model="workerFilter">
            <option value="">Filter by worker run/version…</option>
            <option
              v-for="v in orderedFilters"
              :key="v.id"
              :value="v.id"
            >
              {{ truncateShort(`${v.name}`) }}
              ({{ v.treeCount }}/{{ flatTree.length - 1 }})
            </option>
          </select>
        </div>
      </li>
      <TreeItem
        :node="filteredChildrenTree"
        :interactive="interactive"
        :parent-id="element.id"
        v-on:edit="edit"
        v-on:transcribe="transcribe"
      />
    </ul>
    <EditionForm
      v-if="editionModal && selectedElement"
      v-model:modal="editionModal"
      :element="selectedElement"
    />
    <TranscriptionsModal
      v-if="transcriptionModal && selectedElement"
      v-model:modal="transcriptionModal"
      :element="selectedElement"
    />
  </aside>
</template>

<script>
import { orderBy } from 'lodash'
import { mapState, mapActions } from 'pinia'
import {
  mapState as mapVuexState,
  mapMutations as mapVuexMutations,
  mapGetters as mapVuexGetters,
  mapActions as mapVuexActions
} from 'vuex'

import { MANUAL_WORKER_VERSION } from '@/config'
import { corporaMixin, truncateMixin } from '@/mixins'
import { useDisplayStore } from '@/stores'

import EditionForm from '@/components/Element/EditionForm'
import TranscriptionsModal from '@/components/Element/Transcription/Modal'
import TreeItem from './TreeItem'

export default {
  components: {
    TreeItem,
    EditionForm,
    TranscriptionsModal
  },
  mixins: [
    corporaMixin,
    truncateMixin
  ],
  props: {
    element: {
      // Base element
      type: Object,
      required: true
    },
    interactive: {
      // Make tree interactive to visualize sub-elements or transcriptions
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    selectedElement: null,
    editionModal: false,
    transcriptionModal: false
  }),
  computed: {
    ...mapState(useDisplayStore, ['imageShow']),
    ...mapVuexState('process', ['workerVersions']),
    ...mapVuexGetters('tree', ['tree']),
    corpusId () {
      return this.element.corpus.id
    },
    childrenTree () {
      return this.tree(this.element)
    },
    filteredChildrenTree () {
      if (!this.typeFilter && !this.workerFilter) return this.childrenTree
      return this.filterTree(this.childrenTree)
    },
    flatTree () {
      // Return a flat list of all elements on the tree
      return this.flatten(this.childrenTree)
    },
    flatFilteredTree () {
      // Return a flat list of all elements on the tree
      return this.flatten(this.filteredChildrenTree)
    },
    flatFilteredTreeIds () {
      return this.flatFilteredTree.map(e => e.id)
    },
    treeLengths () {
      const lengths = { fullTree: this.flatTree.length, filteredTree: this.flatFilteredTreeIds.length }
      return lengths
    },
    displayTitle () {
      return this.imageShow ? 'Hide' : 'Display'
    },
    modal () {
      return this.editionModal || this.transcriptionModal
    },
    typeFilter: {
      get () {
        return this.$store.state.tree.typeFilter
      },
      set (newValue) {
        this.setTypeFilter(newValue)
      }
    },
    workerFilter: {
      get () {
        return this.$store.state.tree.workerFilter
      },
      set (newValue) {
        this.setWorkerFilter(newValue)
      }
    },
    orderedTypes () {
      /*
       * Return a list of types ordered by name and with the count of
       * corresponding elements in the tree. The main element is excluded.
       */
      if (!this.corpus.types) return []
      // Count types in the tree except for the main element
      const types = this.flatTree.reduce((obj, elt) => {
        if (elt.id === this.element.id) return obj
        obj[elt.type] = (obj[elt.type] || 0) + 1
        return obj
      }, {})
      // Add the selected type filter to available types even if no element in the tree have this type
      if (this.typeFilter && !Object.keys(types).includes(this.typeFilter)) { types[this.typeFilter] = 0 }
      // Return ordered types with their props (e.g. display name) and elements count in the tree
      return orderBy(
        Object.entries(types).map(([typeSlug, treeCount]) => ({
          ...this.corpus.types[typeSlug] || {}, treeCount
        })),
        t => t.display_name, ['asc']
      )
    },
    /**
     * Return the worker version / worker run UUIDs with the corresponding display names and element counts,
     * ordered by UUID.
     */
    orderedFilters () {
      const namedFilters = this.flatTree.reduce((obj, elt) => {
        if (elt.id === this.element.id) return obj
        if (!elt.worker_run_id && !elt.worker_version_id) obj[MANUAL_WORKER_VERSION] = { name: 'Manual', treeCount: (obj[MANUAL_WORKER_VERSION]?.treeCount || 0) + 1 }
        else if (elt.worker_run) {
          obj[elt.worker_run.id] = { name: elt.worker_run.summary, treeCount: (obj[elt.worker_run.id]?.treeCount || 0) + 1 }
        } else if (elt.worker_version_id && this.workerVersions[elt.worker_version_id]) {
          const versionDetails = this.workerVersions[elt.worker_version_id]
          obj[elt.worker_version_id] = { name: versionDetails.worker.name + ' ' + versionDetails.revision.hash.substring(0, 8), treeCount: (obj[elt.worker_version_id]?.treeCount || 0) + 1 }
        }
        return obj
      }, {})
      return orderBy(
        Object.entries(namedFilters)
          .map(([id, { name, treeCount }]) => {
            return { id, name, treeCount }
          }),
        item => item.id, ['asc']
      )
    }
  },
  methods: {
    ...mapActions(useDisplayStore, ['setImageShow']),
    ...mapVuexMutations('tree', ['setTypeFilter', 'setWorkerFilter']),
    ...mapVuexMutations('annotation', ['setVisibleBulk']),
    ...mapVuexActions('process', ['getWorkerVersion', 'getWorkerRun']),
    flatten (node) {
      return [
        node.element,
        ...node.children.reduce((array, node) => {
          // TODO: Use array.flat?
          array.push(...this.flatten(node))
          return array
        }, [])
      ]
    },
    workerFiltered (node) {
      return (!this.workerFilter ||
        (this.workerFilter === MANUAL_WORKER_VERSION && !node.element.worker_version_id && !node.element.worker_run) ||
        (node.element.worker_version_id === this.workerFilter || node.element.worker_run?.id === this.workerFilter)
      )
    },
    typeFiltered (node) {
      return (!this.typeFilter || node.element.type === this.typeFilter)
    },
    filterTree (node) {
      /*
       * Recursively filter elements of the tree with a corresponding type slug.
       * Parents are preserved if some children match the type filter.
       * Main element is always displayed.
       */
      const filteredChildren = node.children.map(node => this.filterTree(node)).filter(node => node)
      // Return this node if it match filtering itself or via its children
      if ((this.workerFiltered(node) && this.typeFiltered(node)) || filteredChildren.length || node.element.id === this.element.id) {
        return {
          ...node,
          children: filteredChildren
        }
      }
    },
    edit (element) {
      this.selectedElement = element
      this.editionModal = true
    },
    transcribe (element) {
      this.selectedElement = element
      this.transcriptionModal = true
    },
    toggle () {
      this.setImageShow(!this.imageShow)
    }
  },
  watch: {
    modal (open) { if (!open) this.selectedElement = null },
    treeLengths (newValues, oldValues) {
      /*
       * If no elements are shown anymore due to deletion, reset the filters.
       * Only if the total element count in the tree changes, because if a
       * user chooses filters that, combined, don't return anything, they
       * should be able to see it.
       */
      if (newValues.filteredTree === 1 && oldValues.filteredTree > 1 &&
          newValues.fullTree < oldValues.fullTree &&
          (this.typeFilter || this.workerFilter)
      ) {
        this.setTypeFilter('')
        this.setWorkerFilter('')
      }
    },
    flatFilteredTreeIds (newList, oldList) {
      // Removed elements should not be visible anymore
      const removedElts = oldList.filter(id => !newList.includes(id))
      this.setVisibleBulk({ parentId: this.element.id, ids: removedElts, visible: false })
      // Added elements should be visible if zones have been set to be displayed by default
      if (!this.imageShow) return
      const addedElts = newList.filter(id => !oldList.includes(id) && id !== this.element.id)
      this.setVisibleBulk({ parentId: this.element.id, ids: addedElts })
    },
    imageShow: {
      immediate: true,
      handler (value) {
        // Toggle each element visible except the parent
        const ids = this.flatFilteredTreeIds.filter(id => id !== this.element.id)
        this.setVisibleBulk({ parentId: this.element.id, ids, visible: value })
      }
    },
    flatTree: {
      handler (newTree) {
        [...new Set(newTree.map(elt => elt.worker_version_id))].filter(id => id && !this.workerVersions[id]).map(id => this.getWorkerVersion(id))
      }
    }
  }
}
</script>
