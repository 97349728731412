import axios from 'axios'
import { unique } from '.'

// Get all visible corpora.
export const listCorpora = unique(async () => (await axios.get('/corpus/')).data)

// Create a corpus.
export const createCorpus = async corpus => (await axios.post('/corpus/', corpus)).data

/*
 * Update a single corpus.
 * Expects an object with an ID.
 * Fields other than the ID will be sent in the request.
 */
export const updateCorpus = async ({ id, ...data }) => (await axios.patch(`/corpus/${id}/`, data)).data

// Delete a corpus by ID
export const destroyCorpus = unique(corpusId => axios.delete(`/corpus/${corpusId}/`))
