<template>
  <Modal title="Background jobs" :model-value="modelValue" v-on:update:model-value="input">
    <Job v-for="(job, id) in jobs" :key="id" :job="job" />
    <div class="notification" v-if="isEmpty(jobs)">There are no background jobs.</div>

    <template v-slot:footer>
      <button
        class="button"
        v-on:click="list"
        :class="{ 'is-loading': loading }"
        :disabled="loading || null"
      >
        Refresh
      </button>
    </template>
  </Modal>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { isEmpty } from 'lodash'
import Modal from '@/components/Modal.vue'
import Job from './Job'

export default {
  components: {
    Modal,
    Job
  },
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('jobs', ['jobs', 'loading'])
  },
  mounted () {
    // Triggers as soon as a user logs in or the frontend's authentication gets loaded
    this.list()
  },
  methods: {
    ...mapActions('jobs', ['list']),
    isEmpty,
    input (value) {
      // v-model passthrough
      this.$emit('update:modelValue', value)
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      async handler (newValue) {
        if (newValue) await this.$store.dispatch('jobs/startPolling')
        else await this.$store.dispatch('jobs/stopPolling')
      }
    }
  }
}
</script>
