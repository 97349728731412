import { errorParser } from './index'

// Create a process and redirect to elements filtering configuration page.
export const createProcessRedirect = async (store, router, payload) => {
  try {
    const resp = await store.dispatch('process/createProcess', payload)
    router.push({ name: 'process-filter', params: { id: resp.id } })
  } catch (e) {
    store.commit('notifications/notify', { type: 'error', text: `An error occurred creating the process: ${errorParser(e)}` })
  }
}

/**
 * Handle worker user configuration validation errors.
 */
export class ConfigurationValidationError extends Error {
  constructor (errors) {
    super(errors)
    // Not setting this would make the error look like a normal Error
    this.name = this.constructor.name
    this.message = errors
  }
}
