import { TRUNCATE_LENGTHS } from './config'
import { ensureArray } from './helpers'

const truncateFilter = maxLength => text => {
  if (text?.length > maxLength) return text.slice(0, maxLength) + '…'
  return text ?? ''
}

export const truncateMixin = {
  methods: {
    truncateSelect: truncateFilter(TRUNCATE_LENGTHS.select),
    truncateLong: truncateFilter(TRUNCATE_LENGTHS.long),
    truncateShort: truncateFilter(TRUNCATE_LENGTHS.short),
    truncateNotification: truncateFilter(TRUNCATE_LENGTHS.notification),
    truncateId: truncateFilter(TRUNCATE_LENGTHS.id)
  }
}

const corporaFilters = {
  // From a corpus, returns true if the corpus is readable.
  canRead: corpus => corpus && Array.isArray(corpus.rights) && corpus.rights.includes('read'),
  // From a corpus, returns true if the corpus is writable.
  canWrite: corpus => corpus && Array.isArray(corpus.rights) && corpus.rights.includes('write'),
  // From a corpus, returns true if the user has admin rights on the corpus.
  canAdmin: corpus => corpus && Array.isArray(corpus.rights) && corpus.rights.includes('admin'),
  // From a list of types or an object mapping type slugs to types, returns a list of folder or normal types.
  folderTypes: types => ensureArray(types).filter(type => type.folder),
  normalTypes: types => ensureArray(types).filter(type => !type.folder),
  defaultTypes: types => ensureArray(types).filter(type => type.default_view)
}

export const corporaMixin = {
  methods: {
    ...corporaFilters,
    // These cannot be used as filters as they require `this`
    getType (slug) {
      if (!this.corpus || !this.corpus.types) return {}
      return this.corpus.types[slug] || {}
    },
    typeName (slug) {
      // Get the display name for a type by slug. Returns the slug when the type is not found.
      return this.getType(slug).display_name || slug
    }
  },
  computed: {
    corpora () {
      return this.$store.state.corpora.corpora
    },
    corpus () {
      /*
       * Get a single corpus.
       * vue-async-computed does not support parameters on an async computed property;
       * you will need to provide a `corpusId` computed property yourself in the component.
       */
      if (this.corpusId === undefined) {
        // eslint-disable-next-line no-console
        console.warn('The corpus computed from corporaMixin requires this.corpusId to be defined.')
      }
      if (!this.corpusId) return {}
      return this.$store.state.corpora.corpora[this.corpusId] ?? {}
    }
  }
}
