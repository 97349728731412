import { assign } from 'lodash'
import * as api from '@/api'
import { errorParser } from '@/helpers'
import { ELEMENT_LIST_MAX_AUTO_PAGES } from '@/config'

export const initialState = () => ({
  /*
   * Folder data with a list of subfolder IDs:
   * { [folder.id]: { ...folder, subfolders: [id, id, id…] }
   * Using IDs in this way allows avoiding strange recursive code in the store.
   */
  folders: {},
  // List of top-level folder IDs per corpora: { corpusid: [id, id, id…] }
  corpus: {},
  /*
   * Pagination state by corpus ID or parent folder ID
   * to allow loading a portion of the list and load more upon user request
   */
  corpusPagination: {},
  folderPagination: {}
})

export const mutations = {
  addFolders (state, { corpus, folder = null, subfolders }) {
    if (!corpus && !folder) throw new Error('Either a corpus ID or a folder ID are required')

    // Add the folders to the state
    state.folders = {
      ...state.folders,
      ...Object.fromEntries(subfolders.map(subfolder => ([subfolder.id, { ...subfolder, subfolders: [] }])))
    }

    // Add the folders as subfolders of their folder or corpus
    const ids = subfolders.map(subfolder => subfolder.id)
    if (folder) {
      state.folders[folder] = {
        ...(state.folders[folder] || {}),
        subfolders: [
          ...((state.folders[folder] || {}).subfolders || []),
          ...ids
        ]
      }
    } else {
      state.corpus[corpus] = [
        ...new Set([
          ...(state.corpus[corpus] || []),
          ...ids
        ])
      ]
    }
  },
  setPagination (state, { corpus, folder = null, ...pagination }) {
    if (folder) state.folderPagination[folder] = pagination
    else state.corpusPagination[corpus] = pagination
  },
  reset (state) {
    assign(state, initialState())
  }
}

export const actions = {
  async nextFolders ({ commit, getters }, { corpus, folder = null, max = ELEMENT_LIST_MAX_AUTO_PAGES }) {
    if (!corpus && !folder) throw new Error('Either a corpus ID or a folder ID are required')
    try {
      let count = 0
      while (count < max) {
        const pagination = getters.pagination(corpus, folder) || { number: 0 }
        if (pagination.number > 0 && !pagination.next) {
          // No next page
          break
        }

        const endpoint = folder ? api.listElementChildren : api.listElements

        const payload = {
          page: pagination.number + 1,
          folder: true
        }
        if (folder) payload.id = folder
        else {
          payload.corpus = corpus
          payload.top_level = true
        }

        const data = await endpoint(payload)

        commit('addFolders', { corpus, folder, subfolders: data.results })

        /*
         * Copy pagination parameters e.g. all returned parameters except the response body
         * Do not alter data directly has API endpoints may return the same object in case of duplicated requests
         */
        const nextPagination = { ...data }
        delete nextPagination.results

        commit('setPagination', { corpus, folder, ...nextPagination })
        count++
      }
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
      throw err
    }
  }
}

export const getters = {
  pagination: state => (corpus = null, folder = null) => (folder ? state.folderPagination[folder] : state.corpusPagination[corpus]) || null
}

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  actions,
  getters
}
