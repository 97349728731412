<template>
  <polygon
    v-bind="svgProps"
    v-on:mouseover="active && !isHovered && setHoveredId(element.id)"
    v-on:mouseleave="active && setHoveredId(null)"
    v-on:mouseup.left="active && $emit('select', element.id)"
    :class="{ 'is-hovered': active && isHovered }"
  >
    <title>{{ element.name }}</title>
  </polygon>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { svgPolygon } from '@/helpers'
import { INTERACTIVE_POLYGON_COLORS } from '@/config'
export default {
  emits: ['select'],
  props: {
    element: {
      type: Object,
      required: true
    },
    /**
     * Color to display the polygon with.
     * By default, this component will display the polygon using the colors defined in js/config.
     */
    color: {
      type: String,
      default: null
    },
    /**
     * When disabled, this component does not react to mouse events and cannot be hovered or selected.
     */
    active: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState('annotation', ['enabled', 'hoveredId', 'selectedElement', 'batchDeletion']),
    isHovered () {
      if (this.selectedElement && this.selectedElement.id === this.element.id) return false
      return this.hoveredId && this.hoveredId === this.element.id
    },
    isDeleteTarget () {
      return this.enabled && this.batchDeletion && this.isHovered
    },
    isSelected () {
      return this.selectedElement && this.selectedElement.id === this.element.id
    },
    eltColor () {
      // Return the element color
      if (this.color) return this.color
      const mode = this.isSelected ? 'selected' : 'visible'
      return INTERACTIVE_POLYGON_COLORS.element[mode]
    },
    highlighted () {
      // Use CSS highlighting on select and hover
      return this.active && (this.isHovered || this.isSelected)
    },
    svgProps () {
      return {
        points: this.svgPolygon(this.element.zone.polygon),
        stroke: this.isDeleteTarget ? 'red' : this.eltColor,
        fill: this.isDeleteTarget ? 'red' : this.eltColor,
        cursor: 'pointer',
        'stroke-opacity': 1,
        'fill-opacity': this.highlighted ? 0.5 : 0.2,
        'stroke-width': this.highlighted ? 2 : 1,
        'vector-effect': 'non-scaling-stroke'
      }
    }
  },
  methods: {
    ...mapMutations('annotation', ['setHoveredId']),
    svgPolygon
  }
}
</script>
