<template>
  <div class="card">
    <router-link
      :to="{ name: 'element-details', params: { id: element.id }, query: routeQuery }"
      :title="`Navigate to ${element.name}`"
    >
      <div class="card-image">
        <figure class="image">
          <img src="@/assets/missingPreview.jpg" v-if="!thumbnailUrl || imgFailed" />
          <img
            :src="thumbnailUrl"
            loading="lazy"
            v-on:error="imgFailed = true"
            v-else
          />
        </figure>
      </div>
      <div class="card-content" v-if="!compactDisplay">
        <span class="name">{{ truncateLong(element.name) }}</span>
        <template v-if="sortedClasses !== null">
          <span class="tags is-pulled-right">
            <span
              class="tag is-warning"
              v-for="classification in sortedClasses"
              :key="classification.id"
              :title="classification.ml_class.name"
            >
              {{ truncateShort(classification.ml_class.name) }}
            </span>
          </span>
          <span class="is-clearfix"></span>
        </template>
      </div>
    </router-link>
    <div class="card-footer">
      <span class="type" :title="elementTypeName">{{ truncateShort(elementTypeName) }}</span>
      <DeleteModal :element="element">
        <template v-slot:default="{ open, canDelete }">
          <button
            v-if="canDelete"
            title="Delete this element"
            class="button has-text-danger"
            v-on:click.prevent="open"
          >
            <i class="icon-trash"></i>
          </button>
        </template>
      </DeleteModal>
      <template v-if="hasPreview">
        <PreviewDropdown :element-id="element.id">
          <!-- Redefine the dropdown button here to allow the scoped style to remove the borders -->
          <button
            title="Preview this element on its image"
            class="button"
            type="button"
          >
            <i class="icon-eye"></i>
          </button>
        </PreviewDropdown>
      </template>
      <button
        v-if="isVerified && hasFeature('selection')"
        title="Add this element to the selection"
        class="button"
        :class="{ 'is-success': selected }"
        v-on:click.prevent="toggleSelection"
        :disabled="!isVerified || null"
      >
        <i class="icon-check"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import {
  mapState as mapVuexState,
  mapActions as mapVuexActions,
  mapGetters as mapVuexGetters
} from 'vuex'

import { iiifUri } from '@/helpers'
import { truncateMixin, corporaMixin } from '@/mixins'
import { useDisplayStore } from '@/stores'

import DeleteModal from '@/components/Element/DeleteModal'
import PreviewDropdown from './PreviewDropdown'

export default {
  mixins: [
    truncateMixin,
    corporaMixin
  ],
  components: {
    DeleteModal,
    PreviewDropdown
  },
  props: {
    element: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    imgFailed: false
  }),
  computed: {
    ...mapVuexState('selection', ['selection']),
    ...mapState(useDisplayStore, ['displayElementClasses', 'compactDisplay', 'iiifHeight']),
    ...mapVuexGetters('auth', ['isVerified', 'hasFeature']),
    thumbnailUrl () {
      if (this.element.thumbnail_url) return this.element.thumbnail_url
      if (this.element.zone) return iiifUri(this.element.zone, { height: this.iiifHeight(1 / 4) })
      return null
    },
    corpusId () {
      return this.element.corpus.id
    },
    elementType () {
      if (this.element.type) return this.getType(this.element.type)
      else if (this.element.type_id && this.corpus?.types) return Object.values(this.corpus.types).find(({ id }) => id === this.element.type_id)
      return null
    },
    elementTypeName () {
      return this.elementType?.display_name ?? this.elementType?.slug ?? this.element.type ?? ''
    },
    selected () {
      return this.corpusId !== null && this.selection[this.corpusId]?.includes(this.element.id)
    },
    sortedClasses () {
      if (!this.displayElementClasses || !Array.isArray(this.element.classes)) return []
      const classes = [...this.element.classes]
      classes.sort((a, b) => a.ml_class.name.localeCompare(b.ml_class.name))
      return classes
    },
    /**
     * The backend generates thumbnail URLs for all folder elements, or for non-folder elements with a zone.
     * Since most folder elements do not have a zone anyway, we only display the preview modal for non-folder
     * elements with a thumbnail URL.
     */
    hasPreview () {
      return !this.elementType?.folder && this.element.thumbnail_url
    },
    routeQuery () {
      // Add extra information to the route when navigating among elements
      const eltId = this.$route?.params?.id
      if (!eltId) return {}
      return { from: eltId }
    }
  },
  methods: {
    ...mapVuexActions('selection', ['select', 'unselect']),
    toggleSelection () {
      if (this.selected) this.unselect(this.element)
      else this.select({ elements: [this.element] })
    }
  }
}
</script>

<style scoped>
samp {
  color: lightgray;
  font-size: 50%;
}
.type {
  color: lightgray;
  overflow-wrap: anywhere;
  margin: 0 auto 0 .5rem;
}
.name, .corpus-name {
  white-space: nowrap;
}
.name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.2;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
.view {
  width: 100%;
}
.card-content {
  color: #4a4a4a;
}
.card-footer {
  align-items: center;
}
.image img {
  width: auto;
  margin-left: auto;
  margin-right: auto;
  max-height: 330px;
}
.button {
  border-width: 0em;
}
.tags {
  margin-top: .5rem;
  /*
   * .tag has a margin-bottom of 0.5rem to separate many tags on multiple lines.
   * This cancels the margin on the last line, since we only want a separation
   * between multiple lines of tags, not below the tags.
   */
  margin-bottom: -.5rem;
}
</style>
