import axios from 'axios'
import { unique } from '.'

// Retrieve an user
export const retrieveUser = unique(async () => (await axios.get('/user/')).data)

// Create an user
export const createUser = async payload => (await axios.post('/user/new/', payload)).data

// Login an user
export const loginUser = async payload => (await axios.post('/user/login/', payload)).data

// Logout an user
export const logoutUser = unique(async () => await axios.delete('/user/'))

// Send a reset email to an user
export const sendResetEmail = async payload => await axios.post('/user/password-reset/', payload)

// Reset an user's password
export const resetUserPassword = payload => axios.post('/user/password-reset/confirm/', payload)
