import { assign } from 'lodash'
import * as api from '@/api'
import { errorParser } from '@/helpers'

export const initialState = () => ({
  /*
   * Stores ML models and their versions.
   * { [modelId]: model }
   */
  models: {},
  // { [modelVersionId]: modelVersion }
  modelVersions: {}
})

export const mutations = {
  setModels (state, models) {
    state.models = {
      ...state.models,
      ...Object.fromEntries(models.map(model => [model.id, model]))
    }
  },

  setModelVersions (state, versions) {
    state.modelVersions = {
      ...state.modelVersions,
      ...Object.fromEntries(versions.map(v => [v.id, v]))
    }
  },

  removeModelVersion (state, versionId) {
    const newModelVersions = { ...state.modelVersions }
    delete newModelVersions[versionId]
    state.modelVersions = newModelVersions
  },

  reset (state) {
    assign(state, initialState())
  }
}

export const actions = {
  async createModel ({ commit }, params) {
    const resp = await api.createModel(params)
    commit('setModels', [resp])
    return resp
  },

  async listModels ({ commit }, params) {
    const resp = await api.listModels(params)
    commit('setModels', resp.results)
    return resp
  },

  async listModelVersions ({ commit }, { modelId, page = 1 }) {
    const resp = await api.listModelVersions({ id: modelId, page })
    commit('setModelVersions', resp.results)
    return resp
  },

  async retrieveModel ({ commit }, modelId) {
    const resp = await api.retrieveModel(modelId)
    commit('setModels', [resp])
  },

  async getModelVersion ({ commit }, modelVersionId) {
    const resp = await api.retrieveModelVersion(modelVersionId)
    commit('setModelVersions', [resp])
  },

  async deleteModelVersion ({ commit }, { versionId }) {
    try {
      await api.deleteModelVersions(versionId)
      commit('removeModelVersion', versionId)
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
    }
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  actions
}
