import axios from 'axios'
import { unique } from '.'

// List executable workers
export const listWorkers = unique(async params => (await axios.get('/workers/', { params })).data)

// List available worker types on instance
export const listWorkerTypes = unique(async params => (await axios.get('/workers/types/', { params })).data)

// List versions of a worker
export const listWorkerVersions = unique(async ({ workerId, ...params }) => (await axios.get(`/workers/${workerId}/versions/`, { params })).data)

// List worker versions used by elements of a corpus
export const listCorpusWorkerVersions = unique(async ({ id, url = null, ...params }) => (await axios.get(url || `/corpus/${id}/versions/`, { params })).data)

// Retrieve a worker version
export const retrieveWorkerVersion = unique(async id => (await axios.get(`/workers/versions/${id}/`)).data)

// Retrieve a worker
export const retrieveWorker = unique(async id => (await axios.get(`/workers/${id}/`)).data)

// Delete results on a corpus or under a parent element produced by a specified worker version
export const deleteWorkerResults = unique(({ corpus, ...params }) => axios.delete(`/corpus/${corpus}/worker-results/`, { params }))
