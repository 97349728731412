<template>
  <div class="transcription">
    <EditionForm
      v-if="editing"
      :element="element"
      :transcription="transcription"
      v-on:close="editing = false"
    />
    <template v-else>
      <Actions
        class="is-pulled-right mb-1"
        :element="element"
        :transcription="transcription"
        v-on:edit="editing = true"
      />
      <div class="select is-truncated" v-if="entityFilterValues.length">
        <select v-model="filterValue">
          <option value="">No entities</option>
          <option v-for="[id, name] in entityFilterValues" :key="id" :value="id">{{ truncateSelect(name) }}</option>
        </select>
      </div>
      <span class="is-clearfix"></span>
      <Box
        :transcription="transcription"
        :worker-version-filter="workerVersionFilter"
        :worker-run-filter="workerRunFilter"
      />
    </template>
  </div>
</template>

<script>
import { sortBy } from 'lodash'
import { mapState, mapActions } from 'vuex'

import { MANUAL_WORKER_VERSION } from '@/config'
import { truncateMixin } from '@/mixins'

import Actions from './Actions'
import Box from './Box'
import EditionForm from './EditionForm'

export default {
  mixins: [
    truncateMixin
  ],
  props: {
    element: {
      type: Object,
      required: true
    },
    transcription: {
      type: Object,
      required: true
    }
  },
  components: {
    Actions,
    Box,
    EditionForm
  },
  data: () => ({
    workerVersionFilter: '',
    workerRunFilter: '',
    editing: false
  }),
  mounted () {
    if (!this.inTranscription?.[this.transcription.id]?.results) this.$store.dispatch('entity/listInTranscription', { transcriptionId: this.transcription.id })
  },
  computed: {
    ...mapState('entity', ['inTranscription']),
    ...mapState('process', ['workerVersions']),

    /**
     * Values and display names for the options of the entity worker versions/worker runs filter.
     * @returns {[string, string][]} Array of IDs and display names.
     *   The IDs are UUIDs that start with a `run-` prefix for WorkerRuns and a `version-` prefix for WorkerVersions.
     */
    entityFilterValues () {
      const transcriptionEntities = this.inTranscription?.[this.transcription.id]?.results
      if (!transcriptionEntities) return []

      const values = []
      // If there are TranscriptionEntities with no worker run and no worker version, add the manual option as the very first item
      if (transcriptionEntities.some(transcriptionEntity => !transcriptionEntity.worker_version_id && !transcriptionEntity.worker_run)) {
        values.push([`version-${MANUAL_WORKER_VERSION}`, 'Manual'])
      }

      values.push(
        ...sortBy(
          // Turn into an object to make the array unique by ID, but turn back into an array to allow sorting
          Object.entries(Object.fromEntries(
            transcriptionEntities
              .filter(transcriptionEntity => transcriptionEntity.worker_run)
              .map(transcriptionEntity => ([`run-${transcriptionEntity.worker_run.id}`, transcriptionEntity.worker_run.summary])),
            [1, 0]
          ))
        )
      )

      values.push(
        ...sortBy(
          Object.entries(Object.fromEntries(
            transcriptionEntities
              .filter(transcriptionEntity => !transcriptionEntity.worker_run && transcriptionEntity.worker_version_id)
              .map(transcriptionEntity => this.workerVersions[transcriptionEntity.worker_version_id])
              // Ignore worker versions that were not yet loaded
              .filter(version => version)
              .map(version => ([`version-${version.id}`, `${version.worker.name} ${version.revision.hash.substring(0, 8)}`]))
          )),
          [1, 0]
        )
      )

      return values
    },

    /**
     * Since it is not possible for the options of a <select> to use anything other than a single string
     * as a value without causing an infinite stream of bugs, we use strings prefixed with "run-" or "version-"
     * depending on whether we should filter by WorkerRun or by WorkerVersion.
     * This computed property makes the link between the selected option value and the actual filter attributes.
     */
    filterValue: {
      get () {
        if (this.workerRunFilter) return `run-${this.workerRunFilter}`
        else if (this.workerVersionFilter) return `version-${this.workerVersionFilter}`
        else return ''
      },
      set (newValue) {
        if (!newValue) {
          this.workerVersionFilter = ''
          this.workerRunFilter = ''
        } else if (newValue.startsWith('run-')) {
          this.workerVersionFilter = ''
          this.workerRunFilter = newValue.slice(4)
        } else if (newValue.startsWith('version-')) {
          this.workerVersionFilter = newValue.slice(8)
          this.workerRunFilter = ''
        } else throw new Error(`Unsupported filter value ${newValue}`)
      }
    }
  },
  methods: {
    ...mapActions('process', ['getWorkerVersion'])
  },
  watch: {
    inTranscription (newValue) {
      const transcriptionEntities = newValue?.[this.transcription.id]?.results
      if (!transcriptionEntities?.length) return
      // Get every worker version ID of every TranscriptionEntity
      [...new Set(
        transcriptionEntities
          // Ignore those with worker runs, as we will not use their version
          .filter(transcriptionEntity => !transcriptionEntity.worker_run)
          .map(transcriptionEntity => transcriptionEntity.worker_version_id)
      )]
        // Only pick those that are not yet in the store
        .filter(id => id && !this.workerVersions[id])
        // Fetch them
        .map(id => this.getWorkerVersion(id))
    },
    entityFilterValues: {
      handler (newValue) {
        // Automatically select the first available filter option
        if (newValue.length) this.filterValue = newValue[0][0]
      },
      immediate: true
    }
  }
}
</script>

<style scoped>
/* Add some spacing between two transcriptions */
.transcription:not(:last-child) {
  margin-bottom: .5rem;
}
</style>
