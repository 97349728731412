<template>
  <span>
    <a
      class="trigger"
      v-on:click="open"
    >
      Feedback
    </a>
    <Modal v-model="modal" title="Feedback">
      <div v-if="opening" class="feedback-content button is-large is-loading"></div>
      <form v-else class="feedback-content" v-on:submit.prevent="sendFeedback">
        <div class="field">
          <label class="label">E-mail address</label>
          <div class="control">
            <input
              type="email"
              v-model="email"
              class="input"
              :class="{ 'is-danger': errors.email }"
              :disabled="loading || null"
              placeholder="Email address"
            />
            <template v-if="errors.email">
              <p class="help is-danger">{{ errors.email }}</p>
            </template>
          </div>
        </div>
        <div class="field">
          <label class="label">Message</label>
          <div class="control is-expanded">
            <textarea
              class="textarea"
              :class="{ 'is-danger': errors.message }"
              placeholder="Send us your comments or suggestions…"
              v-model="message"
            ></textarea>
            <template v-if="errors.message">
              <p class="help is-danger">{{ errors.message }}</p>
            </template>
          </div>
        </div>

      </form>
      <template v-slot:footer="{ close }">
        <button class="button" v-on:click="close">Cancel</button>
        <button
          class="button is-success has-margin-left"
          :class="{ 'is-loading': loading }"
          v-on:click="sendFeedback"
        >
          Send
        </button>
      </template>
    </Modal>
  </span>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { errorParser, openDoorBellForm, submitDoorBellForm } from '@/helpers'
import Modal from '@/components/Modal.vue'
export default {
  components: {
    Modal
  },
  data: () => ({
    modal: false,
    opening: false,
    loading: false,
    email: '',
    message: '',
    errors: {}
  }),
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('auth', ['isLoggedOn', 'isVerified', 'hasFeature']),
    noLoginPage () {
      if (!this.$route.name) return true
      // Prevent displaying the notification to specific pages
      return ['home', 'login', 'register'].includes(this.$route.name)
    }
  },
  methods: {
    ...mapMutations('notifications', ['notify']),
    async open () {
      // Open the feedback modal once the initial request is done to doorbell.io
      if (this.modal) return
      this.opening = true
      this.modal = true
      try {
        await openDoorBellForm()
      } catch (err) {
        this.notify({ type: 'error', text: `An error occurred opening the feedback form: ${errorParser(err)}` })
        this.modal = false
      } finally {
        this.opening = false
      }
    },
    async sendFeedback () {
      // A simple validation is required as the submit button is out of the form
      if (!this.message) this.errors.message = 'This field is required'
      if (this.message.length > 5000) this.errors.message = 'Feedback message is limited to 5000 characters'
      if (!this.email) this.errors.email = 'This field is required'

      if (this.errors.email || this.errors.message || this.loading) return

      this.loading = true
      try {
        await submitDoorBellForm({
          email: this.email,
          message: this.message,
          url: this.$el.baseURI
        })
        this.notify({ type: 'success', text: 'Thank you for your feedback!' })
        // Clean and close the modal in case of success
        this.message = ''
        this.modal = false
      } catch (err) {
        if (err.response && err.response.status === 400 && err.response.data === 'Invalid email address') {
          this.errors.email = 'Invalid email address'
        } else {
          this.notify({ type: 'error', text: `An error occurred sending the feedback: ${errorParser(err)}` })
        }
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    user: {
      // Pre-fill the email field with the email of a authenticated user
      immediate: true,
      handler (user) { if (user && user.email) this.email = user.email }
    },
    email () { delete this.errors.email },
    message () { delete this.errors.message }
  }
}
</script>

<style lang="scss" scoped>
a.trigger {
  height: 2rem;
  border-bottom: none;
  margin-bottom: 0;
}
.has-margin-left {
  margin-left: auto;
}
.feedback-content {
  min-width: 20rem;
  &.button.is-loading {
    border: none;
  }
}
</style>
