<template>
  <Modal
    :title="`Edit ${element.name}`"
    :model-value="modal"
    v-on:update:model-value="$emit('update:modal', null)"
  >
    <form
      v-on:submit.prevent="updateElement"
      v-on:keyup.enter="updateElement"
    >
      <div class="columns is-compact is-multiline">
        <div
          v-if="element.zone"
          class="column is-narrow"
        >
          <figure class="is-inline-block">
            <ElementImage
              class="image is-flex"
              :element="element"
            />
          </figure>
        </div>

        <div class="column elt-fields">
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Name</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <input
                    :disabled="!canWriteElement(element.id) || null"
                    type="text"
                    class="input"
                    :class="{ 'is-danger': fieldErrors.name }"
                    v-model="name"
                    :maxlength="ELEMENT_NAME_MAX_LENGTH"
                    :placeholder="element.name"
                  />
                  <template v-if="fieldErrors.name">
                    <p class="help is-danger" v-for="err in fieldErrors.name" :key="err">{{ err }}</p>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Type</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <div class="control" title="Filter by type">
                    <span class="select is-fullwidth" :class="{ 'is-danger': fieldErrors.type }">
                      <select v-model="type" :disabled="!canWriteElement(element.id) || null">
                        <option value="" disabled selected>Type…</option>
                        <option v-for="t in sortedTypes" :key="t.slug" :value="t.slug">
                          {{ truncateSelect(t.display_name) }}
                        </option>
                      </select>
                    </span>
                    <template v-if="fieldErrors.type">
                      <p class="help is-danger" v-for="err in fieldErrors.type" :key="err">{{ err }}</p>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <template v-slot:footer="{ close }">
      <span class="button" v-on:click="close">Discard</span>
      <router-link
        :to="{ name: 'element-details', params: { id: element.id } }"
        class="button"
        :disabled="loading || null"
        v-on:click="close"
      >
        View element
      </router-link>
      <span
        class="button is-danger"
        :disabled="!canDelete || null"
        :class="{ 'is-loading': deleteLoading }"
        :title="canDelete ? 'Delete this element and its children' : 'A project administrator right is required to delete this element and its children'"
        v-on:click="deleteElement"
      >
        <i class="icon-trash"></i>
        Delete
      </span>

      <button
        class="button is-success has-margin-left"
        :class="{ 'is-loading': updateLoading }"
        :disabled="loading || !canUpdate || null"
        :title="canUpdateTitle"
        v-on:click="updateElement"
      >
        Update
      </button>
    </template>
  </Modal>
</template>

<script>
import { orderBy } from 'lodash'
import { mapState, mapMutations, mapGetters } from 'vuex'

import { ELEMENT_NAME_MAX_LENGTH } from '@/config'
import { errorParser } from '@/helpers'
import { truncateMixin, corporaMixin } from '@/mixins'

import ElementImage from '@/components/Image/ElementImage.vue'
import Modal from '@/components/Modal.vue'

export default {
  mixins: [
    truncateMixin,
    corporaMixin
  ],
  components: {
    ElementImage,
    Modal
  },
  emits: ['update:modal'],
  props: {
    modal: {
      type: Boolean,
      required: true
    },
    element: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    ELEMENT_NAME_MAX_LENGTH,
    updateLoading: false,
    deleteLoading: false,
    type: '',
    name: '',
    // API fields validation errors
    fieldErrors: {}
  }),
  mounted () {
    this.type = this.element.type
    this.name = this.element.name
  },
  computed: {
    ...mapState('annotation', { defaultType: 'type' }),
    ...mapGetters('elements', {
      // canWrite and canAdmin are already defined on corporaMixin
      canWriteElement: 'canWrite',
      canAdminElement: 'canAdmin'
    }),
    loading () {
      return this.deleteLoading || this.updateLoading
    },
    corpusId () {
      // Corpus ID for corporaMixin
      return this.element.corpus.id
    },
    sortedTypes () {
      if (!this.corpus?.types) return []
      return orderBy(this.corpus.types, [t => t.display_name.toLowerCase(), t => t.slug])
    },
    isValid () {
      return this.type && this.validClassification
    },
    canUpdate () {
      // User has write access and type and name are valid and different from the element's current values
      return !this.loading && this.canWriteElement(this.element.id) && (
        (this.type && this.type !== this.element.type) ||
        (this.name && this.name !== this.element.name)
      )
    },
    canUpdateTitle () {
      if (!this.canWriteElement(this.element.id)) return 'A project write access is required to update this element'
      if (!this.canUpdate) return 'A valid updated type and name are required to update the element'
      return 'Update element'
    },
    canDelete () {
      return !this.loading && this.canAdminElement(this.element.id)
    }
  },
  methods: {
    ...mapMutations('notifications', ['notify']),
    ...mapMutations('annotation', ['selectElement']),
    nameFromElement () {
      // Update name input to default value if not defined
      if (!this.name) this.name = this.element.name
    },
    setErrors (error) {
      // Set field errors from API return value
      if (!error) this.fieldErrors = {}
      else if (!error.response || typeof error.response.data !== 'object') this.fieldErrors = { error: errorParser(error) }
      else this.fieldErrors = error.response.data
    },
    async updateElement () {
      if (this.loading || !this.canUpdate || !this.canWriteElement(this.element.id)) return
      this.updateLoading = true
      this.setErrors(null)
      this.nameFromElement()
      try {
        await this.$store.dispatch('elements/patch', {
          id: this.element.id,
          name: this.name,
          type: this.type
        })
        this.notify({ type: 'success', text: 'Element updated.' })
        this.$emit('update:modal', false)
        this.showModal = false
      } catch (e) {
        this.setErrors(e)
        this.notify({ type: 'error', text: `An error occurred while updating element: ${errorParser(e)}` })
      } finally {
        this.updateLoading = false
      }
    },
    async deleteElement () {
      if (!this.canDelete) return
      this.deleteLoading = true
      try {
        await this.$store.dispatch('elements/delete', { id: this.element.id })
        this.selectElement(null)
        this.$emit('update:modal', false)
        this.showModal = false
      } catch (e) {
        this.notify({ type: 'error', text: `An error occurred while deleting the element: ${errorParser(e)}` })
      } finally {
        this.deleteLoading = false
      }
    }
  },
  watch: {
    element: {
      immediate: true,
      handler (element) {
        if (!element.id) return
        /*
         * Do not retrieve the element again if it already exists in the store,
         * unless it lacks some of the attributes only available from RetrieveElement.
         * Some elements in the store can come from list endpoints such as those of the children tree.
         * This ensures there are no strange behaviors where some actions are only sometimes disabled when they shouldn't,
         * or some element attributes are not displayed at all.
         */
        if (!element.rights) this.$store.dispatch('elements/get', { id: element.id })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.has-margin-left {
  margin-left: auto;
}
.image {
  height: 15rem;
  max-width: 30rem;
}
.elt-fields {
  margin: auto;
  max-width: 25rem;
  & > .field {
    margin-bottom: .20rem;
  }
  & ::placeholder {
    color: #202020;
  }
}
.columns .is-compact {
  margin-bottom: 0;
  & > .column {
    padding-bottom: 0;
  }
}
</style>
