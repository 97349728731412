<template>
  <span v-if="!version" class="button is-loading empty-button"></span>
  <span v-else>
    <span v-if="hasOutsideTitle">Created by <strong>{{ version.worker.name }}</strong></span>
    <div class="dropdown is-hoverable" :class="{ 'is-right': dropdownPosition === 'right' }">
      <strong class="dropdown-trigger">
        <abbr title v-if="displayIcon"><i class="icon-cog-alt"></i></abbr>
        <abbr title v-else>{{ version.worker.name }}</abbr>
      </strong>
      <div class="dropdown-menu">
        <div class="message">
          <div class="message-header has-text-centered" v-if="hasDropdownTitle">
            Created by <em>{{ version.worker.name }}</em>
          </div>
          <div class="message-body">
            <table>
              <tbody>
                <tr>
                  <td class="label">Author</td>
                  <td>{{ version.revision.author }}</td>
                </tr>
                <tr>
                  <td class="label">Created</td>
                  <td>{{ createdDate }}</td>
                </tr>
                <tr>
                  <td class="label">Commit</td>
                  <td>
                    <a
                      :href="version.revision.commit_url"
                      target="_blank"
                      class="is-uid"
                      title="See commit on the worker repository"
                    >
                      {{ shortHash }}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td class="label">Type</td>
                  <td>{{ version.worker.type }}</td>
                </tr>
                <tr>
                  <td class="label">References</td>
                  <td v-if="refsCount">
                    <span v-for="ref in version.revision.refs" :key="ref.id" class="tag is-primary ml-1"> {{ ref.name }}</span>
                  </td>
                  <td v-else>—</td>
                </tr>
                <tr>
                  <td class="label">Slug</td>
                  <td>
                    <router-link
                      title="Display worker details"
                      v-if="isVerified"
                      :to="{ name: 'worker-manage', params: { workerId: version.worker.id } }"
                    >
                      {{ version.worker.slug }}
                    </router-link>
                    <span v-else>{{ version.worker.slug }}</span>
                  </td>
                </tr>
                <tr>
                  <td class="label">ID</td>
                  <td>
                    <ItemId :item-id="version.id" />
                  </td>
                </tr>
                <tr v-if="withConfiguration">
                  <td class="label">Configured with</td>
                  <td>
                    <span v-if="configuration">{{ configuration.name }}</span>
                    <span v-else>—</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </span>
</template>

<script>
// Displays a single worker version with dynamic loading of the related version in a dropdown
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { errorParser } from '@/helpers'
import { ago } from '@/helpers/text'
import ItemId from '@/components/ItemId.vue'

export default {
  components: {
    ItemId
  },
  props: {
    workerVersionId: {
      type: String,
      required: true
    },
    configurationId: {
      type: String,
      default: null
    },
    /**
     * Display the Configuration field.
     * The configuration is only available on worker runs, so it is usually not available on most use cases
     * of this component. Keeping it displayed with a null value can make users believe there was no
     * configuration selected for this worker version, when we actually have no idea.
     */
    withConfiguration: {
      type: Boolean,
      default: false
    },
    /**
     * Adds a header to the dropdown menu, displaying the creator's name.
     * When setting this, the withIcon property is implied.
     */
    hasDropdownTitle: {
      type: Boolean,
      default: false
    },
    /**
     * Whether the worker version's details already have a title outside of this component,
     * specifying the worker version's creator. When setting this, the withIcon property is implied.
     */
    hasOutsideTitle: {
      type: Boolean,
      default: false
    },
    /**
     * Whether to display the icon or not.
     * Implied by both hasDropdownTitle and hasOutsideTitle properties.
     */
    withIcon: {
      type: Boolean,
      default: false
    },
    dropdownPosition: {
      type: String,
      default: 'right',
      validator: value => ['right', 'left'].includes(value)
    }
  },
  data: () => ({
    toggled: false
  }),
  computed: {
    ...mapState('process', ['workers', 'workerVersions', 'workerConfigurations']),
    ...mapGetters('auth', ['isVerified']),
    version () {
      return this.workerVersions[this.workerVersionId]
    },
    configuration () {
      if (!this.configurationId) return
      if (!this.version || !this.version.worker.id) return
      const confs = this.workerConfigurations[this.version.worker.id]
      if (!confs) return
      return confs[this.configurationId]
    },
    refsCount () {
      if (!this.version) return
      return this.version.revision.refs.length
    },
    shortHash () {
      if (!this.version) return
      return this.version.revision.hash && this.version.revision.hash.substring(0, 8)
    },
    createdDate () {
      if (!this.version) return
      return ago(new Date(this.version.revision.created))
    },
    displayIcon () {
      return this.withIcon || this.hasDropdownTitle || this.hasOutsideTitle
    }
  },
  methods: {
    ...mapActions('process', ['getWorkerVersion', 'getWorker', 'getConfiguration']),
    ...mapMutations('notifications', ['notify']),
    async retrieveVersion () {
      if (this.version) return
      this.loading = true
      try {
        await this.getWorkerVersion(this.workerVersionId)
      } catch (err) {
        this.notify({ type: 'error', text: `An error occurred retrieving version "${this.workerVersionId}": ${errorParser(err)}` })
      } finally {
        this.loading = false
      }
    },
    async retrieveConfiguration () {
      if (this.configuration || !this.configurationId) return
      this.loading = true
      try {
        await this.getConfiguration({ workerId: this.version.worker.id, configurationId: this.configurationId })
      } catch (err) {
        this.notify({ type: 'error', text: `An error occurred retrieving configuration "${this.configurationId}": ${errorParser(err)}` })
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    workerVersionId: {
      immediate: true,
      handler () {
        if (!this.workerVersions[this.workerVersionId]) {
          this.retrieveVersion()
        }
      }
    },
    version () {
      if (this.configurationId) {
        this.retrieveConfiguration()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.empty-button {
  border: none;
  vertical-align: middle;
  height: 0;
}
.is-uid {
  font-family: monospace;
  font-size: smaller;
}
td:not(.label){
  padding-left: .5rem;
  text-align: center;
  white-space: nowrap;
}
td {
  border-style: none !important;
}
</style>
