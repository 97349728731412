import axios from 'axios'
import { unique } from '.'

// List configurations of a worker
export const listWorkerConfigurations = unique(async ({ workerId, ...params }) => (await axios.get(`/workers/${workerId}/configurations/`, { params })).data)

// Create a configuration on a worker
export const createWorkerConfiguration = unique(async ({ workerId, configuration }) => (await axios.post(`/workers/${workerId}/configurations/`, configuration)).data)

// Retrieve a worker configuration
export const retrieveWorkerConfiguration = unique(async id => (await axios.get(`/workers/configurations/${id}/`)).data)

// Update a worker configuration
export const updateWorkerConfiguration = unique(async ({ id, ...data }) => (await axios.patch(`/workers/configurations/${id}/`, data)).data)
