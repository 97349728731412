import axios from 'axios'
import { unique } from '.'

// Update a task
export const updateTask = async (url, payload) => (await axios.patch(url, { headers: { 'Cache-Control': 'no-cache' }, ...payload })).data

// List Ponos agents with their status
export const listAgents = unique(async params => (await axios.get('/agents/', { params })).data)

// Retrieve a Ponos agent with its running tasks
export const retrieveAgent = unique(async id => (await axios.get(`/agent/${id}/`)).data)

// List Ponos farms
export const listFarms = unique(async params => (await axios.get('/farms/', { params })).data)
