import axios from 'axios'
import { unique } from '.'

// List OAuth providers (GitLab, etc.)
export const listProviders = unique(async () => (await axios.get('/oauth/providers/')).data)

// List OAuth credentials (known accounts)
export const listCredentials = unique(async params => (await axios.get('/oauth/credentials/', { params })).data)

// Delete a registered OAuth account
export const deleteCredentials = unique(id => axios.delete(`/oauth/credentials/${id}/`))
