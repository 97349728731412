<template>
  <section class="mb-2">
    <!-- TODO: Split into one component for each metadata -->
    <table class="table is-striped is-fullwidth">
      <tbody>
        <tr v-for="data in sortedMeta" :key="data.id">
          <td :title="iconTitle(data)" class="key">
            <i :class="iconClass(data)"></i>
            {{ data.name }}
          </td>
          <td class="value">
            <span class="value" v-if="containsDates(data)">
              <InterpretedDate
                :dates="data.dates"
                :raw-date="data.value"
              />
            </span>
            <span class="value" v-else>
              <router-link
                v-if="data.entity"
                :to="{ name: 'entity-details', params: { id: data.entity.id } }"
              >
                {{ data.entity.name }}
              </router-link>
              <a
                v-else-if="data.type === 'url'"
                :href="data.value"
                target="_blank"
              >
                {{ data.value }}
              </a>
              <template v-else>{{ data.value }}</template>
            </span>
          </td>
          <td class="is-narrow pr-0">
            <!-- Pass v-bind as the last attribute, allowing to override the placeholder or value -->
            <MetadataActions
              :metadata="data"
              v-bind="$attrs"
            />
          </td>
          <td class="is-narrow pl-1">
            <WorkerRunSummary v-if="data.worker_run" :worker-run-details="data.worker_run" icon-only />
            <WorkerVersionDetails v-else-if="data.worker_version" :worker-version-id="data.worker_version" has-dropdown-title />
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
import { isEmpty } from 'lodash'
import { METADATA_TYPES } from '@/config'
import InterpretedDate from '@/components/InterpretedDate.vue'
import MetadataActions from './MetadataActions'
import WorkerRunSummary from '@/components/Process/Workers/WorkerRuns/WorkerRunSummary.vue'
import WorkerVersionDetails from '@/components/Process/Workers/Versions/Details.vue'

export default {
  components: {
    InterpretedDate,
    MetadataActions,
    WorkerRunSummary,
    WorkerVersionDetails
  },
  props: {
    meta: {
      type: Array,
      required: true
    }
  },
  computed: {
    sortedMeta () {
      return [...this.meta].sort((a, b) => a.name.localeCompare(b.name))
    }
  },
  methods: {
    containsDates (metadata) {
      return !metadata.entity && !isEmpty(metadata.dates)
    },
    iconClass (metadata) {
      return METADATA_TYPES[metadata.type]?.icon ?? 'icon-feather'
    },
    iconTitle (metadata) {
      const typeDisplay = METADATA_TYPES[metadata.type]?.display ?? 'Metadata'
      if (metadata.entity) return `${typeDisplay} - ${metadata.entity.type.name}`
      return typeDisplay
    }
  }
}
</script>

<style scoped>
table td.key {
  color: #555;
  padding-left: 0.1em;
}

table {
  border-bottom: 1px solid #EEE;
}
</style>
