import axios from 'axios'
import { unique } from '.'

// List ML classes on a single corpus.
export const listCorpusMlClasses = unique(async ({ id, ...params }) => (await axios.get(`/corpus/${id}/classes/`, { params })).data)

// Retrieve an ML class on a corpus.
export const retrieveMlClass = unique(async ({ corpusId, id }) => (await axios.get(`/corpus/${corpusId}/classes/${id}/`)).data)

// Create a ML class on a single corpus.
export const createCorpusMlClass = async ({ corpusId, ...data }) => (await axios.post(`/corpus/${corpusId}/classes/`, data)).data

// Update a ML class on a single corpus.
export const updateCorpusMlClass = async ({ corpusId, classId, ...data }) => (await axios.patch(`/corpus/${corpusId}/classes/${classId}/`, data)).data

// Delete a ML class on a single corpus.
export const deleteCorpusMlClass = async ({ corpusId, classId }) => await axios.delete(`/corpus/${corpusId}/classes/${classId}/`)
