<template>
  <ul>
    <Folder
      v-for="folder in subfolders"
      :key="folder.id"
      :folder="folder"
      :exclude="exclude"
      :current-folder-id="currentFolderId"
      v-on:selected-folder="f => $emit('selected-folder', f)"
    />
    <li>
      <button
        class="button is-small"
        :class="{ 'is-loading': loading }"
        v-if="hasNext"
        v-on:click="fetchFolders"
      >
        Load more…
      </button>
    </li>
  </ul>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  components: {
    // Using a regular import here would cause issues with circular imports, since Folder also imports FolderList
    Folder: defineAsyncComponent(async () => await import('./Folder.vue'))
  },
  emits: ['selected-folder'],
  props: {
    corpusId: {
      type: String,
      required: true
    },
    folderId: {
      type: String,
      default: null
    },
    // Highlight the currently selected folder
    currentFolderId: {
      type: String,
      default: null
    },
    exclude: {
      type: Array,
      default: null
    }
  },
  data: () => ({
    loading: false
  }),
  methods: {
    ...mapActions('folderpicker', ['nextFolders']),
    async fetchFolders () {
      this.loading = true
      try {
        await this.nextFolders({ corpus: this.corpusId, folder: this.folderId })
      } finally {
        this.loading = false
      }
    }
  },
  computed: {
    ...mapState('folderpicker', { folders: 'folders', corpusFolders: 'corpus' }),
    ...mapGetters('folderpicker', ['pagination']),
    subfolders () {
      let folderIds
      if (this.folderId) {
        const parent = this.folders[this.folderId]
        if (!parent) throw new Error(`Parent folder ${this.folderId} not found in store`)
        folderIds = parent.subfolders || []
      } else {
        folderIds = this.corpusFolders[this.corpusId] || []
      }

      if (this.exclude?.length) {
        folderIds = folderIds.filter(id => !this.exclude.includes(id))
      }

      return folderIds.map(id => this.folders[id])
    },
    hasNext () {
      const pagination = this.pagination(this.corpusId, this.folderId)
      // Assume there can be more folders to load if there simply never have been any loaded folders
      return !pagination || pagination.next
    }
  },
  watch: {
    corpusId: {
      handler () {
        if ((!this.subfolders || !this.subfolders.length) && this.hasNext) this.fetchFolders()
      },
      immediate: true
    }
  }
}
</script>
