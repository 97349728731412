<template>
  <main class="container is-fluid">
    <template v-if="entity">
      <div class="card">
        <header class="card-header">
          <div class="card-header-title">
            <div class="columns is-flex-grow-1">
              <div class="column">
                <span class="tag mr-2" :style="entityStyle(entity.type)">{{ entity.type.name }}</span>
                <span class="title">{{ entity.name }}</span>
              </div>
              <div class="column is-narrow has-text-right">
                in project
                <router-link :to="{ name: 'navigation', params: { corpusId: entity.corpus.id } }">
                  {{ entity.corpus.name }}
                </router-link>
                <br />
                <router-link :to="{ name: 'corpus-entities', params: { corpusId: entity.corpus.id } }">
                  View entities in this project
                </router-link>
              </div>
            </div>
          </div>
        </header>
        <div class="card-content">
          <p class="mb-3" v-if="entity.worker_run || entity.worker_version_id">
            Created by
            <WorkerRunSummary
              v-if="entity.worker_run"
              :worker-run-details="entity.worker_run"
              multiline
            />
            <WorkerVersionDetails
              v-else
              :worker-version-id="entity.worker_version_id"
              dropdown-position="left"
            />
          </p>
          <h2 class="title is-4">Details</h2>
          <ul v-if="hasMetas">
            <li v-for="(value, key) in entity.metas" :key="key">
              {{ key }} : {{ value }}
            </li>
          </ul>
          <div class="notification is-warning" v-else>
            No details available.
          </div>
          <hr />

          <h2 class="title is-4">{{ entity.children.length !== 0 ? entity.children.length : '' }} Parents</h2>
          <table v-if="entity.children.length !== 0">
            <thead>
              <th>Parent name</th>
              <th>Parent role</th>
              <th></th>
              <th>Child role</th>
            </thead>
            <tbody>
              <tr v-for="child in entity.children" :key="child.id">
                <td>
                  <router-link :to="{ name: 'entity-details', params: { id: child.parent.id } }">{{ child.parent.name }}</router-link>
                </td>
                <td>
                  <span class="tag" :style="entityStyle(child.parent.type)">{{ child.role.parent_name }}</span>
                </td>
                <td>
                  <i class="icon-arrow-right"></i>
                </td>
                <td>
                  <span class="tag" :style="entityStyle(child.child.type)">{{ child.role.child_name }}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="notification is-warning" v-else>
            No parents available.
          </div>
          <hr />

          <h2 class="title is-4"> {{ entity.parents.length !== 0 ? entity.parents.length : '' }} Children</h2>
          <table v-if="entity.parents.length !== 0">
            <thead>
              <th>Child name</th>
              <th>Parent role</th>
              <th></th>
              <th>Child role</th>
            </thead>
            <tbody>
              <tr v-for="parent in entity.parents" :key="parent.id">
                <td>
                  <router-link :to="{ name: 'entity-details', params: { id: parent.child.id } }">{{ parent.child.name }}</router-link>
                </td>
                <td>
                  <span class="tag" :style="entityStyle(parent.parent.type)">{{ parent.role.parent_name }}</span>
                </td>
                <td>
                  <i class="icon-arrow-right"></i>
                </td>
                <td>
                  <span class="tag" :style="entityStyle(parent.child.type)">{{ parent.role.child_name }}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="notification is-warning" v-else>
            No children available.
          </div>
        </div>
      </div>

      <h2>Related elements</h2>
      <Paginator
        :response="elements"
        singular="element"
        plural="elements"
      >
        <template v-slot:default="{ results }">
          <ElementList :elements="results" />
        </template>
        <template v-slot:no-results>No elements linked to {{ entity.name }}</template>
      </Paginator>
    </template>
    <div class="notification is-danger" v-else-if="error">
      {{ error }}
    </div>
    <div class="notification is-info" v-else>Loading...</div>
  </main>
</template>

<script>
import { mapState } from 'vuex'
import { errorParser } from '@/helpers'
import Paginator from '@/components/Paginator.vue'
import ElementList from '@/components/Navigation/ElementList.vue'
import WorkerRunSummary from '@/components/Process/Workers/WorkerRuns/WorkerRunSummary.vue'
import WorkerVersionDetails from '@/components/Process/Workers/Versions/Details.vue'

export default {
  components: {
    Paginator,
    ElementList,
    WorkerRunSummary,
    WorkerVersionDetails
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  mounted () {
    this.load(this.id, this.$route.query.page || 1)
  },
  beforeRouteUpdate (to) {
    this.load(to.params.id, to.query.page)
  },
  data: () => ({
    error: null
  }),
  computed: {
    ...mapState('entity', ['entity', 'elements']),
    hasMetas: function () {
      if (this.entity.metas === null) {
        return false
      } else {
        return Object.keys(this.entity.metas).length !== 0
      }
    }
  },
  methods: {
    async load (id, page) {
      if (this.entity?.id === id && this.elements?.number === page) return
      this.error = null
      try {
        await this.$store.dispatch('entity/get', { id })
        await this.$store.dispatch('entity/listElements', { id, page })
      } catch (err) {
        this.error = errorParser(err)
      }
    },
    entityStyle (type) {
      return { 'background-color': `#${type.color}`, color: '#ffffff' }
    }
  }
}
</script>

<style scoped>
li {
  list-style: none;
}
a[disabled] {
  pointer-events: none;
}
</style>
