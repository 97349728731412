import { clone, assign } from 'lodash'
import { errorParser } from '@/helpers'
import * as api from '@/api'

export const initialState = () => ({
  // A single entity retrieved for the entity details page
  entity: null,
  // A page of entities retrieved for the entity list page
  entities: null,
  // A page of entity links found in an element
  links: null,
  // A page of elements related to an entity
  elements: null,
  /*
   * Entities per transcription, with pagination status
   * { [transcription ID]: { count, results, loaded } }
   */
  inTranscription: {}
})

export const mutations = {
  set (state, entity) {
    state.entity = clone(entity)
  },
  setEntities (state, entities) {
    state.entities = clone(entities)
  },
  setLinks (state, links) {
    state.links = links
  },
  setElements (state, elements) {
    state.elements = clone(elements)
  },
  setInTranscription (state, { id, ...page }) {
    let payload = state.inTranscription[id]
    if (!payload || payload.results === null) payload = { loaded: 0, results: [], count: page.count }
    payload.results.push(...page.results)
    payload.loaded += page.results.length
    // Cause a reference update to ensure Vue reactivity triggers properly
    state.inTranscription = {
      ...state.inTranscription,
      [id]: payload
    }
  },
  resetInTranscription (state, id) {
    state.inTranscription[id] = {
      results: null,
      count: null,
      loaded: null
    }
  },
  reset (state) {
    assign(state, initialState())
  }
}

export const actions = {
  async get ({ commit }, payload) {
    try {
      const data = await api.retrieveEntity(payload.id)
      commit('set', data)
    } catch (err) {
      commit('set', null)
      throw err
    }
  },

  async list ({ commit }, payload) {
    try {
      commit('setEntities', await api.listCorpusEntities(payload))
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
      throw err
    }
  },

  async listLinks ({ commit }, payload) {
    try {
      const data = await api.listElementLinks(payload.id)
      commit('setLinks', data)
    } catch (err) {
      commit('setLinks', null)
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
    }
  },

  async listElements ({ commit }, payload) {
    try {
      const data = await api.listEntityElements(payload)
      commit('setElements', data)
    } catch (err) {
      commit('setElements', null)
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
    }
  },

  async listInTranscription ({ commit, dispatch }, { transcriptionId, page = 1 }) {
    // Init inTranscription on first response
    if (page === 1) commit('resetInTranscription', transcriptionId)
    try {
      const data = await api.listTranscriptionEntities({ id: transcriptionId, page })
      commit('setInTranscription', { id: transcriptionId, ...data })
      // Load other pages
      if (data.next) dispatch('listInTranscription', { transcriptionId, page: page + 1 })
    } catch (err) {
      commit('resetInTranscription', transcriptionId)
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
    }
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  actions
}
