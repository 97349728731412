import axios from 'axios'
import { unique } from '.'

// Retrieve an entity
export const retrieveEntity = unique(async id => (await axios.get(`/entity/${id}/`)).data)

// List entities in a corpus
export const listCorpusEntities = unique(async ({ id, ...params }) => (await axios.get(`/corpus/${id}/entities/`, { params })).data)

// List all links for an element
export const listElementLinks = unique(async id => (await axios.get(`/element/${id}/links/`)).data)

// List all elements linked to an entity
export const listEntityElements = unique(async ({ id, ...params }) => (await axios.get(`/entity/${id}/elements/`, { params })).data)

// List all entities linked to a transcription
export const listTranscriptionEntities = unique(async ({ id, ...params }) => (await axios.get(`/transcription/${id}/entities/`, { params })).data)

// List a corpus's entity types
export const listCorpusEntityTypes = unique(async ({ id, ...params }) => (await axios.get(`/corpus/${id}/entity-types/`, { params })).data)

// Create a new corpus entity type
export const createCorpusEntityType = async type => (await axios.post('/entity/types/', type)).data

// Edit a corpus entity type
export const updateCorpusEntityType = async ({ id, ...data }) => (await axios.patch(`/entity/types/${id}/`, data)).data

// Delete a corpus entity type
export const deleteCorpusEntityType = unique(async ({ id, ...params }) => (await axios.delete(`/entity/types/${id}/`, { params })).data)
