<template>
  <fieldset :class="{ 'toggled': toggled && suggestionsCount }">
    <input
      type="text"
      ref="input"
      v-model="input"
      :placeholder="placeholder"
      class="input dropdown-trigger"
      :class="inputClass"
      v-on:click.stop="toggleSuggestions"
      v-on:keyup.stop="navigate"
      v-on:input="check"
    />
    <div
      v-if="toggled && (suggestionsCount || loading)"
      class="dropdown-content is-paddingless"
      :class="isFixed ? 'is-fixed' : 'is-absolute'"
    >
      <span class="dropdown-item" v-if="loading">
        <em>Loading…</em>
      </span>
      <a
        v-for="(suggestion, key) in suggestions"
        :key="key"
        v-on:click="select(key)"
        :title="suggestion"
        :class="{ 'selected': key === current }"
        class="dropdown-item"
      >
        <span
          class="is-left"
          v-for="(chunk, index) in hlText(suggestion)"
          :key="index"
          :class="{ 'has-text-info': chunk.hl }"
        >{{ chunk.text }}</span>
      </a>
      <span class="dropdown-item" v-if="count > SEARCHABLE_SELECT_MAX_MATCHES">
        and {{ count - SEARCHABLE_SELECT_MAX_MATCHES }} more {{ resultsName }}
      </span>
    </div>
  </fieldset>
</template>

<script>
import {
  SEARCHABLE_SELECT_MAX_MATCHES,
  SEARCHABLE_SELECT_SUGGESTION_DELAY
} from '@/config'
import { highlight } from '@/helpers'
export default {
  inject: [
    /*
     * This component requires an implementation of the getSuggestions method.
     *
     * getSuggestions must return an object, or a Promise that resolves to an object
     * of the form { suggestions: {...}, count: 42 }, where suggestions is a { value: name }
     * object equivalent to <option :value=value>{{ name }}</option> in a classic <select>
     * and count is the total amount of results.
     * No more than SEARCHABLE_SELECT_MAX_MATCHES will be displayed.
     *
     * e.g. {                          | [Pa_          ] |
     *   book: 'Partial manuscript',   | Partial manusc… |
     *   page: 'Page',                 | Page            |
     *   ...
     *
     * You can provide getSuggestions using the `provide` component option.
     * https://vuejs.org/v2/guide/components-edge-cases.html#Dependency-Injection
     */
    'getSuggestions'
  ],
  emits: [
    'submit',
    'update:isValid',
    'update:modelValue'
  ],
  expose: ['focus', 'clear'],
  props: {
    modelValue: {
      required: true,
      validator: value => (value === null || typeof value === 'string')
    },
    isValid: {
      type: Boolean,
      default: null
    },
    placeholder: {
      type: String,
      default: 'Input…'
    },
    title: {
      type: String,
      default: ''
    },
    /*
     * Name of what this field returns.
     * Used when there are more suggestions than SEARCHABLE_SELECT_MAX_MATCHES,
     * as `And [count] more [resultsName]`.
     */
    resultsName: {
      type: String,
      default: 'results'
    },
    default: {
      // Default input value
      type: String,
      default: ''
    },
    allowEmpty: {
      // Allow an empty value to be valid
      type: Boolean,
      default: false
    },
    autoSelect: {
      // Validate a value when it is typed
      type: Boolean,
      default: false
    },
    /**
     * The suggestions dropdown will not display correctly inside of any parent that has
     * an `overflow` CSS property set to anything other than `visible`.
     *
     * Bulma's .modal, .modal-card and .modal-card-body use those classes, to let the user
     * scroll in the modal and not the whole page, but for modals where it is certain that
     * there will not be any scrolling, you can enable this attribute to make the suggestions
     * dropdown use `position: fixed`, which will make sure the dropdown gets displayed above
     * everything else.
     */
    isFixed: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    // Retrieved matching suggestions
    suggestions: {},
    /*
     * Total number of results. If this exceeds SEARCHABLE_SELECT_MAX_MATCHES,
     * the remainder is displayed as "And … more results".
     */
    count: 0,
    SEARCHABLE_SELECT_MAX_MATCHES,
    // Current search text
    input: '',
    // Suggestions visibility
    toggled: false,
    // Hovered suggestion using key bindings
    current: null,
    // Fallback when no isValid property is bound
    valid: true,
    // Timeout ID used to debounce suggestion fetching
    suggestionTimeoutId: null,
    // Set to true while the suggestions are loading
    loading: false
  }),
  created () {
    window.addEventListener('click', (e) => {
      // Toggle dropdown off when user clicks outside component
      if (!this.$el.contains(e.target)) this.toggled = false
    })
  },
  computed: {
    suggestionsCount () {
      return this.suggestions ? Object.keys(this.suggestions).length : 0
    },
    inputClass () {
      if (!this.input) return ''
      return this.validInput ? 'is-success' : 'is-danger'
    },
    validInput () {
      // Returns field input validity depending on whether the isValid property is bound
      return this.isValid !== null ? this.isValid : this.valid
    }
  },
  methods: {
    setValidInput (bool) {
      // Update input validity bound prop or data
      if (this.isValid !== null) this.$emit('update:isValid', bool)
      else this.valid = bool
    },
    hlText (suggestion) {
      return highlight(suggestion, this.input.split(/\s+/))
    },
    navigate (event) {
      /*
       * If the user presses Enter and there already is a valid input, trigger a submit event
       * to imply the user is trying to submit a form.
       * Do not submit if the suggestions are currently opened with a selected value, since this
       * means the user is trying to just select another value.  But since the suggestions can be
       * displayed just by clicking, do submit if no value is selected even when they are shown.
       */
      if (this.validInput && event.key === 'Enter' && (!this.toggled || !this.current)) {
        this.$emit('submit', this.current)
        return
      }

      this.toggled = true
      const currentIndex = Object.keys(this.suggestions).indexOf(this.current)
      let newIndex = currentIndex
      const max = this.suggestionsCount
      if (event.key === 'ArrowDown') newIndex++
      else if (event.key === 'ArrowUp') newIndex = Math.max(-1, newIndex - 1)
      else if (event.key === 'Enter' && currentIndex >= 0) {
        this.select(this.current)
        return
      } else if (event.key === 'Escape') {
        this.toggled = false
        return
      } else {
        // An unbound key has been pressed
        return
      }
      newIndex = newIndex % max
      /*
       * JavaScript's modulo can return numbers between -max and max,
       * so we add max when it is negative to get a proper arithmetic modulo
       */
      if (newIndex < 0) newIndex += max
      this.current = Object.keys(this.suggestions)[newIndex]
    },
    toggleSuggestions () {
      this.toggled = !this.toggled
      this.current = null
      if (this.toggled) this.resetSuggestionTimeout()
    },
    select (value) {
      this.toggled = false
      this.setValidInput(true)
      this.$emit('update:modelValue', value)
    },
    resetSuggestionTimeout () {
      if (this.suggestionTimeoutId !== null) clearTimeout(this.suggestionTimeoutId)
      this.suggestionTimeoutId = setTimeout(this.loadSuggestions, SEARCHABLE_SELECT_SUGGESTION_DELAY)
    },
    async loadSuggestions () {
      const queryTerms = this.input.trim()
      let output
      this.loading = true
      try {
        // this.getSuggestions might not always be async; just ensure it is a promise
        output = await Promise.resolve(this.getSuggestions(queryTerms))
      } catch {
        this.suggestions = []
        return
      } finally {
        this.loading = false
      }
      const { suggestions, count = 0 } = output
      // Input has changed while waiting for the response
      if (queryTerms !== this.input.trim()) return
      this.suggestions = suggestions
      this.count = count || this.suggestions.length
    },
    check () {
      // Reset the value as the input text has changed
      if (this.modelValue) this.$emit('update:modelValue', '')
      if (this.autoSelect) {
        // Check if the input is valid
        const match = Object.entries(this.suggestions).find(([, value]) => value === this.input)
        // Select the matched key
        if (match) {
          this.select(match[0])
          return
        }
      }
      if (this.input === '' && this.allowEmpty) {
        this.setValidInput(true)
      } else this.setValidInput(false)
    },
    updateFromValue () {
      if (!this.modelValue) return
      const suggestion = this.suggestions[this.modelValue]
      if (suggestion) {
        this.input = suggestion
      } else {
        this.input = this.default
      }
      this.setValidInput(true)
    },
    focus () {
      this.$refs.input.focus()
    },
    clear () {
      this.input = ''
      this.$emit('update:modelValue', '')
      this.toggled = false
      this.suggestions = []
    }
  },
  watch: {
    input (newValue, oldValue) {
      if (oldValue.trim() === newValue.trim()) return
      if (this.toggled) this.resetSuggestionTimeout()
    },
    suggestions () {
      this.current = null
      this.check()
    },
    modelValue: {
      immediate: true,
      handler: 'updateFromValue'
    },
    default () {
      this.input = this.default
    }
  }
}
</script>

<style lang="scss" scoped>
fieldset.toggled {
  &> input {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  &> .dropdown-content {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
}

.dropdown-content {
  max-width: 60ch;
  z-index: 5;

  &.is-fixed {
    position: fixed;
  }

  &.is-absolute {
    position: absolute;
    // Height of an input with Bulma
    top: 2.25em;
    left: 0;
  }
}

.dropdown-item {
  white-space: normal;
  &:not(:last-child) {
    border-bottom: solid;
    border-width: thin;
    border-color: #eee;
  }
  &.selected {
    background-color: #f4f4f4;
  }
}
</style>
