<template>
  <tr>
    <th class="is-narrow is-vcentered">{{ index }}</th>
    <td colspan="2" v-if="editing">
      <EditionForm
        class="mb-2"
        :element="element"
        :transcription="transcription"
        v-on:close="editing = false"
      />
    </td>
    <template v-else>
      <td>
        <div
          class="has-plain-text"
          :style="orientationStyle(transcription.orientation)"
        >
          <span>{{ transcription.text }}</span>
        </div>
      </td>
      <td class="shrink">
        <Actions
          class="are-medium"
          :element="element"
          :transcription="transcription"
          v-on:edit="editing = true"
        />
      </td>
    </template>
  </tr>
</template>

<script>
import { TEXT_ORIENTATIONS } from '@/config'
import { orientationStyle } from '@/helpers'

import Actions from './Actions'
import EditionForm from './EditionForm'

export default {
  components: {
    Actions,
    EditionForm
  },
  props: {
    index: {
      type: Number,
      required: true
    },
    element: {
      type: Object,
      required: true
    },
    transcription: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    editing: false,
    TEXT_ORIENTATIONS
  }),
  methods: {
    orientationStyle
  }
}
</script>

<style scoped>
textarea.input {
  height: auto;
}
.has-plain-text {
  white-space: pre-line;
  overflow-wrap: anywhere;
}
</style>
