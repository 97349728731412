<template>
  <div>
    <div v-if="!element || !corpusId" class="loading-content loader"></div>
    <template v-else>
      <template v-if="elementType.folder === false">
        <OrientationPanel :element-id="elementId" />
        <hr />
      </template>

      <template v-if="(canWriteElement(elementId) && hasMlClasses[corpusId]) || classifications.length">
        <DropdownContent id="classifications" title="Classifications">
          <Classifications v-if="element.classifications" :element="element" />
          <form v-on:submit.prevent="createClassification">
            <div v-if="canWriteElement(elementId) && hasMlClasses" class="field">
              <p class="control is-expanded">
                <MLClassSelect
                  ref="newClassificationSelect"
                  v-model="selectedNewClassification"
                  v-model:is-valid="validClassification"
                  placeholder="Add a classification"
                  exclude-manual
                  auto-select
                  :classifications="element.classifications"
                  :corpus-id="corpusId"
                />
              </p>
            </div>
            <p class="help is-danger" v-if="manualClassificationExists">A manual classification for this ML class already exists.</p>
          </form>
        </DropdownContent>
        <hr />
      </template>

      <template v-if="elementType.folder === false">
        <DropdownContent id="transcriptions" title="Transcriptions">
          <Transcriptions :element="element" />
          <template v-if="canWriteElement(elementId)">
            <div class="has-text-right">
              <a v-on:click="transcriptionModal = true">
                <button class="button has-text">
                  manage
                </button>
              </a>
            </div>
            <TranscriptionsModal
              v-if="transcriptionModal"
              v-model:modal="transcriptionModal"
              :element="element"
            />
          </template>
        </DropdownContent>
        <hr />
        <template v-if="canWriteElement(elementId)">
          <DropdownContent id="new-transcription" title="New transcription">
            <TranscriptionCreationForm :element="element" />
          </DropdownContent>
          <hr />
        </template>
      </template>

      <DropdownContent id="metadata" title="Metadata">
        <ElementMetadata :corpus-id="corpusId" :element-id="element.id" />
      </DropdownContent>
      <hr />

      <EntityLinks
        :element-id="element.id"
        v-if="elementType.folder === false"
      />
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { corporaMixin } from '@/mixins.js'

import DropdownContent from '@/components/DropdownContent.vue'
import MLClassSelect from '@/components/MLClassSelect.vue'
import EntityLinks from '@/components/Entity/Links.vue'
import Classifications from './Classifications'
import ElementMetadata from './Metadata'
import OrientationPanel from './OrientationPanel'
import Transcriptions from './Transcription'
import TranscriptionsModal from './Transcription/Modal'
import TranscriptionCreationForm from './Transcription/CreationForm'

export default {
  mixins: [
    corporaMixin
  ],
  components: {
    Classifications,
    DropdownContent,
    ElementMetadata,
    EntityLinks,
    MLClassSelect,
    OrientationPanel,
    TranscriptionCreationForm,
    Transcriptions,
    TranscriptionsModal
  },
  props: {
    elementId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    selectedNewClassification: '',
    validClassification: null,
    isSavingNewClassification: false,
    transcriptionModal: false
  }),
  computed: {
    ...mapState('elements', ['elements', 'transcriptions', 'neighbors']),
    ...mapState('process', ['workerVersions', 'workers']),
    ...mapState('classification', ['hasMlClasses']),
    ...mapGetters('elements', {
      // canWrite and canAdmin are already defined in corporaMixin
      canWriteElement: 'canWrite',
      canAdminElement: 'canAdmin'
    }),
    element () {
      return this.elements[this.elementId]
    },
    corpusId () {
      return this.element?.corpus?.id ?? null
    },
    elementType () {
      return this.element ? this.getType(this.element.type) : {}
    },
    manualClassificationExists () {
      // A manual classification with the selected ML class exists; cannot create
      return this.selectedNewClassification &&
        this.element &&
        this.element.classifications &&
        this.element.classifications.find(c => (c.ml_class.id === this.selectedNewClassification && !c.worker_version && !c.worker_run))
    },
    canCreateClassification () {
      return this.element && this.selectedNewClassification && !this.manualClassificationExists && !this.isSavingNewClassification
    },
    classifications () {
      return (this.element && this.element.classifications) || []
    },
    metadata () {
      return (this.element && this.element.metadata) || []
    },
    firstParentId () {
      // Returns the first parent element of this element. Used to redirect to a parent when deleting the element
      const neighbor = this.neighbors?.[this.elementId]?.results?.find(n => n.element?.id === this.elementId)
      // Prevent errors on null parents since ListElementNeighbors can return null parents for paths with 'ghost elements'
      return [...neighbor?.parents ?? []].reverse().find(parent => parent !== null)?.id
    }
  },
  methods: {
    async createClassification () {
      if (!this.canCreateClassification) return
      this.isSavingNewClassification = true
      try {
        await this.$store.dispatch('classification/create', {
          elementId: this.elementId,
          mlClass: this.selectedNewClassification
        })
      } finally {
        this.$refs.newClassificationSelect?.clear()
        this.isSavingNewClassification = false
      }
    }
  },
  watch: {
    elementId: {
      immediate: true,
      handler (id) {
        if (!id) return
        /*
         * Do not retrieve the element again if it already exists in the store,
         * unless it lacks some of the attributes only available from RetrieveElement.
         * Some elements in the store can come from list endpoints such as those of the children tree.
         * This ensures there are no strange behaviors where some actions are only sometimes disabled when they shouldn't,
         * or some element attributes are not displayed at all.
         */
        if (!this.element || this.element.id !== id || !this.element.rights || !this.element.classifications) this.$store.dispatch('elements/get', { id })
      }
    },
    selectedNewClassification () {
      this.createClassification()
    }
  }
}
</script>

<style scoped>
.loading-content {
  font-size: 2.5rem;
  margin: 2.5rem auto 0 auto;
}
.button.has-tooltip-multiline {
  width: 1rem;
  height: 1.5rem;
  margin-right: 1ch;
}
.has-margin-left {
  margin-left: auto;
}
</style>
