<template>
  <div>
    <template v-if="count">
      <MarkdownMetadata
        v-if="markdownMetadata.length"
        :data="markdownMetadata"
        v-on:delete-metadata="confirmDelete"
        v-on:edit-metadata="edit"
      />
      <MetadataPanel
        v-if="standardMetadata.length"
        :meta="standardMetadata"
        v-on:delete-metadata="confirmDelete"
        v-on:edit-metadata="edit"
      />
    </template>
    <Modal v-if="selectedMetadata" v-model="deleteModal" title="Delete metadata">
      <span>
        Are you sure you want to delete this
        <strong>{{ selectedMetadata.type }}</strong>
        metadata ?
        <div class="card is-flex">
          <span class="card-content p-2">
            {{ selectedMetadata.name }} : <strong>{{ selectedMetadata.value }}</strong>
          </span>
        </div>
        This action is irreversible.
      </span>
      <template v-slot:footer="{ close }">
        <button class="button" v-on:click="close">Cancel</button>
        <button
          class="button is-danger"
          :class="{ 'is-loading': isLoading }"
          v-on:click="deleteMetadata"
        >
          Delete
        </button>
      </template>
    </Modal>
    <form v-on:submit.prevent="updateMetadata">
      <Modal
        v-if="selectedMetadata"
        v-model="editModal"
        :title="createModalForm ? 'Create metadata' : 'Edit metadata'"
      >
        <template v-slot:header>
          <p class="modal-card-title">{{ createModalForm ? 'Create' : 'Edit' }} metadata</p>
          <a
            class="mr-2 pl-2"
            :class="freeEdit ? 'has-text-white has-background-danger' : 'has-text-danger'"
            v-on:click="toggleFreeEdit"
            v-if="isAdmin"
            title="Free editing mode (admin only)"
          >
            Free edit
            <i :class="freeEdit ? 'icon-unlock' : 'icon-lock'"></i>
          </a>
        </template>
        <div class="notification is-danger" v-if="freeEdit">
          <strong>Caution! You are in free editing mode</strong><br />
          This mode is restricted to administrators. There is no form validation.<br />
          Please make sure your metadata types and names are correct to preserve data integrity.<br />
          It is highly recommended to define project-wide allowed metadata in the administration panel instead of using this mode.
        </div>
        <div class="notification is-warning" v-if="!allowedMetadata.length && !freeEdit">
          There are no allowed metadata for this project.
          {{ isAdmin ? 'Use the administration panel to add them.' : 'Please contact your administrator.' }}
        </div>
        <template v-else>
          <div class="field has-addons">
            <template v-if="freeEdit">
              <div class="control">
                <label class="label">
                  <abbr title="Type of metadata value">
                    Type
                  </abbr>
                </label>
                <span class="select">
                  <select v-model="selectedMetadata.type">
                    <option value="" selected disabled>&mdash;</option>
                    <option v-for="type in Object.keys(METADATA_TYPES)" :value="type" :key="type">{{ type }}</option>
                  </select>
                </span>
                <template v-if="formErrors.type">
                  <p class="help is-danger" v-for="(err, i) in formErrors.type" :key="i">{{ err }}</p>
                </template>
              </div>
              <div class="control">
                <label class="label">
                  <abbr title="Metadata name (key)">
                    Name
                  </abbr>
                </label>
                <input
                  class="input"
                  v-model="selectedMetadata.name"
                  type="text"
                  placeholder="Name"
                  :disabled="isLoading || null"
                />
                <template v-if="formErrors.name">
                  <p class="help is-danger" v-for="(err, i) in formErrors.name" :key="i">{{ err }}</p>
                </template>
              </div>
            </template>
            <div class="control" v-else>
              <label class="label">Metadata</label>
              <span class="select">
                <select v-model="selectedAllowedMetadata">
                  <option value="" selected disabled>Select a metadata…</option>
                  <option
                    v-for="(meta, index) in allowedMetadata"
                    :key="index"
                    :value="index"
                  >
                    {{ meta.name }} ({{ meta.type }})
                  </option>
                </select>
              </span>
            </div>
            <div class="control is-expanded" v-if="selectedMetadata.type !== 'markdown'">
              <label class="label">
                <abbr title="Metadata value">
                  Value
                </abbr>
              </label>
              <DateInput
                v-if="selectedMetadata.tyfpe === 'date'"
                class="input"
                v-model="selectedMetadata.value"
                v-on:valid="validDate = $event"
                :disabled="isLoading"
              />
              <input
                v-else
                class="input"
                v-model="selectedMetadata.value"
                type="text"
                placeholder="Value"
                :disabled="isLoading || null"
              />
              <template v-if="formErrors.value">
                <p class="help is-danger" v-for="(err, i) in formErrors.value" :key="i">{{ err }}</p>
              </template>
            </div>
          </div>
          <div class="field" v-if="selectedMetadata.type === 'markdown'">
            <label class="label">
              <abbr title="Metadata value">
                Value
              </abbr>
            </label>
            <textarea
              v-model="selectedMetadata.value"
              placeholder="Value"
              :disabled="isLoading || null"
              class="textarea"
            ></textarea>
          </div>
        </template>
        <template v-slot:footer="{ close }">
          <button class="button" type="button" v-on:click="close">Cancel</button>
          <button
            class="button"
            type="submit"
            :class="{ 'is-loading': isLoading, 'is-info': !freeEdit, 'is-danger': freeEdit }"
          >
            {{ createModalForm ? 'Create' : 'Save' }}
          </button>
        </template>
      </Modal>
    </form>
    <div class="has-text-right">
      <a
        v-if="canWrite(corpus)"
        v-on:click="create"
      >
        <button class="button is-primary">
          <i class="icon-plus"></i>
        </button>
      </a>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import { mapState, mapActions } from 'pinia'
import {
  mapState as mapVuexState,
  mapGetters as mapVuexGetters,
  mapMutations as mapVuexMutations
} from 'vuex'

import { METADATA_TYPES } from '@/config'
import { corporaMixin } from '@/mixins'
import { useDisplayStore } from '@/stores'

import DateInput from '@/components/DateInput'
import Modal from '@/components/Modal'
import MarkdownMetadata from './MarkdownMetadata'
import MetadataPanel from './MetadataPanel'

export default {
  mixins: [
    corporaMixin
  ],
  components: {
    Modal,
    MarkdownMetadata,
    MetadataPanel,
    DateInput
  },
  props: {
    corpusId: {
      type: String,
      required: true
    },
    elementId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    METADATA_TYPES,
    deleteModal: false,
    editModal: false,
    selectedMetadata: null,
    selectedAllowedMetadata: '',
    isLoading: false,
    validDate: true,
    createModalForm: false,
    freeEdit: false,
    formErrors: {}
  }),
  computed: {
    ...mapVuexGetters('auth', ['isAdmin']),
    ...mapVuexState('corpora', ['corpusAllowedMetadata']),
    ...mapVuexState('elements', ['elements']),
    ...mapState(useDisplayStore, ['lastMetadataName', 'lastMetadataType', 'dropdowns']),
    allowedMetadata () {
      return this.corpusAllowedMetadata[this.corpusId] || []
    },
    element () {
      return this.elements[this.elementId]
    },
    metadata () {
      return (this.element && this.element.metadata) || []
    },
    count () {
      return (this.metadata && this.metadata.length)
    },
    markdownMetadata () {
      return this.editableMetadata.filter(m => m.type === 'markdown')
    },
    standardMetadata () {
      return this.editableMetadata.filter(m => m.type !== 'markdown')
    },
    editableMetadata () {
      // Annotate each metadata with an editable property
      if (!this.count) return
      return this.metadata.map(md => ({
        ...md,
        editable: this.isEditable(md)
      }))
    },
    opened () {
      return this.dropdowns.metadata ?? true
    }
  },
  methods: {
    ...mapVuexMutations('notifications', ['notify']),
    ...mapActions(useDisplayStore, ['setLastMetadata']),
    isEditable (md) {
      return this.isAdmin || (this.canWrite(this.corpus) && this.isAllowed(md))
    },
    isAllowed (md = this.selectedMetadata) {
      // Check if metadata is part of allowed ones
      return this.allowedMetadata.some(allowed => allowed.type === md.type && allowed.name === md.name)
    },
    validateForm () {
      this.formErrors = {}
      if (!Object.prototype.hasOwnProperty.call(METADATA_TYPES, this.selectedMetadata.type)) {
        this.formErrors.type = ['Type is invalid']
      }
      if (!this.selectedMetadata.name.trim().length) {
        this.selectedMetadata.name = ''
        this.formErrors.name = ['Name may not be empty']
      }
      if (!(this.selectedMetadata.value ?? '').toString().trim().length) {
        this.selectedMetadata.value = ''
        this.formErrors.value = ['Value may not be empty']
      }
      /*
       * When a metadata is numeric, we might have an `number` value if it was set by the backend,
       * but when it is updated from the v-model, the value will be a string.
       *
       * To validate that the value is a valid number, we have to go through some JavaScript insanity.
       * We need to allow decimal numbers, so parseInt(value, 10) is out of the window.
       * parseFloat() may still return a valid float even when we type something like `1 potato`
       * because it just stops parsing at the first invalid character. It will therefore parse
       * hexadecimal or octal notations as 0, because it will stop at the `x` in `0x4` and just take the 0.
       * The unary + operator, suggested by https://stackoverflow.com/a/175787/, will return NaN for `1 potato`
       * but will parse `0x4` as 4, which is not supported by the backend. Scientific notation is however acceptable.
       * Therefore, to properly validate, we will use the unary + operator and an extra regex to check that it
       * looks like it contains only digits, a dot, an E or +- signs.
       * This allows `-3`, `+4`, `52`, `52.4`, `.4`, `52.`, `-52.E+4`, `1e8`, etc. which are all valid in Python.
       *
       * This will need more rewriting once this component switches to TypeScript,
       * as a numeric metadata will only allow number values and not string values.
       */
      if (this.selectedMetadata.type === 'numeric' && typeof this.selectedMetadata.value !== 'number' && (
        !Number.isFinite(+this.selectedMetadata.value) ||
        !(/^[0-9.E]+$/i.test(this.selectedMetadata.value))
      )) {
        this.formErrors.value = ['Value must be a valid number']
      } else if (this.selectedMetadata.type === 'date' && !this.validDate) {
        this.formErrors.value = ['This date is invalid']
      } else if (this.selectedMetadata.type === 'url') {
        try {
          if (!['http:', 'https:'].includes(new URL(this.selectedMetadata.value).protocol)) this.formErrors.value = ['Only HTTP and HTTPS URLs are allowed']
        } catch (err) {
          this.formErrors.value = [err.message]
        }
      }
      return isEmpty(this.formErrors)
    },
    async updateMetadata () {
      // Assert fields are valid
      if (!this.validateForm()) return
      let endpoint = 'elements/updateMetadata'
      if (this.createModalForm) endpoint = 'elements/createMetadata'
      this.isLoading = true
      try {
        await this.$store.dispatch(endpoint, {
          elementId: this.elementId,
          metadata: this.selectedMetadata
        })
        this.editModal = false
        this.setLastMetadata(this.selectedMetadata.name, this.selectedMetadata.type)
      } catch (err) {
        if (err?.response?.status === 400) this.formErrors = err.response.data
      } finally {
        this.isLoading = false
      }
    },
    async deleteMetadata () {
      this.isLoading = true
      try {
        await this.$store.dispatch('elements/deleteMetadata', {
          elementId: this.elementId,
          metadata: this.selectedMetadata
        })
        this.deleteModal = false
      } finally {
        this.isLoading = false
      }
    },
    confirmDelete (metadata) {
      this.selectedMetadata = metadata
      this.deleteModal = true
    },
    edit (metadata) {
      this.formErrors = {}
      this.createModalForm = false
      this.editModal = true
      this.freeEdit = false
      this.selectedMetadata = { ...metadata }
      this.findAllowedMetadata(this.selectedMetadata)
    },
    create () {
      this.formErrors = {}
      this.editModal = true
      this.createModalForm = true
      this.freeEdit = false
      this.selectedMetadata = {
        type: this.lastMetadataType || '',
        name: this.lastMetadataName || '',
        value: ''
      }
      this.findAllowedMetadata(this.selectedMetadata)
    },
    /**
     * Find and select an existing AllowedMetadata from a metadata.
     * If it is not found, it switches to free edit mode for admins, or unselects.
     */
    findAllowedMetadata ({ type, name }) {
      const foundIndex = this.allowedMetadata.findIndex(
        allowed => allowed.type === type && allowed.name === name
      )
      if (foundIndex < 0) {
        this.freeEdit = this.isAdmin
        this.selectedAllowedMetadata = ''
      } else {
        this.selectedAllowedMetadata = foundIndex
      }
    },
    toggleFreeEdit () {
      this.freeEdit = this.isAdmin && !this.freeEdit
      this.updateSelectedAllowedMetadata()
    },
    updateSelectedAllowedMetadata () {
      if (!this.selectedMetadata || !Number.isInteger(this.selectedAllowedMetadata) || this.selectedAllowedMetadata < 0) return
      const allowedMetadata = { name: this.allowedMetadata[this.selectedAllowedMetadata].name, type: this.allowedMetadata[this.selectedAllowedMetadata].type }
      if (!allowedMetadata) return
      this.selectedMetadata = {
        ...this.selectedMetadata,
        type: allowedMetadata.type,
        name: allowedMetadata.name
      }
    },
    fetchMetadata (elementId) {
      /*
       * This check does not use the computed properties because this might be called with a newValue from a watcher,
       * in which case it is not guaranteed that the computed properties are up to date.
       */
      if (!elementId || this.elements[elementId]?.metadata) return
      this.$store.dispatch('elements/listMetadata', { elementId })
    }
  },
  watch: {
    selectedAllowedMetadata () {
      this.updateSelectedAllowedMetadata()
    },
    corpus: {
      immediate: true,
      handler () {
        // Do fetch allowed metadata instantly after retrieving the corpus
        if (!this.corpus.id || this.allowedMetadata.length) return
        this.$store.dispatch('corpora/listAllowedMetadata', { corpusId: this.corpusId })
      }
    },
    opened: {
      immediate: true,
      handler (newValue) {
        if (newValue) this.fetchMetadata(this.elementId)
      }
    },
    elementId: 'fetchMetadata'
  }
}
</script>

<style scoped>
.card-content {
  max-width: 100ch;
}

textarea {
  min-width: 500px;
}
</style>
