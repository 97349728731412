import axios from 'axios'
import { unique } from '.'

// List or search available repositories for given credentials
export const listAvailableRepositories = unique(
  async (credential, search = null) => (await axios.get(`/process/repos/search/${credential}/`, { params: { search } })).data
)

// List repositories imported on Arkindex
export const listRepositories = unique(async params => (await axios.get('/process/repos/', { params })).data)

// Add a repository and start a process on it
export const createRepository = async ({ credential, data }) => (await axios.post(`/process/repos/search/${credential}/`, data)).data

// Retrieve a repository
export const retrieveRepository = unique(async id => (await axios.get(`/process/repos/${id}/`)).data)

// Delete a repository
export const deleteRepository = unique(id => axios.delete(`/process/repos/${id}/`))
