import axios from 'axios'
import { unique } from '.'

/**
 * Generic method for element list endpoints (ListElements, ListElementParents, ListElementChildren)
 * with support for `If-Modified-Since`. May return null if the API returns HTTP 304 Not Modified.
 */
async function elementList (url, { modifiedSince = null, ...params }) {
  const payload = { params }
  if (modifiedSince) {
    payload.headers = { 'If-Modified-Since': modifiedSince, 'Cache-Control': 'no-cache' }
    // Axios treats HTTP 3xx as errors by default because the browser is supposed to handle them
    payload.validateStatus = status => (status >= 200 && status < 300) || status === 304
  }
  const resp = await axios.get(url, payload)
  if (resp.status === 304) return null
  return resp.data
}

// List an element neighbors
export const listElementNeighbors = unique(id => elementList(`/elements/${id}/neighbors/`, {}))

// List all elements.
export const listElements = unique(({ corpus, ...payload }) => elementList(`/corpus/${corpus}/elements/`, payload))

// List children elements of an element.
export const listElementChildren = unique(({ id, ...payload }) => elementList(`/elements/${id}/children/`, payload))

// List parents elements of an element.
export const listElementParents = unique(({ id, ...payload }) => elementList(`/elements/${id}/parents/`, payload))

// Delete elements asynchronously
export const deleteElements = unique(({ corpus, ...params }) => axios.delete(`/corpus/${corpus}/elements/`, { params }))

// Delete children elements asynchronously
export const deleteElementChildren = unique(({ id, ...params }) => axios.delete(`/elements/${id}/children/`, { params }))

// Retrieve an element.
export const retrieveElement = unique(async id => (await axios.get(`/element/${id}/`)).data)

// Create an element.
export const createElement = async data => (await axios.post('/elements/create/', data)).data

// Update an element.
export const updateElement = async ({ id, ...data }) => (await axios.patch(`/element/${id}/`, data)).data

// Delete an element.
export const deleteElement = unique(async ({ id, ...params }) => (await axios.delete(`/element/${id}/`, { params })).data)

// Move an element to a destination folder.
export const moveElement = async data => (await axios.post('/element/move/', data)).data

// Link an element to a new folder.
export const createParentElement = async ({ childId, parentId }) => (await axios.post(`/element/${childId}/parent/${parentId}/`)).data
