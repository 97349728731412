<template>
  <span>
    <Modal
      v-model="opened"
      :title="'Delete ' + truncateShort(typeName(element.type)) + ' ' + element.name"
    >
      <p>
        Are you sure you want to delete the {{ typeName(element.type) }}
        <strong>{{ element.name }}</strong>
        ?<br />
        Child elements will also be deleted recursively.<br />
        This action is irreversible.
      </p>
      <template v-slot:footer="{ close }">
        <button class="button" v-on:click.prevent="close">Cancel</button>
        <button
          class="button is-danger"
          :class="{ 'is-loading': loading }"
          :disabled="loading || !canDelete || null"
          v-on:click.prevent="performDelete"
        >
          Delete
        </button>
      </template>
    </Modal>
  </span>
</template>

<script>
import { mapGetters } from 'vuex'
import { corporaMixin, truncateMixin } from '@/mixins.js'
import Modal from '@/components/Modal.vue'

export default {
  components: {
    Modal
  },
  mixins: [
    corporaMixin,
    truncateMixin
  ],
  props: {
    // The element to delete.
    element: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    loading: false,
    opened: true
  }),
  computed: {
    ...mapGetters('elements', {
      // canWrite and canAdmin are already defined in corporaMixin
      canWriteElement: 'canWrite',
      canAdminElement: 'canAdmin'
    }),
    ...mapGetters('auth', ['isVerified']),
    // Required for the corporaMixin
    corpusId () {
      return this.element.corpus.id
    },
    /**
     * Allow deleting elements only if the user is verified and has admin rights on the corpus.
     * Additionally, if the element has a `rights` attribute, look for an admin right.
     * This element rights check cannot be done for every element since the rights attribute is not available in all endpoints.
     */
    canDelete () {
      return this.corpus && this.isVerified && this.canAdmin(this.corpus) && (!this.element.rights || this.canAdminElement(this.element.id))
    }
  },
  methods: {
    async performDelete () {
      if (!this.canDelete || this.loading) return
      this.loading = true
      try {
        await this.$store.dispatch('elements/delete', { id: this.element.id })
        this.opened = false
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    element () {
      this.opened = true
    }
  }
}
</script>
