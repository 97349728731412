import axios from 'axios'
import { unique } from '.'

// Create a new model
export const createModel = unique(async params => (await axios.post('/models/', params)).data)

// List available models
export const listModels = unique(async params => (await axios.get('/models/', { params })).data)

export const retrieveModel = unique(async id => (await axios.get(`/model/${id}/`)).data)

// List available model version
export const listModelVersions = unique(async ({ id, ...params }) => (await axios.get(`/model/${id}/versions/`, { params })).data)

// Delete a model version
export const deleteModelVersions = unique(async id => (await axios.delete(`/modelversion/${id}/`)))

// Retrieve a model version
export const retrieveModelVersion = unique(async id => (await axios.get(`/modelversion/${id}/`)).data)
