<template>
  <form v-if="editing && enabled" v-on:submit.prevent="save">
    <div class="field has-addons">
      <p class="control">
        <input
          class="input"
          type="text"
          placeholder="Name"
          :disabled="loading || null"
          v-model="name"
        />
      </p>
      <p class="control">
        <button
          class="button is-primary"
          type="submit"
          :class="{ 'is-loading': loading }"
          :disabled="loading || null"
        >
          <i class="icon-edit"></i>
        </button>
      </p>
    </div>
  </form>
  <div v-else class="is-flex">
    <span :title="instance.name">
      {{ truncateLong(instance.name) }}
    </span>
    <a v-if="enabled" v-on:click="editing = true">
      <i class="icon-edit has-text-info"></i>
    </a>
  </div>
</template>

<script>
import { truncateMixin } from '@/mixins.js'
export default {
  mixins: [
    truncateMixin
  ],
  props: {
    instance: {
      type: Object,
      required: true
    },
    dispatch: {
      type: String,
      required: true
    },
    enabled: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      editing: false,
      loading: false,
      name: this.instance ? this.instance.name : ''
    }
  },
  methods: {
    async save () {
      if (!this.editing || this.loading) return
      this.loading = true
      try {
        await this.$store.dispatch(this.dispatch, {
          id: this.instance.id,
          name: this.name
        })
      } finally {
        this.loading = false
        this.editing = false
      }
    }
  },
  watch: {
    instance () {
      if (!this.editing || this.loading) this.name = this.instance.name
      // Do not keep the editing form open if the element changes
      this.editing = false
    }
  }
}
</script>
