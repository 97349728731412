<template>
  <section class="mb-4">
    <div class="mt-2 pb-5" v-if="manualClassifications.length">
      <strong class="mb-4">Manual</strong>
      <Classification
        v-for="classification in manualClassifications"
        :key="classification.id"
        :classification="classification"
        :disabled="!canWrite(corpus)"
        :element-id="element.id"
      />
    </div>

    <div
      class="mt-2 pb-5"
      v-for="[workerRunId, classifications] in workerRunClassifications"
      :key="workerRunId"
    >
      <WorkerRunSummary
        class="mb-4"
        :worker-run-details="workerRunSummaries[workerRunId]"
      />
      <Classification
        v-for="classification in classifications"
        :key="classification.id"
        :classification="classification"
        :disabled="!canWrite(corpus)"
        :element-id="element.id"
      />
    </div>

    <div
      class="mt-2 pb-5"
      v-for="[versionId, classifications] in workerVersionClassifications"
      :key="versionId"
    >
      <div class="mb-4">
        <WorkerVersionDetails :worker-version-id="versionId" has-outside-title />
      </div>
      <Classification
        v-for="classification in classifications"
        :key="classification.id"
        :classification="classification"
        :disabled="!canWrite(corpus)"
        :element-id="element.id"
      />
    </div>
  </section>
</template>

<script>
import { orderBy, groupBy } from 'lodash'

import { corporaMixin } from '@/mixins.js'

import WorkerRunSummary from '@/components/Process/Workers/WorkerRuns/WorkerRunSummary'
import WorkerVersionDetails from '@/components/Process/Workers/Versions/Details.vue'
import Classification from './Classification'

export default {
  mixins: [
    corporaMixin
  ],
  components: {
    Classification,
    WorkerVersionDetails,
    WorkerRunSummary
  },
  props: {
    element: {
      type: Object,
      required: true,
      validator: element => Array.isArray(element.classifications)
    }
  },
  computed: {
    corpusId () {
      return this.element.corpus.id
    },
    /**
     * Classifications sorted by descending confidence and ascending class name.
     * This sorting is computed once and then re-used by other computed properties
     * to perform the grouping.
     */
    sortedClassifications () {
      return orderBy(
        this.element.classifications,
        ['confidence', 'class_name'],
        ['desc', 'asc']
      )
    },
    manualClassifications () {
      return this.sortedClassifications.filter(classification => !classification.worker_run && !classification.worker_version)
    },
    /**
     * Classifications with worker runs, grouped by their worker run ID and sorted by their worker run summary.
     * @returns {[string, object[]][]}
     */
    workerRunClassifications () {
      const grouped = groupBy(
        this.sortedClassifications.filter(classification => classification.worker_run),
        'worker_run.id'
      )
      return orderBy(Object.entries(grouped), ([id]) => this.workerRunSummaries[id])
    },
    /**
     * Worker run summary serializers mapped to their IDs.
     * @returns {{ [id: string]: { id: string, summary: string } }}
     */
    workerRunSummaries () {
      return Object.fromEntries(
        this.sortedClassifications
          .filter(classification => classification?.worker_run)
          .map(classification => [classification.worker_run.id, classification.worker_run])
      )
    },
    /**
     * Classifications with worker versions and no worker runs, grouped by their worker version ID and sorted only by ID.
     * @returns {[string, object[]][]}
     */
    workerVersionClassifications () {
      const grouped = groupBy(
        this.sortedClassifications.filter(classification => classification.worker_version && !classification.worker_run),
        'worker_version'
      )
      return orderBy(Object.entries(grouped), [0])
    }
  }
}
</script>
