import { assign } from 'lodash'
import * as api from '@/api'
import { errorParser } from '@/helpers'
import { JOBS_POLLING_DELAY } from '@/config'

export const initialState = () => ({
  jobs: {},
  timeoutId: null,
  polling: false,
  loading: false
})

export const mutations = {
  bulkSet (state, jobs) {
    // Turn the job list into an object
    state.jobs = Object.fromEntries(jobs.map(job => [job.id, job]))
  },
  remove (state, jobId) {
    const newJobs = { ...state.jobs }
    delete newJobs[jobId]
    state.jobs = newJobs
  },
  setTimeoutId (state, timeoutId) {
    state.timeoutId = timeoutId
  },
  setPolling (state, polling) {
    state.polling = polling
  },
  setLoading (state, loading) {
    state.loading = loading
  },
  reset (state) {
    assign(state, initialState())
  }
}

export const actions = {
  async list ({ state, commit }) {
    if (state.loading) return
    commit('setLoading', true)
    try {
      commit('bulkSet', await api.listJobs())
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
    } finally {
      commit('setLoading', false)
    }
  },
  async delete ({ commit }, id) {
    try {
      await api.deleteJob(id)
      commit('remove', id)
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
    }
  },
  async startPolling (store) {
    await store.dispatch('stopPolling')
    store.commit('setPolling', true)
    const update = async () => {
      if (store.state.polling) {
        await store.dispatch('list')
        store.commit('setTimeoutId', setTimeout(update, JOBS_POLLING_DELAY))
      }
    }
    await update()
  },
  async stopPolling ({ state, commit }) {
    commit('setPolling', false)
    if (state.timeoutId) clearTimeout(state.timeoutId)
    commit('setTimeoutId', null)
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  actions
}
