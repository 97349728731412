import { orderBy } from 'lodash'

/**
 * Parse EntityTranscriptions to place them on a transcription text using a series of Token components.
 * @typedef EntityTranscription {offset: number, length: number, entity: object}
 * @param {string} text Transcription text to place the entities on.
 * @param {EntityTranscription[]} entities EntityTranscriptions from the API to place onto the text.
 * @param {number} textOffset Global offset to apply to the text when parsing only a portion of the text.
 * @returns {{offset: number, text: string, entity?: object, children: EntityTranscription[], overflow?: boolean}[]}
 *   Sets of properties to be used on the Token component for display.
 */
export const parseEntities = (text, entities, textOffset = 0) => {
  /**
   * The current position in the text.
   * If we go through an entity, this position is set to the end of that entity;
   * if we find another entity that is before this one, we'll add it to the children
   * of the last built token.
   * The Vue component will take care of calling this function again to parse recursively.
   */
  let cursor = 0
  const tokens = []

  for (const { offset, length, entity } of orderBy(entities, ['offset', 'length', 'id'], ['asc', 'desc', 'asc'])) {
    if (cursor > offset) {
      if (!tokens.length) {
        // eslint-disable-next-line no-console
        console.error(`Entity ${entity.id} has a negative offset.`)
      } else {
        tokens[tokens.length - 1].children.push({ offset, length, entity })
      }
      continue
    }

    // There might be some text without an entity before this entity
    const prefix = text.substring(cursor - textOffset, offset - textOffset)
    if (prefix) {
      tokens.push({
        offset: offset - prefix.length,
        text: prefix,
        children: []
      })
    }

    cursor = offset + length
    const entityText = text.substring(offset - textOffset, cursor - textOffset)
    tokens.push({
      offset,
      entity,
      text: entityText,
      children: [],
      // Detects if this entity exceeds the transcription's or the parent entity's text, an unsupported edge case
      overflow: offset + length - textOffset > text.length
    })
  }

  // There might be some text without entities after all the tokens
  const suffix = text.substring(cursor - textOffset)
  if (suffix) tokens.push({ offset: cursor, text: suffix, children: [] })

  return tokens
}
