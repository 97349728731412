<template>
  <div class="columns mt-0 mr-0">
    <div class="column">
      {{ name }}
      <i
        v-if="classification.high_confidence"
        title="High confidence classification"
        class="icon-award has-text-warning"
      ></i>
    </div>
    <div class="column is-narrow is-paddingless">
      <ConfidenceTag :value="classification.confidence" />
      <div class="tags has-addons ml-1">
        <span
          :disabled="disabled || loading || null"
          class="tag button icon-check"
          :class="{ 'is-success': validated, 'is-loading': loading }"
          v-on:click="validate"
        ></span>
        <span
          :disabled="disabled || loading || null"
          class="tag button"
          :class="{ 'is-loading': loading, 'is-danger': rejected, 'icon-trash has-text-danger': isManual, 'is-delete': !isManual }"
          v-on:click="reject"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
import { CLASSIFICATION_STATES } from '@/config'
import ConfidenceTag from '@/components/ConfidenceTag.vue'

export default {
  components: {
    ConfidenceTag
  },
  props: {
    classification: {
      type: Object,
      required: true
    },
    elementId: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: false
  }),
  computed: {
    name () {
      // Normal case where we have a full ml class structure with a name
      if (this.classification.ml_class.name !== undefined) {
        return this.classification.ml_class.name
      }

      // Special case where the serializer has only sent back the ML class id
      if (this.classification.ml_class) {
        try {
          return this.$store.state.classification.classes[this.classification.ml_class].name
        } catch (err) {
          return this.classification.ml_class
        }
      }

      return 'unknown class'
    },
    validated () {
      return this.classification.state === CLASSIFICATION_STATES.validated
    },
    rejected () {
      return this.classification.state === CLASSIFICATION_STATES.rejected
    },
    isManual () {
      return !this.classification.worker_version && !this.classification.worker_run
    }
  },
  methods: {
    async validate () {
      if (this.validated || this.disabled) return
      this.loading = true
      try {
        await this.$store.dispatch('classification/validate', {
          elementId: this.elementId,
          id: this.classification.id
        })
      } finally {
        this.loading = false
      }
    },
    async reject () {
      if (this.rejected || this.disabled) return
      this.loading = true
      try {
        await this.$store.dispatch('classification/reject', {
          elementId: this.elementId,
          classification: this.classification
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.column {
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  align-items: center;
}
.button.is-danger.tag.is-delete:hover, .button.is-danger.tag.is-delete:focus {
  background-color: #ff3529;
}
.button.icon-trash {
  width: 2em;
}
</style>
