import axios from 'axios'
import { unique } from '.'

// List selected elements.
export const listSelection = unique(async params => (await axios.get('/elements/selection/', { params })).data)

// Add a list of element IDs to the selection.
export const addSelection = unique(async ids => axios.post('/elements/selection/', { ids }))

// Remove a single element from the selection.
export const removeSelection = unique(async id => axios.delete('/elements/selection/', { data: { id } }))

// Clear the whole selection.
export const clearSelection = unique(async () => axios.delete('/elements/selection/'))

/*
 * Create classifications for a single ML class on all selected elements by corpus ID.
 * Expects a corpus ID and an ML class ID (from listCorpusMlClasses).
 */
export const createClassificationsSelection = unique(async (corpusId, mlClassId) => axios.post('/classifications/selection/', {
  mode: 'create',
  corpus_id: corpusId,
  ml_class: mlClassId
}))

// Validate all best classes on all selected elements by corpus ID.
export const validateClassificationsSelection = unique(async corpusId => axios.post('/classifications/selection/', {
  mode: 'validate',
  corpus_id: corpusId
}))

// Delete all selected elements by corpus ID.
export const deleteElementsSelection = unique(async corpusId => axios.delete(`/corpus/${corpusId}/selection/`))

// Move selected elements from a corpus to a destination folder.
export const moveSelection = async data => (await axios.post('/elements/selection/move/', data)).data

// Link selected elements in a corpus to a new folder.
export const createParentSelection = async data => (await axios.post('/elements/selection/parent/', data)).data
