import axios from 'axios'
import { PageNumberPaginationParameters, unique } from '.'
import { WorkerRun, PageNumberPagination, UUID } from '@/types'

export interface WorkerRunsParameters extends PageNumberPaginationParameters {
  /**
   * ID of the process to list worker runs from
   */
  id: UUID
}

/**
 * Create a worker run from a process and and worker version.
 */
export interface WorkerRunCreateParameters {
  /**
   * `id` parameter is the UUID of the process.
   */
  id: UUID
  workerRun: {
    worker_version_id: UUID
    parents: UUID[]
    configuration_id?: UUID | null
  }
}

/**
 * Patch an existing worker run.
 * Only configuration and model version properties can be updated.
 */
export interface WorkerRunEditParameters {
  /**
   * `id` parameter is the UUID of the process.
   */
  id: UUID
  payload: {
    parents: UUID[]
    configuration_id?: UUID | null
    model_version_id?: UUID | null
  }
}

// List worker runs for a process
export const listWorkerRuns = unique(
  async ({ id, ...params }: WorkerRunsParameters): Promise<PageNumberPagination<WorkerRun>> =>
    (await axios.get(`/process/${id}/workers/`, { params })).data
)

// Create a worker run on a process
export const createWorkerRun = unique(
  async ({ id, workerRun }: WorkerRunCreateParameters): Promise<WorkerRun> =>
    (await axios.post(`/process/${id}/workers/`, workerRun)).data
)

// Update a worker run on a process
export const updateWorkerRun = unique(
  async ({ id, payload }: WorkerRunEditParameters): Promise<WorkerRun> =>
    (await axios.patch(`/process/workers/${id}/`, payload)).data
)

// Delete a worker run on a process
export const deleteWorkerRun = unique(
  async (id: UUID) => await axios.delete(`/process/workers/${id}/`)
)

// Retrieve a worker run's details
export const retrieveWorkerRun = unique(
  async (id: UUID): Promise<WorkerRun> =>
    (await axios.get(`/process/workers/${id}/`)).data
)
