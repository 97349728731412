<template>
  <div class="is-flex column is-narrow">
    <div class="dropdown is-right is-hoverable">
      <div class="dropdown-trigger">
        <a class="navbar-link">
          Display
        </a>
      </div>
      <div class="dropdown-menu" :class="{ 'focused': updatingPageSize }" role="menu">
        <div
          class="dropdown-content has-text-right"
          v-on:focus.stop
        >
          <div class="dropdown-item" v-if="element">
            <input
              id="switchDetails"
              type="checkbox"
              class="switch is-rtl is-rounded is-info"
              :checked="displayDetails"
              v-on:change="toggleElementDetails"
            />
            <label v-on:focus.prevent="" for="switchDetails">Details</label>
          </div>
          <div class="dropdown-item" v-if="element && elementType.folder">
            <input
              id="switchTree"
              type="checkbox"
              class="switch is-rtl is-rounded is-info"
              :checked="displayFolderTree"
              v-on:change="toggleFolderTree"
            />
            <label for="switchTree">Children tree</label>
          </div>
          <div class="dropdown-item" v-else-if="element && !elementType.folder">
            <input
              id="switchTree"
              type="checkbox"
              class="switch is-rtl is-rounded is-info"
              :checked="displayAnnotationsTree"
              v-on:change="toggleAnnotationsTree"
            />
            <label for="switchTree">Annotations tree</label>
          </div>
          <div class="dropdown-item" v-if="element">
            <input
              id="switchEntityTypes"
              type="checkbox"
              class="switch is-rtl is-rounded is-info"
              :checked="displayEntityTypes"
              v-on:change="toggleEntityTypes"
            />
            <label for="switchEntityTypes">Entity types</label>
          </div>
          <template v-if="!element || elementType.folder">
            <div class="dropdown-item">
              <input
                id="switchLayout"
                type="checkbox"
                class="switch is-rtl is-rounded is-info"
                :checked="elementsTableLayout"
                v-on:change="toggleLayout"
              />
              <label for="switchLayout">List view</label>
            </div>
            <div class="dropdown-item">
              <input
                id="switchCompactDisplay"
                type="checkbox"
                class="switch is-rtl is-rounded is-info"
                :checked="compactDisplay"
                v-on:change="toggleCompactDisplay"
                :disabled="elementsTableLayout || null"
              />
              <label for="switchCompactDisplay">Compact display</label>
            </div>
            <div class="dropdown-item">
              <input
                id="switchClasses"
                type="checkbox"
                class="switch is-rtl is-rounded is-info"
                :checked="displayElementClasses"
                v-on:change="toggleElementClasses"
              />
              <label for="switchClasses">Classes</label>
            </div>
            <div class="dropdown-item">
              <label class="is-size-6 mr-2">Pagination size</label>
              <div class="select is-small page-select">
                <select
                  :value="navigationPageSize"
                  v-on:focus="updatingPageSize = true"
                  v-on:blur="updatingPageSize = false"
                  v-on:change="selectPageSize"
                >
                  <option
                    v-for="size in NAVIGATION_PAGE_SIZES"
                    :key="size"
                    :value="size"
                  >
                    {{ size }}
                  </option>
                </select>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div class="dropdown is-right is-hoverable">
      <div class="dropdown-trigger">
        <a class="navbar-link">
          Actions
        </a>
      </div>
      <div class="dropdown-menu">
        <div class="dropdown-content">
          <!-- Add folder and import file on writable corpora and folder elements -->
          <template v-if="canContain">
            <!-- Add folder is disabled but visible if the corpus does not have a folder type -->
            <a
              class="dropdown-item"
              :disabled="!hasContribPrivilege || !hasFolderTypes || null"
              v-on:click="addFolderModal = hasContribPrivilege && hasFolderTypes"
              :title="hasContribPrivilege && hasFolderTypes ? 'Create a folder on this directory.' : createDisabledTitle"
            >
              <i class="icon-add-square"></i>
              Add folder
            </a>
            <component
              :is="hasContribPrivilege ? 'router-link' : 'a'"
              class="dropdown-item"
              :disabled="!hasContribPrivilege || !hasFolderTypes || null"
              :to="hasContribPrivilege ? { name: 'process-files', params: { corpusId, folderId: elementId } } : ''"
              :title="hasContribPrivilege ? 'Import files in a new folder.' : createDisabledTitle"
            >
              <i class="icon-doc"></i>
              Import files
            </component>
            <component
              :is="hasContribPrivilege ? 'router-link' : 'a'"
              class="dropdown-item"
              :disabled="!hasContribPrivilege || !hasFolderTypes || null"
              :to="hasContribPrivilege ? { name: 'process-buckets', params: { corpusId, folderId: elementId } } : ''"
              :title="hasContribPrivilege ? 'Import files from a bucket into a new folder.' : createDisabledTitle"
            >
              <i class="icon-doc"></i>
              Import files from S3
            </component>
          </template>

          <!-- Move button for elements with write rights -->
          <a
            v-if="isVerified && element"
            class="dropdown-item"
            :disabled="!hasContribPrivilege || null"
            v-on:click.prevent="moveModal = true"
            :title="hasContribPrivilege ? 'Move the current element to another folder.' : createDisabledTitle"
          >
            <i class="icon-direction"></i>
            Move the current element
          </a>

          <!-- New parent element link for elements with write rights -->
          <a
            v-if="isVerified && element"
            class="dropdown-item"
            :disabled="!hasContribPrivilege || null"
            v-on:click.prevent="createParentModal = true"
            :title="hasContribPrivilege ? 'Link the current element to another folder.' : createDisabledTitle"
          >
            <i class="icon-direction"></i>
            Link to another parent
          </a>

          <!-- ML processes for both writable corpora and elements -->
          <a
            class="dropdown-item"
            :disabled="!hasAdminPrivilege || null"
            v-on:click.prevent="createProcess"
            :title="hasAdminPrivilege ? 'Build a new ML process from those elements.' : executeDisabledTitle"
          >
            <i class="icon-cog-alt"></i>
            Create process
          </a>

          <!-- Train a machine learning process. A contributor right is required -->
          <component
            :is="hasContribPrivilege ? 'router-link' : 'a'"
            v-if="!elementId"
            class="dropdown-item"
            :disabled="!hasContribPrivilege || null"
            :to="hasContribPrivilege ? { name: 'training-process', params: { corpusId } } : ''"
            :title="hasContribPrivilege ? 'Train a Machine Learning model.' : createDisabledTitle"
          >
            <i class="icon-cog-alt"></i>
            Train a model
          </component>

          <!-- Edit button for writable corpora -->
          <component
            :is="hasContribPrivilege ? 'router-link' : 'a'"
            v-if="!elementId"
            :disabled="!hasAdminPrivilege || null"
            class="dropdown-item"
            :to="hasAdminPrivilege ? { name: 'corpus-update', params: { corpusId } } : ''"
            :title="hasAdminPrivilege ? 'Edit this project\'s information.' : executeDisabledTitle"
          >
            <i class="icon-edit"></i>
            Project information
          </component>

          <!-- Exports modal for verified users on all corpora -->
          <a v-if="!elementId && isVerified" class="dropdown-item" v-on:click="exportsModal = true">
            <i class="icon-export"></i>
            Manage exports
          </a>

          <router-link
            class="dropdown-item"
            :to="{ name: 'corpus-entities', params: { corpusId }, query: elementId ? { parent: elementId } : {} }"
          >
            <i class="icon-list"></i>
            View entities
          </router-link>

          <!-- Workers statistics for administrable corpora -->
          <component
            :is="hasAdminPrivilege ? 'router-link' : 'a'"
            v-if="!elementId"
            :disabled="!hasAdminPrivilege || null"
            class="dropdown-item"
            :to="hasAdminPrivilege ? { name: 'corpus-workers-activity', params: { corpusId } } : ''"
            :title="hasAdminPrivilege ? 'Display statistics about workers activity on this corpus.' : executeDisabledTitle"
          >
            <i class="icon-chart"></i>
            Workers activity
          </component>

          <template v-if="isVerified && hasFeature('selection')">
            <!-- Select/Unselect button for elements -->
            <a
              v-if="element"
              class="dropdown-item"
              :class="selected ? 'has-text-danger' : 'has-text-info'"
              v-on:click="select"
            >
              <i :class="selected ? 'icon-minus' : 'icon-plus'"></i>
              {{ selected ? 'Remove from selection' : 'Add to selection' }}
            </a>

            <a
              v-if="canContain"
              class="dropdown-item has-text-info"
              title="Not all child elements are selected, only the elements displayed on this page"
              v-on:click="selectAll"
              :disabled="!canSelectAll || null"
            >
              <i class="icon-plus"></i>
              Select all displayed elements
            </a>
          </template>

          <!-- Delete button for Worker Results produced by a specific WorkerVersion on a corpora or an element -->
          <a
            v-if="corpus && !isEmpty(corpus.worker_versions)"
            :disabled="!hasAdminPrivilege || null"
            class="dropdown-item has-text-danger"
            v-on:click="deleteResultsModal = hasAdminPrivilege"
            :title="hasAdminPrivilege ? 'Delete all results produced by a worker version.' : executeDisabledTitle"
          >
            <i class="icon-trash"></i>
            Delete worker results
          </a>

          <!-- Delete button for listed elements in corpora and folders -->
          <a
            v-if="canContain"
            class="dropdown-item has-text-danger"
            :disabled="!canDeleteFiltered || null"
            :title="deleteFilteredTitle"
            v-on:click="deleteFilteredModal = canDeleteFiltered"
          >
            <i class="icon-trash"></i>
            Delete filtered elements
          </a>

          <!-- Delete button for corpora with admin rights and elements without children -->
          <a
            :disabled="!hasAdminPrivilege || null"
            class="dropdown-item has-text-danger"
            v-on:click="deleteModal = hasAdminPrivilege"
            :title="hasAdminPrivilege ? 'Delete this complete directory.' : executeDisabledTitle"
          >
            <i class="icon-trash"></i>
            Delete the current {{ element ? "element" : "project" }}
          </a>

          <!--
            IIIF manifest for folder elements, with viewers in public corpora
            We have to ensure the hrefs are `undefined` to make the <a> tags not link to anything
            when they are clicked; `undefined` removes the attribute entirely.
            href="" would cause a refresh and href="#" can affect the router.
            The `disabled` does not officially exist on <a> tags, so it would not affect them.
          -->
          <template v-if="elementType && elementType.folder">
            <hr class="dropdown-divider" />
            <a
              v-if="miradorUri(elementId)"
              class="dropdown-item"
              :href="corpus.public ? miradorUri(elementId) : undefined"
              :title="corpus.public ? undefined : 'This feature is only available in public projects.'"
              :disabled="!corpus.public || null"
              target="_blank"
            >
              <i class="icon-eye"></i>
              View in Mirador
            </a>
            <a
              v-if="uvUri(elementId)"
              class="dropdown-item"
              :href="corpus.public ? uvUri(elementId) : undefined"
              :title="corpus.public ? undefined : 'This feature is only available in public projects.'"
              :disabled="!corpus.public || null"
              target="_blank"
            >
              <i class="icon-eye"></i>
              View in UV
            </a>
            <a class="dropdown-item" :href="manifestUri(elementId)" target="_blank">
              <i class="icon-code"></i>
              Manifest
            </a>
          </template>
        </div>
      </div>

      <AddFolderModal
        v-model="addFolderModal"
        :corpus-id="corpusId"
        :parent-id="elementId"
      />

      <ExportsModal v-model="exportsModal" :corpus-id="corpusId" />

      <Modal v-if="element" v-model="moveModal" title="Move this element">
        <FolderPicker v-model="pickedFolder" :corpus-id="corpusId" :exclude="canContain ? [elementId] : null" />
        <div class="content message is-warning mb-3" v-if="elementDirectParents && elementDirectParents.length > 1">
          <div class="message-body">
            This element has {{ elementDirectParents.length }} parents:
            <ul class="mt-1 mb-1">
              <li v-for="parent in elementDirectParents" :key="parent.id">
                <strong>{{ typeName(parent.type) }} {{ parent.name }}</strong>
              </li>
            </ul>
            Note that all the links towards previous parents will be removed.
          </div>
        </div>
        <span>
          Moving element <strong>{{ typeName(element.type) }} {{ element.name }}</strong> will also move all its children elements and all attached transcriptions, entities, and classifications.
          <br />
          All current paths will be replaced by the newly created path.
        </span>
        <br />
        <span>This action is irreversible.</span>
        <template v-slot:footer="{ close }">
          <button class="button" v-on:click="close">Cancel</button>
          <button
            class="button is-primary"
            :class="{ 'is-loading': moveLoading }"
            :disabled="!pickedFolder || null"
            v-on:click="performMove"
          >
            Move {{ element.name }}
          </button>
        </template>
      </Modal>

      <Modal v-model="createParentModal" title="Link this element to another parent">
        <FolderPicker v-model="pickedFolder" :corpus-id="corpusId" :exclude="canContain ? [elementId] : null" />
        <template v-slot:footer="{ close }">
          <button class="button" v-on:click="close">Cancel</button>
          <button
            class="button is-primary"
            :class="{ 'is-loading': createParentLoading }"
            :disabled="!pickedFolder || null"
            v-on:click="performCreateParent"
          >
            Link element
          </button>
        </template>
      </Modal>

      <Modal v-model="deleteModal" :title="'Delete ' + (elementId ? 'element' : 'project')">
        <span>
          Are you sure you want to delete
          <template v-if="element">
            element
            <strong>{{ typeName(element.type) }} {{ element.name }}</strong>
          </template>
          <template v-else-if="corpus">
            project <strong>{{ corpus.name }}</strong>
          </template>?
          <br />
          Child elements on this
          <template v-if="element">
            element
          </template>
          <template v-else-if="corpus">
            project
          </template>will also be deleted recursively.
        </span>
        <br />
        <span>This action is irreversible.</span>
        <template v-slot:footer="{ close }">
          <button class="button" v-on:click="close">Cancel</button>
          <button
            class="button is-danger"
            :class="{ 'is-loading': deleteLoading }"
            v-on:click="performDelete"
          >
            Delete
          </button>
        </template>
      </Modal>

      <Modal v-model="deleteFilteredModal" title="Delete elements">
        <p>
          Are you sure you want to delete all the listed elements in this {{ elementId ? 'folder' : 'project' }}?<br />
          This action is irreversible.
        </p>
        <template v-slot:footer="{ close }">
          <div class="buttons is-pulled-right">
            <button class="button" v-on:click="close">Cancel</button>
            <button
              class="button is-danger"
              :class="{ 'is-loading': deleteFilteredLoading }"
              v-on:click="performDeleteFiltered"
            >
              Delete
            </button>
          </div>
        </template>
      </Modal>

      <DeleteResultsModal
        v-model="deleteResultsModal"
        :corpus-id="corpusId"
        :element-id="elementId"
      />
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import { mapState, mapActions } from 'pinia'
import {
  mapState as mapVuexState,
  mapGetters as mapVuexGetters
} from 'vuex'

import { NAVIGATION_PAGE_SIZES } from '@/config'
import { manifestUri, uvUri, miradorUri, createProcessRedirect } from '@/helpers'
import { corporaMixin } from '@/mixins'
import { useDisplayStore } from '@/stores'

import Modal from '@/components/Modal'
import AddFolderModal from '@/components/Navigation/AddFolderModal'
import FolderPicker from '@/components/Navigation/FolderPicker'
import ExportsModal from '@/components/Corpus/ExportsModal'
import DeleteResultsModal from '@/components/Process/Workers/DeleteResultsModal'

export default {
  mixins: [
    corporaMixin
  ],
  components: {
    Modal,
    AddFolderModal,
    FolderPicker,
    ExportsModal,
    DeleteResultsModal
  },
  props: {
    corpusId: {
      type: String,
      required: true
    },
    elementId: {
      type: String,
      default: null
    }
  },
  data: () => ({
    NAVIGATION_PAGE_SIZES,
    deleteModal: false,
    deleteLoading: false,
    deleteResultsModal: false,
    deleteFilteredModal: false,
    deleteFilteredLoading: false,
    addFolderModal: false,
    exportsModal: false,
    updatingPageSize: false,
    moveModal: false,
    createParentModal: false,
    moveLoading: false,
    createParentLoading: false,
    /**
     * pickedFolder is shared between the two Folder Picker components (the one from moving
     * the element and the one from linking it to a new parent element); this only affects
     * the selected folder display in the Folder Pickers (if you select a folder in one of
     * them, it will appear as selected when you open the other), causing no other issue as
     * the two Folder Pickers cannot be open at the same time.
     */
    pickedFolder: null
  }),
  computed: {
    ...mapVuexGetters('auth', [
      'isAdmin',
      'isVerified',
      'hasFeature'
    ]),
    ...mapVuexGetters('elements', {
      // canWrite and canAdmin are already defined in corporaMixin
      canWriteElement: 'canWrite',
      canAdminElement: 'canAdmin'
    }),
    ...mapVuexGetters('navigation', { canDeleteFiltered: 'canDelete' }),
    ...mapVuexState('navigation', { filteredElements: 'elements' }),
    ...mapVuexState('elements', [
      'elements',
      'transcriptions',
      'childrenPagination',
      'neighbors'
    ]),
    ...mapVuexState('entity', ['inTranscription']),
    ...mapState(useDisplayStore, [
      'displayDetails',
      'displayFolderTree',
      'displayAnnotationsTree',
      'displayEntityTypes',
      'displayElementClasses',
      'navigationPageSize',
      'elementsTableLayout',
      'compactDisplay'
    ]),
    ...mapVuexState('selection', ['selection']),
    element () {
      if (!this.elementId) return
      return this.elements[this.elementId] || null
    },
    elementType () {
      if (!this.elementId || !this.element) return
      return this.getType(this.element.type)
    },
    totalChildrenCount () {
      return this.elementId && this.childrenPagination[this.elementId]?.count
    },
    firstParentId () {
      // Returns the first parent element of this element. Used to redirect to a parent when deleting the element
      if (!this.elementId || !this.neighbors[this.elementId]?.results) return null
      const neighbor = this.neighbors[this.elementId].results.find(n => n.element && n.element.id === this.elementId)
      // Prevent errors on null parents since ListElementNeighbors can return null parents for paths with 'ghost elements'
      return [...(neighbor.parents ?? [])].reverse().find(parent => parent !== null)?.id
    },
    elementDirectParents () {
      if (!this.elementId || !this.neighbors[this.elementId]?.results) return null
      // Fetch all direct parents of this element.
      const firstParents = this.neighbors[this.elementId].results
        .filter(({ element, parents }) => element?.id === this.elementId && parents?.length > 0)
        .map(({ parents }) => parents[parents.length - 1])
      // Prevent returning duplicated parents by filtering out identical objects.
      return firstParents.filter((parent, index, parents) => parents.findIndex(p => (p.id === parent.id)) === index)
    },
    hasContribPrivilege () {
      /*
       * Either these actions are shown for an element and the user has write rights on the element,
       * or the user has write rights on the corpus.
       */
      return this.isVerified && (
        (this.elementId && this.canWriteElement(this.elementId)) ||
        (this.corpus && this.canWrite(this.corpus))
      )
    },
    hasAdminPrivilege () {
      /*
       * The user had admin rights on the corpus.
       */
      return this.isVerified && (
        (this.corpus && this.canAdmin(this.corpus))
      )
    },
    canContain () {
      // Determine if the current view show a corpus or a folder
      return !this.elementId || (this.elementType && this.elementType.folder)
    },
    hasFolderTypes () {
      return this.corpus && this.folderTypes(this.corpus.types).length > 0
    },
    createDisabledTitle () {
      let prefix = 'You do not have the required right'
      if (!this.isVerified) prefix = 'Your email address should be verified'
      else if (!this.hasAdminPrivilege) prefix = 'You must have a contributor access to this corpus in order'
      else if (!this.hasFolderTypes) prefix = 'At least one folder type should be declared on the corpus'
      else return null
      return `${prefix} to perform this action.`
    },
    executeDisabledTitle () {
      let prefix = 'You do not have the required right'
      if (!this.isVerified) prefix = 'Your email address should be verified'
      else if (!this.hasAdminPrivilege) prefix = 'You must have an admin access to this corpus in order'
      else return null
      return `${prefix} to perform this action.`
    },
    deleteFilteredTitle () {
      if (this.canDeleteFiltered) {
        return 'Delete all ' +
          (this.elementId ? 'child elements of this folder' : 'elements in this project') +
          ', applying the currently selected filters.'
      }
      return this.executeDisabledTitle ?? 'The currently selected navigation filters cannot be applied to a filtered deletion.'
    },
    selected () {
      return this.selection && Array.isArray(this.selection[this.corpusId]) && this.selection[this.corpusId].includes(this.elementId)
    },
    canSelectAll () {
      return this.isVerified && this.hasFeature('selection') && this.canContain && this.filteredElements?.results?.length
    }
  },
  methods: {
    ...mapActions(useDisplayStore, [
      'toggleElementDetails',
      'toggleFolderTree',
      'toggleAnnotationsTree',
      'toggleEntityTypes',
      'toggleElementClasses',
      'toggleElementsLayout',
      'toggleCompactDisplay',
      'setPageSize'
    ]),
    isEmpty,
    // Update page size and remove focus in the dropdown
    selectPageSize (event) {
      const value = Number.parseInt(event.target.value, 10)
      if (value && NAVIGATION_PAGE_SIZES.includes(value)) this.setPageSize(value)
      document.documentElement.focus()
      this.updatingPageSize = false
    },
    // Cannot call toggleElementsLayout directly from v-on:click because it tries to parse the event as a string
    toggleLayout () {
      this.toggleElementsLayout()
    },
    // Retrieve navigation filters when creating a process
    getProcessFilters () {
      const filters = {}
      if (!this.$route.query) return filters

      // Only name, type and recursive parameters are handled yet
      const { name, type, top_level: topLevel, recursive } = this.$route.query

      if (name) filters.element_name_contains = name
      if (type) filters.element_type = type
      /*
       * Use the backend logic for boolean parameters:
       * recursive (only on ListElementChildren) should cause load_children to be set only when it is not empty,
       * false or zero.
       * top_level (only on ListElements) should have the opposite behavior, since it means "not recursive".
       */
      if (recursive) filters.load_children = !['false', '0'].includes(recursive.toString().toLowerCase())
      else if (topLevel) filters.load_children = ['false', '0'].includes(topLevel.toString().toLowerCase())
      return filters
    },
    createProcess () {
      if (!this.hasAdminPrivilege) return
      const payload = { corpus: this.corpusId, ...this.getProcessFilters() }
      if (this.elementId) payload.element = this.elementId
      createProcessRedirect(this.$store, this.$router, payload)
    },
    async performDelete () {
      if (!this.hasAdminPrivilege) return
      this.deleteLoading = true
      try {
        if (this.elementId) await this.$store.dispatch('elements/delete', { id: this.elementId })
        else await this.$store.dispatch('corpora/delete', { id: this.corpusId })

        this.deleteModal = false

        /*
         * Redirect to the first parent element when available, otherwise redirect to the element's corpus.
         * When removing a corpus, redirect to the corpora list page.
         */
        if (this.elementId) {
          if (this.firstParentId) {
            this.$router.push({ name: 'element-details', params: { id: this.firstParentId } })
          } else {
            this.$router.push({ name: 'navigation', params: { corpusId: this.corpusId } })
          }
        } else this.$router.push({ name: 'corpus-list' })
      } finally {
        this.deleteLoading = false
      }
    },
    async performDeleteFiltered () {
      if (!this.canDeleteFiltered) return
      this.deleteFilteredLoading = true
      await this.$store.dispatch('navigation/delete')
      this.deleteFilteredModal = false
      this.deleteFilteredLoading = false
    },
    async performMove () {
      if (!this.hasContribPrivilege) return
      this.moveLoading = true
      try {
        await this.$store.dispatch('elements/move', { source: this.elementId, destination: this.pickedFolder.id })
        this.moveModal = false

        // Redirect to the first parent element when available, otherwise redirect to the element's corpus.
        if (this.firstParentId) {
          this.$router.push({ name: 'element-details', params: { id: this.firstParentId } })
        } else {
          this.$router.push({ name: 'navigation', params: { corpusId: this.corpusId } })
        }
      } finally {
        this.moveLoading = false
      }
    },
    async performCreateParent () {
      if (!this.hasContribPrivilege || !this.pickedFolder) return
      this.createParentLoading = true
      try {
        await this.$store.dispatch('elements/createParent', { childId: this.elementId, parentId: this.pickedFolder.id })
        this.createParentModal = false
      } finally {
        this.createParentLoading = false
      }
    },
    select () {
      if (!this.isVerified || !this.hasFeature('selection') || !this.element) return
      if (this.selected) this.$store.dispatch('selection/unselect', this.element)
      else this.$store.dispatch('selection/select', { elements: [this.element] })
    },
    selectAll () {
      if (!this.canSelectAll) return
      this.$store.dispatch('selection/select', { elements: this.filteredElements.results })
    },
    uvUri,
    miradorUri,
    manifestUri
  }
}
</script>

<style scoped>
.dropdown-content > .dropdown-item {
  display: block;
  white-space: nowrap;
}
a.dropdown-item[disabled] {
  color: lightgray !important;
  cursor: not-allowed;
}
.page-select {
  height: 1.75rem;
}
.dropdown-menu.focused {
  display: block;
}
/* The default height set by bulma-switch is excessive within a dropdown */
.dropdown-menu .switch[type="checkbox"] + label {
  height: unset;
}
</style>
