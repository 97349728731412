import { clone, assign } from 'lodash'
import * as api from '@/api'
import { errorParser } from '@/helpers'

export const initialState = () => ({
  // { [repoId]: repo }
  repositories: {},
  // Available but not yet imported repositories (from user credentials)
  available: null
})

export const mutations = {
  setRepos (state, repos) {
    state.repositories = {
      ...state.repositories,
      ...Object.fromEntries(repos.map(repo => [repo.id, repo]))
    }
  },
  removeRepo (state, id) {
    delete state.repositories[id]
  },
  setAvailable (state, available) {
    state.available = clone(available)
  },
  reset (state) {
    assign(state, initialState())
  }
}

export const actions = {

  async retrieve ({ commit }, id) {
    const repo = await api.retrieveRepository(id)
    commit('setRepos', [repo])
  },

  async list ({ commit }, { page = 1 }) {
    // List repositories imported on Arkindex
    const data = await api.listRepositories({ page })
    commit('setRepos', data.results)
    // Set workers in the appropriate store not to retrieve them following a link later
    const workers = data.results.reduce((list, repo) => {
      list.push(...repo.workers)
      return list
    }, [])
    commit('process/setWorkers', workers, { root: true })
    return data
  },

  async listAvailable ({ commit }, { credential, search = null }) {
    try {
      commit('setAvailable', await api.listAvailableRepositories(credential, search))
    } catch (err) {
      commit('setAvailable', null)
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
    }
  },

  async create ({ commit }, payload) {
    /*
     * Creates a repository from Gitlab.
     * This endpoint automatically build an import process
     */
    try {
      const process = await api.createRepository(payload)
      commit('process/setProcess', process, { root: true })
      return process
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
      throw err
    }
  },

  async delete ({ commit }, id) {
    const data = await api.deleteRepository(id)
    commit('removeRepo', id)
    return data
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  actions
}
