<template>
  <section v-if="transcriptions[element.id]">
    <div class="pb-4" v-if="manualTranscriptions.length">
      <strong class="mb-2">Manual</strong>
      <Transcription
        v-for="transcription in manualTranscriptions"
        :key="transcription.id"
        :element="element"
        :transcription="transcription"
      />
    </div>

    <div
      class="pb-4"
      v-for="[workerRunId, transcriptions] in workerRunTranscriptions"
      :key="workerRunId"
    >
      <WorkerRunSummary
        class="mb-2"
        :worker-run-details="workerRunSummaries[workerRunId]"
      />
      <Transcription
        v-for="transcription in transcriptions"
        :key="transcription.id"
        :element="element"
        :transcription="transcription"
      />
    </div>

    <div
      class="pb-4"
      v-for="[versionId, transcriptions] in workerVersionTranscriptions"
      :key="versionId"
    >
      <div class="mb-2">
        <WorkerVersionDetails :worker-version-id="versionId" has-outside-title />
      </div>
      <Transcription
        v-for="transcription in transcriptions"
        :key="transcription.id"
        :element="element"
        :transcription="transcription"
      />
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { orderBy, groupBy } from 'lodash'

import WorkerRunSummary from '@/components/Process/Workers/WorkerRuns/WorkerRunSummary.vue'
import WorkerVersionDetails from '@/components/Process/Workers/Versions/Details.vue'
import Transcription from './Transcription'

export default {
  props: {
    element: {
      type: Object,
      required: true,
      validator: value => value?.id
    }
  },
  components: {
    Transcription,
    WorkerVersionDetails,
    WorkerRunSummary
  },
  computed: {
    ...mapState('elements', ['transcriptions']),
    ...mapState('process', ['workerVersions']),
    /**
     * Transcriptions sorted by descending confidence and ascending text.
     * This sorting is computed once and then re-used by other computed properties
     * to perform the grouping.
     */
    sortedTranscriptions () {
      return orderBy(
        this.transcriptions[this.element.id],
        ['confidence', 'text'],
        ['desc', 'asc']
      )
    },
    manualTranscriptions () {
      return this.sortedTranscriptions.filter(transcription => !transcription.worker_run && !transcription.worker_version_id)
    },
    /**
     * Transcriptions with worker runs, grouped by their worker run ID and sorted by their worker run summary.
     * @returns {[string, object[]][]}
     */
    workerRunTranscriptions () {
      const grouped = groupBy(
        this.sortedTranscriptions.filter(transcription => transcription.worker_run),
        'worker_run.id'
      )
      return orderBy(Object.entries(grouped), ([id]) => this.workerRunSummaries[id])
    },
    /**
     * Worker run summary serializers mapped to their IDs.
     * @returns {{ [id: string]: { id: string, summary: string } }}
     */
    workerRunSummaries () {
      return Object.fromEntries(
        this.sortedTranscriptions
          .filter(transcription => transcription?.worker_run)
          .map(transcription => [transcription.worker_run.id, transcription.worker_run])
      )
    },
    /**
     * Transcriptions with worker versions and no worker runs, grouped by their worker version ID
     * and sorted by worker name, then revision creation date, then worker version ID.
     * If the worker version is not yet loaded, this only sorts by worker version ID.
     * @returns {[string, object[]][]}
     */
    workerVersionTranscriptions () {
      const grouped = groupBy(
        this.sortedTranscriptions.filter(transcription => transcription.worker_version_id && !transcription.worker_run),
        'worker_version_id'
      )
      return orderBy(Object.entries(grouped), [
        ([id]) => this.workerVersions[id]?.worker?.name,
        ([id]) => this.workerVersions[id]?.revision?.created,
        /*
         * Fallback to sorting by worker version ID. When the worker version is not yet loaded,
         * the two functions above will return `null`, and this will be the only sorting criterion.
         */
        '0'
      ])
    }
  },
  methods: {
    ...mapActions('elements', ['listTranscriptions']),
    ...mapActions('process', ['getWorkerVersion'])
  },
  watch: {
    element: {
      immediate: true,
      async handler (newValue) {
        if (!newValue) return
        if (!this.transcriptions[newValue.id]) await this.listTranscriptions({ id: newValue.id })

        /*
         * Once transcriptions are loaded, we need to fetch all worker versions
         * to properly sort the transcriptions that have no worker runs and preserve the older sorting method.
         * TODO: Remove this whole fetching once we fully switch to worker runs
         */
        if (!this.transcriptions[newValue.id]) return
        [...new Set(
          Object.values(this.transcriptions[newValue.id])
            // Get all the worker version IDs of all transcriptions on this element that have no workerRuns
            .filter(transcription => !transcription.worker_run)
            .map(transcription => transcription.worker_version_id)
            // If the worker version ID is not null (not manual) and the version ID was not loaded in the frontend
            .filter(id => id && !(id in this.workerVersions))
          // Retrieve the worker version's information
        )].forEach(id => this.getWorkerVersion(id))
      }
    }
  }
}
</script>
