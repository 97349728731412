import axios from 'axios'
import { PageNumberPaginationParameters, unique } from '.'
import { Dataset, PageNumberPagination, UUID } from '@/types'

interface DatasetCreate extends Omit<Dataset, 'id' | 'state'> {
  corpusId: UUID
}

interface DatasetEdit extends Omit<Dataset, 'state'> {}

export interface DatasetParams extends PageNumberPaginationParameters {
  /**
   * ID of the corpus to retrieve datasets from.
   */
  corpusId: UUID
}

export const listCorpusDataset = unique(
  async ({ corpusId, ...params }: DatasetParams): Promise<PageNumberPagination<Dataset>> =>
    (await axios.get(`/corpus/${corpusId}/datasets/`, { params })).data
)

export const createDataset = unique(
  async ({corpusId, ...data}: DatasetCreate): Promise<Dataset> =>
    (await axios.post(`/corpus/${corpusId}/datasets/`, data)).data
)

export const updateDataset = unique(
  async ({id, ...data}: DatasetEdit): Promise<Dataset> =>
    (await axios.patch(`/datasets/${id}/`, data)).data
)

export const deleteDataset = unique(
  async (id: UUID) => await axios.delete(`/datasets/${id}/`)
)