<template>
  <div class="card">
    <!-- Path with parent and element -->
    <header class="card-header">
      <p class="card-header-title is-block has-text-grey">
        <template v-if="document.parent_id !== document.element_id">
          <span :title="document.parent_type" class="mr-1">
            {{ truncateShort(document.parent_type) }}
          </span>
          <router-link
            :to="{ name: 'element-details', params: { id: document.parent_id } }"
            :title="document.parent_name"
          >
            {{ truncateLong(document.parent_name) }}
          </router-link>
          /
        </template>
        <span :title="document.element_type" class="mr-1">
          {{ truncateShort(document.element_type) }}
        </span>
        <router-link
          :to="{ name: 'element-details', params: { id: document.element_id } }"
          :title="document.element_text"
        >
          {{ truncateLong(document.element_text) }}
        </router-link>
      </p>
    </header>
    <!-- Image -->
    <div class="card-image">
      <figure class="image">
        <img src="@/assets/missingPreview.jpg" v-if="!document.element_image || imgFailed" />
        <img :src="document.element_image" v-on:error="imgFailed = true" v-else />
      </figure>
    </div>
    <!-- Additional information -->
    <div class="card-content">
      <!-- Transcription -->
      <template v-if="document.transcription_id">
        <div class="has-text-centered">
          <span class="text">{{ truncated(document.transcription_text) }}</span>
          <ConfidenceTag
            v-if="Number.isFinite(document.transcription_confidence)"
            :value="document.transcription_confidence"
          />
        </div>
      </template>
      <!-- Classification -->
      <template v-if="document.classification_id">
        <div class="tags has-addons mb-0 is-justify-content-center">
          <span class="tag is-warning">{{ document.classification_name }}</span>
          <ConfidenceTag
            v-if="Number.isFinite(document.classification_confidence)"
            :value="document.classification_confidence"
          />
        </div>
      </template>
      <!-- Metadata -->
      <template v-if="document.metadata_id">
        <div class="has-text-centered">
          <span :title="document.metadata_type">
            <i :class="iconClass(document.metadata_type)"></i>
          </span>
          <span class="metadata-content">
            {{ document.metadata_name }} : <strong>{{ document.metadata_text }}</strong>
          </span>
        </div>
      </template>
      <!-- Entity -->
      <template v-if="document.entity_id">
        <div class="has-text-centered">
          <span>{{ document.entity_text }} ({{ document.entity_type }})</span>
        </div>
      </template>
    </div>
    <!-- View element button -->
    <div class="card-footer">
      <router-link
        class="button is-white"
        :to="{ name: 'element-details', params: { id: document.parent_id }, query: { highlight: document.element_id } }"
      >
        View element
      </router-link>
    </div>
  </div>
</template>

<script>
import { METADATA_TYPES } from '@/config'
import { truncateMixin } from '@/mixins.js'
import ConfidenceTag from '@/components/ConfidenceTag.vue'

export default {
  components: {
    ConfidenceTag
  },
  mixins: [
    truncateMixin
  ],
  props: {
    document: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    METADATA_TYPES,
    imgFailed: false
  }),
  methods: {
    iconClass (type) {
      return METADATA_TYPES[type].icon || 'icon-feather'
    },
    truncated: text => {
      if (text.length < 100) return text
      return text.substring(0, 100) + '[...]'
    }
  }
}
</script>

<style scoped>
span.text {
  margin-right: 0.5em;
}

.card-content {
  position: relative;
}

.card-header-title {
  font-weight: normal;
}

.button {
  width: 100%;
}
</style>
