import axios from 'axios'
import { unique } from '.'

// Create an element type
export const createType = async type => (await axios.post('/elements/type/', type)).data

// Update an element type
export const updateType = async ({ id, ...data }) => (await axios.patch(`/elements/type/${id}/`, data)).data

// Delete an element type
export const destroyType = unique(id => axios.delete(`/elements/type/${id}/`))
