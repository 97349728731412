<template>
  <Modal
    title="Create element"
    :model-value="modal"
    v-on:update:model-value="$emit('update:modal', $event)"
  >
    <form
      v-on:submit.prevent="createElement"
      v-on:keyup.enter="createElement"
    >
      <div class="columns is-compact is-multiline">
        <div class="column is-narrow">
          <figure class="is-inline-block">
            <ElementImage
              class="image is-flex"
              :element="{ ...element, ...selectedElement }"
            />
          </figure>
        </div>

        <div class="column elt-fields">
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Name</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <input
                    type="text"
                    class="input"
                    :class="{ 'is-danger': fieldErrors.name }"
                    v-model="name"
                    :maxlength="ELEMENT_NAME_MAX_LENGTH"
                    :placeholder="defaultName(element.id, type)"
                  />
                  <template v-if="fieldErrors.name">
                    <p class="help is-danger" v-for="err in fieldErrors.name" :key="err">{{ err }}</p>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Type</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <div class="control" title="Filter by type">
                    <span class="select is-fullwidth" :class="{ 'is-danger': fieldErrors.type }">
                      <select ref="typeSelect" v-model="type">
                        <option value="" disabled selected>Type…</option>
                        <option v-for="t in sortedTypes" :key="t.slug" :value="t.slug">
                          {{ truncateSelect(t.display_name) }}
                        </option>
                      </select>
                    </span>
                    <template v-if="fieldErrors.type">
                      <p class="help is-danger" v-for="err in fieldErrors.type" :key="err">{{ err }}</p>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Class</label>
            </div>
            <div class="field-body control">
              <MLClassSelect
                v-if="corpusId"
                v-model="classId"
                class="is-full-width"
                v-model:is-valid="validClassification"
                placeholder="Class…"
                :corpus-id="corpusId"
                allow-empty
                auto-select
                is-fixed
                v-on:submit="createElement"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
    <template v-slot:footer="{ close }">
      <span class="button" v-on:click="close">Discard</span>
      <span
        class="button is-success has-margin-left"
        :class="{ 'is-loading': loading }"
        :disabled="loading || !isValid || null"
        :title="isValid ? 'Create the sub-element' : 'A valid type and class are required to create the element'"
        v-on:click="createElement"
      >Create</span>
    </template>
  </Modal>
</template>

<script>
import { orderBy } from 'lodash'
import { mapMutations, mapState, mapGetters } from 'vuex'

import { ELEMENT_NAME_MAX_LENGTH } from '@/config'
import { errorParser } from '@/helpers'
import { truncateMixin, corporaMixin } from '@/mixins'

import ElementImage from '@/components/Image/ElementImage.vue'
import MLClassSelect from '@/components/MLClassSelect.vue'
import Modal from '@/components/Modal.vue'

export default {
  mixins: [
    truncateMixin,
    corporaMixin
  ],
  components: {
    ElementImage,
    MLClassSelect,
    Modal
  },
  emits: ['update:modal'],
  props: {
    element: {
      type: Object,
      required: true
    },
    modal: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    ELEMENT_NAME_MAX_LENGTH,
    loading: false,
    type: '',
    classId: '',
    // Do not allow form validation on invalid class input
    validClassification: true,
    name: '',
    showModal: false,
    // API fields validation errors
    fieldErrors: {}
  }),
  mounted () {
    this.$refs.typeSelect.focus()
  },
  computed: {
    ...mapState('annotation', ['defaultType', 'defaultClass', 'selectedElement']),
    ...mapGetters('annotation', ['defaultName']),
    corpusId () {
      // Corpus ID for corporaMixin
      return this.element.corpus.id
    },
    sortedTypes () {
      if (!this.corpus?.types) return []
      return orderBy(this.corpus.types, [t => t.display_name.toLowerCase(), t => t.slug])
    },
    isValid () {
      return this.type && this.validClassification
    }
  },
  methods: {
    ...mapMutations('notifications', ['notify']),
    ...mapMutations('annotation', ['setDefaultType']),
    setErrors (error) {
      // Set field errors from API return value
      if (!error) this.fieldErrors = {}
      else if (!error.response || typeof error.response.data !== 'object') this.fieldErrors = { error: errorParser(error) }
      else this.fieldErrors = error.response.data
    },
    async createElement () {
      if (this.loading || !this.isValid) return
      if (!this.type) return this.setErrors({ response: { data: { type: ['A type is required'] } } })
      this.loading = true
      this.setErrors(null)
      try {
        await this.$store.dispatch('annotation/create', {
          corpus: this.corpusId,
          name: this.name || this.defaultName(this.element.id, this.type),
          type: this.type,
          parent: this.element.id,
          image: this.element.zone.image.id,
          polygon: this.selectedElement.zone.polygon,
          classId: this.classId
        })
        this.notify({ type: 'success', text: 'Element created.' })
        // Close modal in case of success
        this.$emit('update:modal', false)
      } catch (e) {
        this.setErrors(e)
        this.notify({ type: 'error', text: `An error occurred during element creation: ${errorParser(e)}` })
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    defaultType: {
      immediate: true,
      handler () {
        this.type = this.defaultType[this.corpusId] || ''
      }
    },
    defaultClass: {
      immediate: true,
      handler () {
        this.classId = this.defaultClass[this.corpusId] || ''
      }
    },
    type () {
      this.fieldErrors.type = null
    }
  }
}
</script>

<style lang="scss" scoped>
.has-margin-left {
  margin-left: auto;
}
.image {
  height: 15rem;
  max-width: 30rem;
}
.elt-fields {
  margin: auto;
  max-width: 25rem;
  & > .field {
    margin-bottom: .20rem;
  }
  & ::placeholder {
    color: #202020;
  }
}
.is-full-width {
  width: 100%
}
.columns .is-compact {
  margin-bottom: 0;
  & > .column {
    padding-bottom: 0;
  }
}
</style>
