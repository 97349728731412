<template>
  <div class="notification is-warning" v-if="!elements.length">
    <slot name="no-results">
      <h4 class="subtitle">Nothing to display</h4>
      Change the filters or use the actions menu to create a new folder or import images.
    </slot>
  </div>
  <div v-else-if="elementsTableLayout || asTable" class="columns is-multiline">
    <table class="table centered-table is-fullwidth is-hoverable">
      <thead>
        <tr>
          <th class="is-two-third-table">Name</th>
          <th>Type</th>
          <th v-if="hasClasses && displayElementClasses">Classes</th>
          <th class="is-narrow" v-if="!disabled">Actions</th>
        </tr>
      </thead>
      <tbody>
        <ElementInLine
          v-for="element in elements"
          :key="element.id"
          :element="element"
          :disabled="disabled"
        />
      </tbody>
    </table>
  </div>
  <div class="columns is-multiline" v-else>
    <div
      class="column"
      :class="size"
      v-for="element in elements"
      :key="element.id"
    >
      <ElementThumbnail
        :element="element"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useDisplayStore } from '@/stores'
import ElementThumbnail from './ElementThumbnail'
import ElementInLine from './ElementInLine'

export default {
  components: {
    ElementThumbnail,
    ElementInLine
  },
  props: {
    elements: {
      type: Array,
      required: true,
      validator: elements => elements.every(element => element.id && element.name && (element.type || element.type_id) && element.corpus)
    },
    /**
     * Make all actions unavailable on elements, to make the list appear as read-only.
     */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Force elements to be displayed as a table and not as thumbnails.
     */
    asTable: {
      type: Boolean,
      default: false
    },
    /**
     * Increase the amount of displayed thumbnail columns from 4 to 5.
     */
    maxSize: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(useDisplayStore, ['elementsTableLayout', 'displayDetails', 'displayElementClasses']),
    size () {
      return this.maxSize
        ? 'is-one-fifth-desktop is-one-third'
        : 'is-one-quarter-desktop is-half'
    },
    /**
     * Whether or not any of the listed elements have loaded classes.
     * This returns True even if every element has no classes at all, just an empty array;
     * the classes column should be displayed anyway, with every row showing no classes.
     * @return {boolean}
     */
    hasClasses () {
      return this.elements.some(element => Array.isArray(element.classes))
    }
  }
}
</script>

<style scoped>
.is-two-third-table {
  width: 66%;
}
</style>
