import axios from 'axios'
import { DOORBELL_ID, DOORBELL_APPKEY } from '@/config'

const DOORBELL_API = 'https://doorbell.io/api'
const DOORBELL_REQUIRED_PARAMETERS = { key: DOORBELL_APPKEY, library: 'javascript' }
const DOORBELL_AXIOS_PARAMS = { withCredentials: false, headers: { Accept: '*' } }

/**
 * Open the doorbell.io form
 */
export const openDoorBellForm = async () => await axios.post(
  `${DOORBELL_API}/applications/${DOORBELL_ID}/open`,
  DOORBELL_REQUIRED_PARAMETERS,
  DOORBELL_AXIOS_PARAMS
)

/**
 * Request body for the Doorbell.io form submission endpoint.
 * @link https://doorbell.io/docs/api
 */
export interface DoorBellFormPayload {
  email: string
  message: string
  name?: string
  url?: string
  ip?: string
  sentiment?: 'positive' | 'neutral' | 'negative'
  tags?: string | string[]
  properties?: unknown
  attachments?: number[]
  nps?: number
  language?: string
}

/**
 * Perform a request to submit the doorbell.io form
 * @link https://doorbell.io/docs/api
 */
export const submitDoorBellForm = async (payload: DoorBellFormPayload) => {
  if (!payload.email || !payload.message) throw new Error('Expected email and message defined in the feedback payload')
  return await axios.post(
    `${DOORBELL_API}/applications/${DOORBELL_ID}/submit`,
    { ...DOORBELL_REQUIRED_PARAMETERS, ...payload },
    DOORBELL_AXIOS_PARAMS
  )
}
