import { clone, assign } from 'lodash'
import * as api from '@/api'
import { removeEmptyStrings, errorParser } from '@/helpers'

export const initialState = () => ({
  providers: null,
  credentials: null
})

export const mutations = {
  setProviders (state, providers) {
    state.providers = clone(providers)
  },
  setCredentials (state, credentials) {
    state.credentials = clone(credentials)
  },
  reset (state) {
    assign(state, initialState())
  }
}

export const actions = {
  async listProviders ({ commit }) {
    try {
      commit('setProviders', await api.listProviders())
    } catch (err) {
      commit('setProviders', null)
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
    }
  },

  async listCredentials ({ commit }, payload = {}) {
    try {
      const data = await api.listCredentials(removeEmptyStrings(payload))
      commit('setCredentials', data)
    } catch (err) {
      commit('setCredentials', null)
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
    }
  },

  async deleteCredentials ({ commit }, { id }) {
    try {
      await api.deleteCredentials(id)
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
    }
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  actions
}
