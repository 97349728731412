import { assign } from 'lodash'
import {
  createGroup,
  updateGroup,
  retrieveGroup,
  deleteGroup,
  listGenericMembers,
  listUserMemberships
} from '@/api'

export const initialState = () => ({
  // { [groupId]: group }
  groups: {},
  // { responsePayload }
  membersPage: {},
  membershipsPage: {}
})

export const mutations = {
  setGroup (state, group) {
    state.groups = {
      ...state.groups,
      [group.id]: group
    }
  },
  removeGroup (state, groupId) {
    delete state.groups[groupId]
  },
  setMembersPage (state, response) {
    state.membersPage = response
  },
  setMembershipsPage (state, response) {
    state.membershipsPage = response
  },
  reset (state) {
    assign(state, initialState())
  }
}

export const actions = {
  async createGroup ({ commit }, params) {
    const createdGroup = await createGroup(params)
    commit('setGroup', createdGroup)
    return createdGroup
  },
  async updateGroup ({ commit }, { groupId, payload }) {
    const updatedGroup = await updateGroup(groupId, payload)
    commit('setGroup', updatedGroup)
    return updatedGroup
  },
  async retrieveGroup ({ commit }, groupId) {
    const data = await retrieveGroup(groupId)
    commit('setGroup', data)
  },
  async deleteGroup ({ commit }, groupId) {
    await deleteGroup(groupId)
    commit('removeGroup', groupId)
  },
  async listMembers ({ commit }, { contentType, contentId, page = 1, typeFilter = null }) {
    const params = { [contentType]: contentId, page }
    if (typeFilter) params.type = typeFilter
    // Lists user members of the group at a specific page
    const data = await listGenericMembers(params)
    commit('setMembersPage', data)
  },
  async listMemberships ({ commit }, { page = 1 }) {
    // Lists groups the authenticated user belongs to on the profile page
    const data = await listUserMemberships({ page })
    commit('setMembershipsPage', data)
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  actions
}
