import axios from 'axios'
import { unique } from '.'
import { Classification, UUID } from '@/types'

/*
 * Only manual classifications can be created from the frontend
 * Thus, worker_version, worker_run_id, confidence and high_confidence parameters are ignored
 */
type ClassificationCreate = Pick<Classification, 'element' | 'ml_class'>

// Create a classification.
export const createClassification = async (classification: ClassificationCreate): Promise<Classification> => (
  (await axios.post('/classifications/', classification)).data
)

// Validate a classification.
export const validateClassification = unique(
  async (id: UUID) => await axios.put(`/classifications/${id}/validate/`)
)

/**
 * Reject a classification.
 * In case the classification has been manually annotated, it will be deleted (HTTP 204_NO_CONTENT)
 */
export const rejectClassification = unique(
  async (id: UUID) => await axios.put(`/classifications/${id}/reject/`)
)
