<template>
  <span>
    <router-link
      class="entity"
      v-if="entity"
      :style="entityStyle(entity.type)"
      :to="{ name: 'entity-details', params: { id: entity.id } }"
    >
      <span
        class="entity-type"
        v-if="displayEntityTypes"
        :style="tagStyle(entity.type)"
        :title="`${entity.type.name} - ${entity.name}`"
      >{{ entity.type.name }}</span>
      <template v-if="tokens.length">
        <Token
          v-bind="token"
          v-for="(token, index) in tokens"
          :key="index"
        />
      </template>
      <span class="entity-text" v-else>{{ text }}</span>
    </router-link>
    <span class="normal-text" v-else>{{ text }}</span>
    <span
      v-if="overflow"
      class="icon has-text-warning has-tooltip-bottom"
      data-tooltip="This entity exceeds the parent's text length"
    >
      <i class="icon-warning"></i>
    </span>
  </span>
</template>

<script>
import { mapState } from 'pinia'
import { parseEntities } from '@/helpers'
import { useDisplayStore } from '@/stores'

export default {
  name: 'Token',
  props: {
    entity: {
      type: Object,
      default: null
    },
    text: {
      type: String,
      required: true
    },
    // This allows this component to properly parse its child tokens, to compute entity offsets relative to its own text.
    offset: {
      type: Number,
      required: true
    },
    children: {
      type: Array,
      default: () => []
    },
    // Show a warning about an entity overflowing its parent
    overflow: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    tagStyle (type) {
      return { 'background-color': `#${type.color}` }
    },
    entityStyle (type) {
      return { 'text-decoration': `underline #${type.color}` }
    }
  },
  computed: {
    ...mapState(useDisplayStore, ['displayEntityTypes']),
    tokens () {
      if (!this.children.length) {
        return []
      }
      return parseEntities(this.text, this.children, this.offset)
    }
  }
}
</script>

<style scoped lang="scss">

$default_color: #4a4a4a;

.entity {

  /* Let text breathe as it's harder to read with the types */
  line-height: 1.6em;

  .entity-type {
    color: white;
    font-size: 80%;
    padding: 1px 2px 1px 3px;
    user-select: none;
    margin-left: 3px;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    display: inline-block;
    border-radius: 5px 0 0 5px;
    direction: ltr;
    line-height: 1.3em;
  }

  .entity-text {
    /* Use a stronger color for entity text */
    color: darken($default_color, 10%);
  }

  /* Default values */
  .entity-type {
    background-color: black;
  }

  /* Showcase explicitly nested entities */
  .entity {
    text-decoration-style: wavy !important;
  }

}

.normal-text {
  /* Same as the body text color */
  color: $default_color;
}
</style>
