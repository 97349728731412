<template>
  <form v-on:submit.prevent="submit">
    <!-- Search form -->
    <div class="card">
      <div class="card-header">
        <div class="field has-addons is-flex-grow-1">
          <div class="control is-expanded">
            <input
              class="input"
              v-model="currentTerms"
              type="text"
              placeholder="Search terms..."
              :disabled="loading || null"
            />
          </div>
          <div class="control is-hidden">
            <HelpModal icon-class="button" title="Help for search" :data="SEARCH_HELP" />
          </div>
          <div class="control">
            <button
              type="submit"
              class="button is-primary"
              :disabled="loading || noSource || null"
              :class="{ 'is-loading': loading }"
            >
              <span class="icon"><i class="icon-search"></i></span>
              <span>Search</span>
            </button>
          </div>
        </div>
      </div>
      <div class="card-content">
        <div v-for="source in Object.keys(sources)" :key="source">
          <input
            :id="source"
            v-model="sources[source]"
            v-on:change="submit"
            type="checkbox"
          /> <label :for="source" class="is-capitalized">{{ source }}</label>
          <!-- The input and label tags must be on the same line for the space between checkbox and label to show -->
        </div>
        <p v-if="noSource" class="help is-danger">Please select at least one source</p>
        <div class="columns">
          <div class="column is-narrow is-align-self-center">
            <label class="label">Sort by</label>
          </div>
          <div class="column">
            <div class="select is-fullwidth">
              <select v-model="sort">
                <option value="relevance">Relevance</option>
                <option value="element_name">Element name</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Facet form -->
    <Facet
      v-for="(values, name) in allFacets"
      :key="name"
      :name="name"
      :values="allFacets[name]"
      v-on:change="submit"
      v-model="checkedFacets[name]"
    />
  </form>
</template>

<script>
import { cloneDeep } from 'lodash'

import { UUID_REGEX } from '@/config'
import { SEARCH_HELP } from '@/help'
import { truncateMixin } from '@/mixins'
import { ensureArray } from '@/helpers'

import HelpModal from '@/components/HelpModal.vue'
import Facet from './Facet'

export default {
  emits: ['update:modelValue'],
  mixins: [
    truncateMixin
  ],
  components: {
    HelpModal,
    Facet
  },
  props: {
    corpusId: {
      type: String,
      required: true,
      validator: value => UUID_REGEX.test(value)
    },
    modelValue: {
      type: Object,
      required: true
    },
    allFacets: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    currentTerms: '',
    sources: {
      element: true,
      transcription: true,
      metadata: true,
      entity: true
    },
    sort: 'relevance',
    SEARCH_HELP,
    checkedFacets: {}
  }),
  methods: {
    reset () {
      this.checkedFacets = {}
      this.submit()
    },
    updateCheckedFacets () {
      const facets = cloneDeep(this.checkedFacets)
      for (const item in this.allFacets) {
        if (!(item in facets)) facets[item] = ''
        if (this.modelValue?.[item]) facets[item] = this.modelValue[item]
      }
      this.checkedFacets = facets
    },
    parseParams () {
      // Parse URL params
      this.currentTerms = this.modelValue.query ? this.modelValue.query.trim() : ''

      if (this.modelValue.sources) {
        const sources = Object.fromEntries(Object.keys(this.sources).map(key => ([key, false])))
        // When there is only one source in the URL, the parameter might be a single string and not an array
        for (const source of ensureArray(this.modelValue.sources)) {
          if (source in sources) sources[source] = true
        }
        this.sources = sources
      }
      this.updateCheckedFacets()
    },
    /**
     * Submit the currently selected query, but remove the `page` parameter from the paginator to restart at the beginning.
     */
    submit () {
      this.$emit('update:modelValue', this.parsedQuery)
    }
  },
  computed: {
    parsedQuery () {
      return {
        query: this.currentTerms.trim() || '*',
        sources: this.selectedSources,
        sort: this.sort,
        ...this.checkedFacets
      }
    },
    noSource () {
      return Object.values(this.sources).every(value => value === false)
    },
    selectedSources () {
      return Object.keys(this.sources).filter(k => this.sources[k])
    }
  },
  watch: {
    allFacets: 'updateCheckedFacets',
    // Search again if we switch to another project, after resetting the facets.
    corpusId: 'reset',
    // Parse from URL again if the URL params change
    modelValue: {
      handler: 'parseParams',
      immediate: true
    },
    sort: 'submit'
  }
}
</script>

<style scoped>
.card {
  margin-bottom: 1em;
}
.text_icon {
  margin-left: 1ch;
}
.label abbr {
  white-space: nowrap;
}
.input[type=text] {
  min-width: 200px;
}
.paddingless-top {
  padding-top: 0;
}
.is-margin-bottom-small {
  margin-bottom: 0.25rem !important;
}
.control.has-icons-right .icon {
  pointer-events: auto;
  cursor: pointer;
}
</style>
