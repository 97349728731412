import { clone, assign } from 'lodash'
import { removeEmptyStrings, errorParser } from '@/helpers'
import * as api from '@/api'

export const initialState = () => ({
  documents: null
})

export const mutations = {
  setDocuments (state, documents) {
    state.documents = clone(documents)
  },
  reset (state) {
    assign(state, initialState())
  }
}

export const actions = {
  async corpus ({ commit }, { corpus, ...payload }) {
    try {
      const data = await api.searchCorpus({ corpus, ...removeEmptyStrings(payload) })
      commit('setDocuments', data)
    } catch (err) {
      commit('setDocuments', null)
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
    }
  },
  async indexCorpus ({ dispatch }, { corpusId, ...payload }) {
    await api.startIndexation({ corpusId, ...payload })
    dispatch('jobs/list', null, { root: true })
  }
}

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  actions
}
