<template>
  <span>
    <a
      class="icon icon-help has-text-info"
      :class="iconClass"
      v-on:click="openModal"
      v-if="!noIcon"
    ></a>
    <Modal :title="title" v-model="showModal">
      <div v-for="(section, index) in data" :key="index">
        <div>
          <strong v-if="section.title">{{ section.title }}</strong>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <p v-for="(line, i) in section.content" v-html="line" :key="i"></p>
        </div>
        <br />
      </div>
    </Modal>
  </span>
</template>

<script>
import Modal from './Modal'
export default {
  components: {
    Modal
  },
  emits: ['update:modelValue'],
  props: {
    title: {
      type: String,
      default: 'Help'
    },
    /*
     * The help data to display:
     * [
     *   {
     *     title: string?
     *     content: string[]
     *   },
     *   ...
     * ]
     */
    data: {
      type: Array,
      required: true
    },
    // The modal's opened state.
    modelValue: {
      type: Boolean,
      default: false
    },
    iconClass: {
      type: String,
      default: ''
    },
    noIcon: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    showModal: false
  }),
  methods: {
    openModal () {
      this.showModal = true
    }
  },
  watch: {
    showModal (value) {
      this.$emit('update:modelValue', value)
    },
    modelValue: {
      handler (value) {
        this.showModal = value
      },
      immediate: true
    }
  }
}
</script>
