<template>
  <div>
    <div :class="{ 'has-shadow': !subHeader }">
      <div class="elt-header is-flex">
        <template v-if="element && !loading">
          <div class="columns has-items-centered">
            <div class="is-flex column is-narrow is-hidden-mobile">
              <template v-if="!subHeader">
                <div
                  v-if="morePaths > 0"
                  :title="morePaths + ' more path' + (morePaths > 1 ? 's' : '') + ' for this element'"
                >
                  <a
                    v-on:click="toggleShowPaths"
                    class="navbar-link"
                  >
                    <div class="tag is-link">
                      +{{ morePaths }}
                    </div>
                  </a>
                </div>
              </template>
            </div>
            <div class="is-flex column">
              <nav class="breadcrumb is-flex has-vpadding">
                <ul>
                  <li>
                    <router-link :to="corpusLink" class="has-text-weight-semibold">
                      {{ truncateShort(element.corpus.name) }}
                    </router-link>
                  </li>
                  <template v-if="currentNeighbor">
                    <li
                      v-for="parent in currentNeighbor.parents"
                      :key="parent.id"
                    >
                      <span class="is-flex has-hpadding">
                        <span class="has-text-grey" :title="typeName(parent.type)">
                          {{ truncateShort(typeName(parent.type)) }}&nbsp;
                        </span>
                        <router-link
                          class="is-paddingless"
                          :to="elementLink(parent.id)"
                          :title="parent.name"
                        >
                          {{ truncateLong(parent.name) }}
                        </router-link>
                      </span>
                    </li>
                  </template>
                  <li>
                    <span class="is-flex has-hpadding has-items-centered">
                      <span class="is-flex has-text-grey" :title="typeName(element.type)">
                        {{ truncateShort(typeName(element.type)) }}&nbsp;
                      </span>
                      <EditableName
                        :instance="element"
                        :enabled="isVerified && canWrite(corpus)"
                        dispatch="elements/patch"
                        class="has-text-weight-bold"
                      />
                    </span>
                  </li>
                </ul>
              </nav>
            </div>

            <div class="is-flex column is-narrow" v-if="currentNeighbor">
              <div>
                <router-link
                  :to="pathLink(previous)"
                  :class="{ 'disabled': !previous }"
                >
                  <i class="icon-arrow-left"></i>
                </router-link>
                {{ currentNeighbor.position + 1 }}
                <router-link
                  :to="pathLink(next)"
                  :class="{ 'disabled': !next }"
                >
                  <i class="icon-arrow-right"></i>
                </router-link>
              </div>
            </div>

            <HeaderActions v-if="!subHeader" :corpus-id="corpusId" :element-id="element.id" />
          </div>
        </template>
        <span v-else class="has-vpadding">Loading…</span>
      </div>
      <transition name="paths">
        <div v-if="element && showPaths && !subHeader">
          <ElementHeader
            v-for="(e, index) in similarElementNeighbors.slice(1)"
            :key="index"
            :element="element"
            :neighbor="e"
            :sub-header="true"
          />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { isEqual } from 'lodash'
import Mousetrap from 'mousetrap'
import { truncateMixin, corporaMixin } from '@/mixins.js'
import EditableName from '@/components/EditableName.vue'
import HeaderActions from '@/components/HeaderActions.vue'
import ElementHeader from './ElementHeader'

export default {
  mixins: [
    truncateMixin,
    corporaMixin
  ],
  name: 'ElementHeader',
  props: {
    element: {
      type: Object,
      required: true
    },
    neighbor: {
      type: Object,
      default: null
    },
    /*
     * Displays path breadcrumb and neighbors navigation only
     * This property is used in recursive display of multiple paths
     */
    subHeader: {
      type: Boolean,
      default: false
    }
  },
  components: {
    HeaderActions,
    EditableName,
    ElementHeader
  },
  data: () => ({
    showPaths: false,
    loading: false
  }),
  mounted () {
    this.showPaths = sessionStorage.getItem('showPaths') === 'true'
    if (!this.subHeader) {
      Mousetrap.bind('ctrl+left', () => {
        if (this.previous) this.$router.push(this.pathLink(this.previous))
      })
      Mousetrap.bind('ctrl+right', () => {
        if (this.next) this.$router.push(this.pathLink(this.next))
      })
    }
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'isVerified', 'hasFeature']),
    ...mapState('elements', ['childrenPagination']),
    ...mapState('elements', { neighborsState: 'neighbors' }),
    corpusId () {
      return this.element?.corpus?.id ?? null
    },
    corpusLink () {
      const route = {
        name: 'navigation',
        params: { corpusId: this.element.corpus.id }
      }
      return route
    },
    allElementNeighbors () {
      return this.neighborsState[this.element.id]?.results
    },
    similarElementNeighbors () {
      /*
       * Filters neighbors from the state to list only items concerning the current element
       * Allows to display the different paths concerning the main element in recursive instances of the ElementHeader
       */
      if (!this.element?.id || !this.allElementNeighbors) return []
      const eltNeighbors = this.allElementNeighbors.filter(n => n.element.id === this.element.id)

      // If we know the parent folder, display paths containing that parent at the closest position
      if (eltNeighbors.length > 1 && this.fromFolderId) {
        eltNeighbors.sort(
          neighbor => {
            /*
             * Sort paths depending on the parent's position: 0 if a direct parent, 1 if grandparent, Infinite if not found
             * As parents are listed from top to bottom, look for the parent index from the end of the array (closer to the element)
             */
            const parents = [...neighbor.parents].reverse()
            const index = parents.findIndex(elt => elt.id === this.fromFolderId)
            return index === -1 ? Infinity : index
          }
        )
      }
      return eltNeighbors
    },
    currentNeighbor () {
      // Current neighbor result, containing its path (ordered parents IDs) and position within siblings
      if (this.subHeader && this.neighbor) {
        // Return neighbor property for recursive sub-headers
        if (this.neighbor.parents.some(p => p === null)) return null
        return this.neighbor
      }
      if (!this.similarElementNeighbors.length) return null
      return this.similarElementNeighbors[0]
    },
    previous () {
      if (!this.currentNeighbor) return null
      const previous = this.allElementNeighbors.find(n => isEqual(n.parents, this.currentNeighbor.parents) && n.position < this.currentNeighbor.position)
      if (!previous) return null
      else return previous.element.id
    },
    next () {
      if (!this.currentNeighbor) return null
      const next = this.allElementNeighbors.find(n => isEqual(n.parents, this.currentNeighbor.parents) && n.position > this.currentNeighbor.position)
      if (!next) return null
      else return next.element.id
    },
    morePaths () {
      return this.similarElementNeighbors.length - 1
    },
    fromFolderId () {
      // Query parameter allowing to order path corresponding to the navigation scheme
      return this.$route.query.from
    }
  },
  methods: {
    getDirectParent (eltId = null) {
      /*
       * Returns the direct parent of a specific element in the current path
       * In case elementId is null, returns the first parent in the path
       */
      const parents = this.currentNeighbor?.parents
      if (!parents || parents.length === 0) return
      if (!eltId) return parents[parents.length - 1]

      const eltIndex = parents.findIndex(elt => elt.id === eltId)
      if (eltIndex <= 0) return
      return parents[eltIndex - 1]
    },
    elementLink (eltId, routeName = 'element-details', parent = null) {
      /* Returns a link to a route (default to element's details) from an element ID */
      if (!eltId) return {}
      const from = parent?.id || this.getDirectParent(eltId)?.id
      return {
        name: routeName,
        params: { id: eltId },
        query: { from }
      }
    },
    pathLink (eltId) {
      /* Returns a link to same route but with a different ID parameter */
      return this.elementLink(eltId, this.$route.name, this.getDirectParent())
    },
    toggleShowPaths () {
      this.showPaths = !this.showPaths
      sessionStorage.setItem('showPaths', this.showPaths)
    },
    async load () {
      if (!this.element || this.subHeader || this.allElementNeighbors) return
      this.loading = true
      try {
        await this.$store.dispatch('elements/listNeighbors', { id: this.element.id })
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    element: {
      handler: 'load',
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.disabled {
  pointer-events: none;
  opacity: 0.4;
}
.has-items-centered > * {
  align-items: center;
}
.elt-header {
  & > .columns {
    width: 100%;
    margin-top: 0rem;
    margin-bottom: 0rem;
    .column {
      padding-top: 0rem;
      padding-bottom: 0rem;
    }
  }
}
.has-shadow {
  box-shadow: 0.1rem 0.1rem 0.6rem lightgray;
}
.has-hpadding {
  padding: 0 0.75em;
}
.has-vpadding {
  padding: .5rem 0 .5rem 0
}
.paths-enter-active {
  transition: all .4s;
}
.paths-leave-active {
  transition: all .4s;
}
.paths-enter-from, .paths-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
</style>
