<template>
  <div class="modal" :class="{ 'is-active': modelValue }">
    <div class="modal-background" v-on:click="close"></div>
    <div class="modal-card" :class="{ 'widen': isLarge }">
      <header class="modal-card-head">
        <slot name="header">
          <p v-if="title" class="modal-card-title">{{ title }}</p>
        </slot>
        <button class="delete" type="button" v-on:click="close"></button>
      </header>
      <section class="modal-card-body">
        <slot :close="close"></slot>
      </section>
      <footer class="modal-card-foot">
        <slot name="footer" :close="close">
          <button
            v-if="showButton"
            class="button"
            type="button"
            v-on:click="close"
          >
            OK
          </button>
        </slot>
      </footer>
    </div>
  </div>
</template>

<script>
import Mousetrap from 'mousetrap'
export default {
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    },
    showButton: {
      type: Boolean,
      default: true
    },
    // Fixes the modal to 90%
    isLarge: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    close () {
      this.$emit('update:modelValue', false)
    },
    onOpen () {
      /*
       * Prevent double scrollbars when the modal is longer than the screen height
       * Inspired by buefy: https://github.com/buefy/buefy/blob/c7d46c37d70e71e448bebc13ff623b4d73a68bdb/src/components/modal/Modal.vue#L147
       */
      document.documentElement.classList.add('is-clipped')
      Mousetrap.bind('esc', this.close)
    },
    onClose () {
      document.documentElement.classList.remove('is-clipped')
      Mousetrap.unbind('esc')
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler (newValue, oldValue) {
        if (newValue) this.onOpen()
        else if (oldValue !== undefined) this.onClose()
      }
    }
  },
  unmounted () {
    // Ensure we activate scrolling again if the modal was opened but suddenly gets destroyed
    this.onClose()
  }
}
</script>

<style lang="scss" scoped>
.modal-card {
  /* Make modal dialogs resizable */
  width: initial;
  max-width: 100%;
  /* Reset styles set by parent elements */
  color: black;
  &.widen {
    width: 90%;
  }
}
footer > button {
  // eventually override the default css for .field.has-addons .control:first-child:last-child .button which removes the rounded edges
  border-radius: 4px !important;
}
</style>
