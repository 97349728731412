<template>
  <div v-if="allLinks && allLinks.length">
    <DropdownContent id="entity-roles" title="Roles">
      <nav class="panel">
        <p class="panel-heading">
          {{ allLinks.length }} role{{ allLinks.length > 1 ? 's' : '' }}
        </p>
        <a v-for="link in allLinks" :key="link.id" class="panel-block">
          <span class="metadata-content">
            <router-link :to="{ name: 'entity-details', params: { id: link.parent.id } }">
              {{ link.parent.name }}
            </router-link>
          </span>
          <span class="metadata-content">{{ link.role.parent_name }} - {{ link.role.child_name }}</span>
          <span class="metadata-content">
            <router-link :to="{ name: 'entity-details', params: { id: link.child.id } }">
              {{ link.child.name }}
            </router-link>
          </span>
        </a>
      </nav>
    </DropdownContent>
    <hr />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import DropdownContent from '@/components/DropdownContent.vue'
export default {
  props: {
    elementId: {
      type: String,
      required: true
    }
  },
  components: {
    DropdownContent
  },
  computed: {
    ...mapState('entity', ['links']),
    allLinks () {
      if (this.links) return this.links.results
      return null
    }
  },
  watch: {
    elementId: {
      immediate: true,
      handler (id) {
        this.$store.dispatch('entity/listLinks', { id })
      }
    }
  }
}
</script>

<style scoped>
.metadata {
  margin-bottom: 1em;
}
.content, .content pre {
  margin-bottom: 0;
}
.panel-block {
  justify-content: space-between;
  cursor: default;
}
.metadata-content {
  flex: 1;
}
</style>
