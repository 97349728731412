<template>
  <Modal
    v-model="modalState"
  >
    <div v-if="loading">
      <span class="loader is-size-2 mx-auto"></span>
    </div>
    <div v-else-if="error" class="notification is-danger">
      Failed to load worker run <ItemId :item-id="workerRunId" />: {{ error }}
    </div>
    <div v-else-if="workerRun">
      <div class="mb-5">
        <h2 class="subtitle has-text-weight-bold">Worker <span class="tag is-uppercase is-light mx-1" :class="workerClass(workerRun.worker_version.worker.type)">{{ workerType?.display_name ?? workerRun.worker_version.worker.type }}</span></h2>
        <div class="mt-2">
          <p>
            <!-- Spacing the field label and value from inside the <strong> due to vue3 space handling -->
            <strong>Name: </strong>
            {{ workerRun.worker_version.worker.name || '—' }}
          </p>
          <p>
            <strong>Git references: </strong>
            <template v-if="workerRun.worker_version.revision.refs.length">
              <span
                class="tag is-light mx-1"
                :class="refsClass(item.type)"
                v-for="item in workerRun.worker_version.revision.refs"
                :key="item"
              >{{ item.name }}</span>
            </template>
            <template v-else>—</template>
          </p>
          <p>
            <strong>Git revision: </strong>
            <a
              :href="workerRun.worker_version.revision.commit_url"
              target="_blank"
            >
              <samp>{{ workerRun.worker_version.revision.hash.slice(0, 8) }}</samp>
            </a>
          </p>
          <p class="mt-2">
            <strong>Worker version ID: </strong>
            <ItemId :item-id="workerRun.worker_version.id" />
          </p>
          <p>
            <strong>Worker version creation date: </strong>
            {{ versionCreatedDate }}
          </p>
          <p>
            <strong>Worker ID: </strong>
            <ItemId :item-id="workerRun.worker_version.worker.id" />
          </p>
        </div>
      </div>
      <hr />
      <div class="mb-5" v-if="!loading && workerRun?.model_version?.id">
        <h2 class="subtitle has-text-weight-bold">Model</h2>
        <div class="mt-2">
          <p>
            <strong>Name: </strong>
            {{ workerRun.model_version.model.name || '—' }}
          </p>
          <p>
            <strong>State: </strong>
            <span class="tag is-light mx-1" :class="modelVersionStateClass(workerRun.model_version.state)">{{ workerRun.model_version.state }}</span>
          </p>
          <p>
            <strong>Size: </strong>
            {{ workerRun.model_version.size }}
          </p>
          <p class="mt-2">
            <strong>Model version ID: </strong>
            <ItemId :item-id="workerRun.model_version.id" />
          </p>
          <p>
            <strong>Model ID: </strong>
            <ItemId :item-id="workerRun.model_version.model.id" />
          </p>
          <details>
            <summary><strong>Configuration</strong></summary>
            <pre>{{ workerRun.model_version.configuration }}</pre>
          </details>
        </div>
      </div>
      <div v-else class="mb-5">
        <div class="notification is-info is-light">
          No model.
        </div>
      </div>
      <hr />
      <div class="mb-5 " v-if="!loading && workerRun?.configuration?.id">
        <h2 class="subtitle has-text-weight-bold">Configuration</h2>
        <div class="mt-2">
          <p>
            <strong>Name: </strong>
            {{ workerRun.configuration.name || '—' }}
          </p>
          <details>
            <summary><strong>Configuration</strong></summary>
            <pre>{{ workerRun.configuration.configuration }}</pre>
          </details>
          <p class="mt-2">
            <strong>Configuration ID: </strong>
            <ItemId :item-id="workerRun.configuration.id" />
          </p>
          <p>
            <strong>Archived: </strong>
            {{ humanBoolean(workerRun.configuration.archived) }}
          </p>
        </div>
      </div>
      <div v-else class="mb-5">
        <div class="notification is-info is-light">
          No configuration.
        </div>
      </div>
      <hr />
      <div v-if="!loading && workerRun?.process?.id">
        <h2 class="subtitle has-text-weight-bold">Process</h2>
        <div class="mt-2">
          <p>
            <strong>Name: </strong>
            {{ workerRun.process.name || '—' }}
          </p>
          <p>
            <strong>State: </strong>
            <span class="tag is-light mx-1" :class="processStateClass(workerRun.process.state)">{{ workerRun.process.state }}</span>
          </p>
          <p class="mt-2">
            <strong>Process ID: </strong>
            <ItemId :item-id="workerRun.process.id" />
          </p>
        </div>
      </div>
    </div>
    <template v-slot:footer="{ close }">
      <span class="button" v-on:click="close">Close</span>
      <div class="field is-grouped ml-auto">
        <router-link
          v-if="workerRun?.model_version?.id"
          class="button"
          :to="{ name: 'model-version', params: { versionId: workerRun?.model_version.id } }"
        >
          View model version
        </router-link>
        <router-link
          v-if="workerRun?.worker_version.id"
          class="button"
          :to="{ name: 'worker-version', params: { versionId: workerRun?.worker_version.id } }"
        >
          View worker version
        </router-link>
        <router-link
          v-if="workerRun?.process.id"
          class="button is-success"
          :to="{ name: 'process-details', params: { id: workerRun.process.id } }"
        >
          View process
        </router-link>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue'
import { WORKER_TYPE_COLORS, GIT_REF_COLORS, MODEL_VERSION_STATE_COLORS, PROCESS_STATE_COLORS } from '@/config'
import { mapActions, mapState, mapMutations } from 'vuex'
import { errorParser, ago } from '@/helpers'
import ItemId from '@/components/ItemId'
import { isEmpty } from 'lodash'

export default {
  emits: ['update:modelValue'],
  components: {
    Modal,
    ItemId
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    workerRunId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    loading: false,
    error: null
  }),
  mounted () {
    if (isEmpty(this.workerTypes)) this.listWorkerTypes()
  },
  computed: {
    ...mapState('process', ['workerRuns', 'workerTypes']),
    workerRun () {
      if (!this.workerRunId | !this.workerRuns) return
      return this.workerRuns[this.workerRunId]
    },
    workerType () {
      if (!this.workerRun || isEmpty(this.workerTypes)) return
      return Object.values(this.workerTypes).find(t => t.slug === this.workerRun.worker_version.worker.type)
    },
    modalState: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    },
    versionCreatedDate () {
      return ago(new Date(this.workerRun.worker_version.revision.created))
    }
  },
  methods: {
    ...mapActions('process', ['getWorkerRun', 'listWorkerTypes']),
    ...mapMutations('notifications', ['notify']),
    humanBoolean (value) {
      if (value) return 'Yes'
      return 'No'
    },
    async workerRunDetails () {
      if (!this.workerRunId || this.loading || this.workerRuns?.[this.workerRunId]) return
      this.loading = true
      this.error = null
      try {
        await this.getWorkerRun(this.workerRunId)
      } catch (err) {
        this.error = errorParser(err)
      } finally {
        this.loading = false
      }
    },
    workerClass: type => {
      return WORKER_TYPE_COLORS[type]?.cssClass ?? WORKER_TYPE_COLORS.default.cssClass
    },
    refsClass: type => {
      return GIT_REF_COLORS[type]
    },
    modelVersionStateClass: state => {
      return MODEL_VERSION_STATE_COLORS[state]
    },
    processStateClass: state => {
      return PROCESS_STATE_COLORS[state]?.cssClass ?? PROCESS_STATE_COLORS.default.cssClass
    }
  },
  watch: {
    workerRunId: 'workerRunDetails',
    modalState: {
      immediate: true,
      handler (newValue) {
        if (newValue) this.workerRunDetails()
      }
    }
  }
}
</script>
