<template>
  <div
    class="content"
    :style="orientationStyle(transcription.orientation)"
  >
    <blockquote class="transcription-box">
      <template v-if="transcriptionEntities.length">
        <Token
          v-for="(token, index) in tokens"
          v-bind="token"
          :key="index"
        />
      </template>
      <template v-else>{{ transcription.text }}</template>
    </blockquote>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { MANUAL_WORKER_VERSION } from '@/config'
import { parseEntities, orientationStyle } from '@/helpers'
import Token from './Token'

export default {
  props: {
    transcription: {
      type: Object,
      required: true
    },
    /**
     * Filter TranscriptionEntities that do not have a WorkerRun by WorkerVersion.
     * If both this parameter and the WorkerRun filter are unset, no TranscriptionEntities are displayed.
     * To display entities created without a WorkerVersion or a WorkerRun, use the `MANUAL_WORKER_VERSION` const.
     * Both parameters may not be set at once.
     */
    workerVersionFilter: {
      type: String,
      default: ''
    },
    /**
     * Filter TranscriptionEntities by WorkerRun UUID.
     * If both this parameter and the WorkerVersion filter are unset, no TranscriptionEntities are displayed.
     * Both parameters may not be set at once.
     */
    workerRunFilter: {
      type: String,
      default: ''
    }
  },
  components: {
    Token
  },
  computed: {
    ...mapState('entity', ['inTranscription']),
    loaded () {
      const { results, count, loaded } = this.inTranscription?.[this.transcription.id] ?? {}
      return results && loaded >= count
    },
    transcriptionEntities () {
      let transcriptionEntities = this.inTranscription?.[this.transcription.id]?.results

      if (!this.loaded || !transcriptionEntities?.length || (!this.workerVersionFilter && !this.workerRunFilter)) return []
      if (this.workerVersionFilter && this.workerRunFilter) throw new Error('The WorkerVersion and WorkerRun filters cannot be used at the same time.')

      if (this.workerVersionFilter) {
        transcriptionEntities = transcriptionEntities.filter(transcriptionEntity =>
          !transcriptionEntity.worker_run &&
          (transcriptionEntity.worker_version_id || MANUAL_WORKER_VERSION) === this.workerVersionFilter
        )
      } else if (this.workerRunFilter) transcriptionEntities = transcriptionEntities.filter(transcriptionEntity => transcriptionEntity.worker_run?.id === this.workerRunFilter)

      return transcriptionEntities
    },
    tokens () {
      return parseEntities(this.transcription.text, this.transcriptionEntities)
    }
  },
  methods: {
    orientationStyle
  }
}
</script>

<style scoped>
.transcription-box {
  padding-top: .75rem;
  padding-bottom: .75rem;
  overflow-x: auto;
  white-space: pre-line;
}
</style>
