<template>
  <form class="form" v-on:submit.prevent="updateTranscription">
    <div class="field">
      <div class="control">
        <textarea
          ref="textInput"
          class="input"
          :class="{ 'is-loading': loading }"
          :style="orientationStyle(newOrientation)"
          v-model="newText"
          v-on:keydown.enter.exact.prevent="updateTranscription"
          placeholder="Transcription text"
          :rows="rows"
          :disabled="loading || null"
          required
        ></textarea>
        <template v-if="fieldErrors.text">
          <p class="help is-danger" v-for="err in fieldErrors.text" :key="err">{{ err }}</p>
        </template>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field">
          <div class="control">
            <div class="select is-small">
              <select
                v-model="newOrientation"
                :disabled="loading || null"
                required
              >
                <option
                  v-for="(textOrientation, key) in TEXT_ORIENTATIONS"
                  :key="key"
                  :value="key"
                  :title="textOrientation.display"
                >
                  {{ textOrientation.display }}
                </option>
              </select>
              <template v-if="fieldErrors.orientation">
                <p
                  class="help is-danger"
                  v-for="err in fieldErrors.orientation"
                  :key="err"
                >
                  {{ err }}
                </p>
              </template>
            </div>
          </div>
        </div>
        <div class="field">
          <div class="control has-text-right">
            <button
              type="button"
              class="button"
              v-on:click="$emit('close')"
              :disabled="loading || null"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="button is-primary"
              :disabled="!canUpdate || loading || null"
              :title="canUpdate ? 'Update transcription' : updateDisabledTitle"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapMutations } from 'vuex'
import { TEXT_ORIENTATIONS } from '@/config'
import { errorParser, orientationStyle } from '@/helpers'

/**
 * A transcription edition form.
 * Emits a `close` event to notify the parent component when a transcription was edited successfully
 * or when the user clicked the Cancel button.
 */
export default {
  emits: ['close'],
  props: {
    element: {
      type: Object,
      required: true
    },
    transcription: {
      type: Object,
      required: true,
      // Cannot edit a transcription with a worker version or worker run
      validator: transcription => transcription?.text && transcription?.orientation && !transcription.worker_version_id && !transcription.worker_run
    }
  },
  data: () => ({
    newText: '',
    newOrientation: '',
    loading: false,
    fieldErrors: {},
    TEXT_ORIENTATIONS
  }),
  mounted () {
    this.newText = this.transcription.text
    this.newOrientation = this.transcription.orientation
    this.$refs.textInput.focus()
  },
  computed: {
    /**
     * How many rows to allow in the textarea.
     */
    rows () {
      return Math.max(1, this.newText.split(/\r\n|\r|\n/).length)
    },
    canUpdate () {
      return this.newText.trim() !== '' && this.newOrientation && this.newOrientation in TEXT_ORIENTATIONS
    },
    updateDisabledTitle () {
      if (!this.newOrientation || !(this.newOrientation in TEXT_ORIENTATIONS)) return 'A valid text orientation value is required'
      else if (!this.newText.trim()) return 'A transcription text is required'
      return null
    }
  },
  methods: {
    ...mapMutations('notifications', ['notify']),
    orientationStyle,
    setErrors (error) {
      // Set field errors from API return value
      if (!error) this.fieldErrors = {}
      else if (!error.response || typeof error.response.data !== 'object') this.fieldErrors = { error: errorParser(error) }
      else this.fieldErrors = error.response.data
    },
    async updateTranscription () {
      if (!this.canUpdate) return
      this.loading = true
      try {
        await this.$store.dispatch('elements/updateTranscription', {
          elementId: this.element.id,
          id: this.transcription.id,
          text: this.newText,
          orientation: this.newOrientation
        })
        // Notify the parent component that the edition is complete
        this.$emit('close')
      } catch (e) {
        this.setErrors(e)
        this.notify({ type: 'error', text: `An error occurred when updating the transcription: ${errorParser(e)}` })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
