import axios from 'axios'
import { assign, merge } from 'lodash'
import * as api from '@/api'
import { TASK_POLLING_DELAY, PROCESS_POLLING_DELAY } from '@/config'
import { errorParser, removeEmptyStrings } from '@/helpers'

export const initialState = () => ({
  /*
   * Stores workers. This does includes workers that cannot be executed but retrieved individually
   * { [workerId]: worker }
   */
  workers: {},
  // { [workerId]: { [workerConfigurationId]: workerConfiguration } }
  workerTypes: {},
  // available worker types on the instance
  workerConfigurations: {},
  // { [workerVersionId]: workerVersion }
  workerVersions: {},
  // { [processId]: { [workerRunId]: { workerRun } }
  processWorkerRuns: {},
  // { [workerRunId]: { workerRun } }
  workerRuns: {},
  // { [processID]: processDetails }
  processes: {},
  // { number, next, results: processId[] }
  processPage: {},
  // { [processId]: ElementsPaginatedResponse }
  processElementsPage: {},
  /**
   * Tasks for the current process, with an added `timeoutId` property for task polling
   */
  tasks: {},
  // { [taskId]: TaskArtifacts }
  artifacts: {},
  // ID of the process getting polled, null if it is turned off
  pollingProcessId: null,
  // Timeout ID for the process polling, null if it is turned off
  processTimeoutId: null
})

export const mutations = {
  setWorkers (state, workers) {
    state.workers = {
      ...state.workers,
      ...Object.fromEntries(workers.map(worker => [worker.id, worker]))
    }
  },

  setWorkerTypes (state, workerTypes) {
    state.workerTypes = {
      ...state.workerTypes,
      ...Object.fromEntries(workerTypes.map(type => [type.id, type]))
    }
  },

  setWorkerConfigurations (state, { workerId, configurations }) {
    state.workerConfigurations = {
      ...state.workerConfigurations,
      [workerId]: {
        ...(state.workerConfigurations[workerId] ?? {}),
        ...Object.fromEntries(configurations.map(c => [c.id, c]))
      }
    }
  },

  removeWorkerConfiguration (state, { workerId, configurationId }) {
    if (!state.workerConfigurations[workerId]?.[configurationId]) return
    const configurations = { ...state.workerConfigurations[workerId] }
    delete configurations[configurationId]
    state.workerConfigurations[workerId] = configurations
  },

  clearWorkerConfigurations (state, workerId) {
    state.workerConfigurations[workerId] = {}
  },

  setWorkerVersions (state, versions) {
    state.workerVersions = {
      ...state.workerVersions,
      ...Object.fromEntries(versions.map(v => [v.id, v]))
    }
    state.workers = {
      ...state.workers,
      ...Object.fromEntries(versions.map(v => [v.worker.id, v.worker]))
    }
  },

  setProcessWorkerRuns (state, { processId, workerRuns }) {
    if (!state.processWorkerRuns[processId]) state.processWorkerRuns[processId] = {}
    state.processWorkerRuns = {
      ...state.processWorkerRuns,
      [processId]: {
        ...state.processWorkerRuns[processId],
        ...Object.fromEntries(workerRuns.map(workerRun => [workerRun.id, workerRun]))
      }
    }
  },

  removeProcessWorkerRun (state, { processId, workerRunId }) {
    const runs = state.processWorkerRuns[processId]
    if (!runs || !runs[workerRunId]) return
    delete runs[workerRunId]
    Object.values(runs).forEach(workerRun => {
      workerRun.parents = workerRun.parents.filter(parent => parent !== workerRunId)
    })
  },

  /**
   * Remove all worker runs of given process
   */
  removeProcessWorkerRuns (state, { processId }) {
    state.processWorkerRuns[processId] = {}
  },

  setWorkerRuns (state, workerRun) {
    state.workerRuns = {
      ...state.workerRuns,
      [workerRun.id]: workerRun
    }
  },

  setProcess (state, process) {
    const existingProcess = state.processes[process.id] ?? {}
    const newProcess = {
      ...existingProcess,
      ...process
    }
    /**
     * Only mark a process as "complete" if we have the fields that are only found in RetrieveProcess and nowhere else
     * or if the existing process was already complete
     */
    newProcess._complete = existingProcess._complete || ('farm' in newProcess && Array.isArray(newProcess.tasks))

    if (Array.isArray(process.tasks)) {
      const newIds = process.tasks.map(task => task.id)

      // Take all timeout IDs of tasks that will be deleted, and stop polling them
      Object.values(state.tasks)
        .filter(task => !newIds.includes(task.id) && task.timeoutId !== null)
        .forEach(task => clearTimeout(task.timeoutId))

      state.tasks = Object.fromEntries(process.tasks.map(task => [task.id, {
        // Default value for the timeoutId
        timeoutId: null,
        // Previously stored task, if it exists
        ...(state.tasks[task.id] ?? {}),
        // The new task
        ...task,
        // The task's process, which is returned by RetrieveTask but not by RetrieveProcess
        process_id: process.id
      }]))

      // Do not duplicate the process' tasks within state.processes and state.tasks
      delete newProcess.tasks
    }

    state.processes = {
      ...state.processes,
      [newProcess.id]: newProcess
    }
  },

  setProcesses (state, processes) {
    /**
     * Set a list of processes. The `_complete` attribute is set to false as
     * a slim serializer is used when listing processes
     */
    state.processes = {
      ...state.processes,
      ...processes.reduce((obj, process) => {
        obj[process.id] = { ...merge({ _complete: false }, state.processes[process.id] || {}, process) }
        return obj
      }, {})
    }
  },

  setProcessPage (state, page) {
    state.processPage = {
      ...state.processPage,
      ...page,
      results: page.results.map(process => process.id)
    }
  },

  removeProcess (state, processId) {
    delete state.processes[processId]
  },

  setProcessElementsPage (state, { processId, response }) {
    if (!Number.isInteger(response.count)) delete response.count
    const newResponse = { ...state.processElementsPage[processId], ...response }
    state.processElementsPage = { ...state.processElementsPage, [processId]: newResponse }
  },

  setPollingProcessId (state, id) {
    state.pollingProcessId = id
  },

  setProcessTimeoutId (state, id) {
    state.processTimeoutId = id
  },

  setTask (state, task) {
    state.tasks[task.id] = {
      timeoutId: null,
      ...(state.tasks[task.id] || {}),
      ...task
    }
  },

  setTaskTimeoutId (state, { taskId, timeoutId }) {
    if (!state.tasks[taskId]) throw new Error(`Unknown task ${taskId}`)
    state.tasks[taskId].timeoutId = timeoutId
  },

  setTaskArtifacts (state, { taskId, artifacts }) {
    state.artifacts = { ...state.artifacts, [taskId]: artifacts }
  },

  stopPolling (state) {
    // Stop the process polling
    state.pollingProcessId = null
    if (state.processTimeoutId !== null) {
      clearTimeout(state.processTimeoutId)
      state.processTimeoutId = null
    }

    // Stop all task pollings
    state.tasks = Object.fromEntries(
      Object.entries(state.tasks).map(([id, task]) => {
        if (task.timeoutId !== null) {
          clearTimeout(task.timeoutId)
          task.timeoutId = null
        }
        return [id, task]
      })
    )
  },

  stopTaskPolling (state, id) {
    if (!state.tasks[id] || state.tasks[id].timeoutId === null) return

    clearTimeout(state.tasks[id].timeoutId)
    state.tasks = {
      ...state.tasks,
      [id]: {
        ...state.tasks[id],
        timeoutId: null
      }
    }
  },

  reset (state) {
    assign(state, initialState())
  }
}

export const actions = {
  async listWorkers ({ commit }, params) {
    const resp = await api.listWorkers(params)
    commit('setWorkers', resp.results)
    return resp
  },

  async listWorkerTypes ({ commit }, params) {
    const resp = await api.listWorkerTypes(params)
    commit('setWorkerTypes', resp.results)
    return resp
  },

  async listConfigurations ({ commit, dispatch }, { workerId, page = 1, ...options }) {
    if (page === 1) commit('clearWorkerConfigurations', workerId)
    try {
      const data = await api.listWorkerConfigurations({ workerId, page, ...options })
      commit('setWorkerConfigurations', { workerId, configurations: data.results })
      if (!data || !data.number || page !== data.number) {
        // Avoid any loop
        throw new Error(`Pagination failed listing worker configurations for worker "${workerId}"`)
      }
      // Load other pages
      if (data.next) dispatch('listConfigurations', { workerId, page: page + 1, ...options })
    } catch (err) {
      if (err?.response?.status === 403) {
        throw err
      } else {
        commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
      }
    }
  },

  async createConfiguration ({ commit }, { workerId, configuration }) {
    const resp = await api.createWorkerConfiguration({ workerId, configuration })
    commit('setWorkerConfigurations', { workerId, configurations: [resp] })
    return resp
  },

  async getConfiguration ({ commit }, { workerId, configurationId }) {
    const resp = await api.retrieveWorkerConfiguration(configurationId)
    commit('setWorkerConfigurations', { workerId, configurations: [resp] })
  },

  async updateConfiguration ({ state, commit }, { workerId, configurationId, ...data }) {
    /*
     * Keep the current archived attribute of the configuration, to see if the backend will update it.
     * When the backend changes the archived state, we need to remove the configuration from the list
     * in the store: since we only list configurations that are either archived or not, and never
     * both at once, the configuration should disappear from the list when it switches state.
     * Doing so ourselves, without calling listConfigurations again, is cleaner and avoids stale reads.
     */
    const currentArchivedState = state.workerConfigurations[workerId]?.[configurationId]?.archived

    let resp
    try {
      resp = await api.updateWorkerConfiguration({ id: configurationId, ...data })
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
      return
    }

    if (currentArchivedState !== undefined && resp.archived !== currentArchivedState) {
      commit('removeWorkerConfiguration', { workerId, configurationId })
    } else {
      commit('setWorkerConfigurations', { workerId, configurations: [resp] })
    }
  },

  async listVersions ({ commit }, { workerId, ...params }) {
    const resp = await api.listWorkerVersions({ workerId, ...params })
    commit('setWorkerVersions', resp.results)
    return resp
  },

  async getWorkerVersion ({ commit }, workerVersionId) {
    const resp = await api.retrieveWorkerVersion(workerVersionId)
    commit('setWorkerVersions', [resp])
  },

  async getWorker ({ commit }, workerId) {
    const resp = await api.retrieveWorker(workerId)
    commit('setWorkers', [resp])
  },

  async listWorkerRuns ({ commit, dispatch }, { processId, page = 1 }) {
    try {
      // Automatically list all worker runs for a process through infinite pagination
      const data = await api.listWorkerRuns({ id: processId, page })
      commit('setProcessWorkerRuns', { processId, workerRuns: data.results })
      if (!data || !data.number || page !== data.number) {
        // Avoid any loop
        throw new Error(`Pagination failed listing worker runs for process "${processId}"`)
      }
      // Load other pages
      if (data.next) dispatch('listWorkerRuns', { processId, page: page + 1 })
    } catch (err) {
      commit('setProcessWorkerRuns', { processId, workerRuns: [] })
      throw err
    }
  },

  async createWorkerRun ({ commit }, { processId, workerRun }) {
    const data = await api.createWorkerRun({ id: processId, workerRun })
    commit('setProcessWorkerRuns', { processId, workerRuns: [data] })
  },

  async updateWorkerRun ({ commit }, { processId, workerRunId, payload }) {
    const data = await api.updateWorkerRun({ id: workerRunId, payload })
    commit('setProcessWorkerRuns', { processId, workerRuns: [data] })
  },

  async deleteWorkerRun ({ commit }, { processId, workerRunId }) {
    await api.deleteWorkerRun(workerRunId)
    commit('removeProcessWorkerRun', { processId, workerRunId })
  },

  async getWorkerRun ({ commit }, workerRunId) {
    const resp = await api.retrieveWorkerRun(workerRunId)
    commit('setWorkerRuns', resp)
  },

  async clearProcess ({ commit }, { processId }) {
    await api.clearProcess(processId)
    commit('removeProcessWorkerRuns', { processId })
  },

  async listProcesses ({ commit }, params) {
    try {
      const data = await api.listProcesses(removeEmptyStrings(params))
      commit('setProcesses', data.results)
      commit('setProcessPage', data)
    } catch (err) {
      commit('setProcessPage', { results: [] })
      throw err
    }
  },

  async listTemplates ({ commit }, { page = 1, name = '' } = {}) {
    const data = await api.listTemplates({ mode: 'template', page, name })
    commit('setProcesses', data.results)
    commit('setProcessPage', data)
    return data
  },

  async createProcess ({ commit }, payload) {
    const data = await api.createProcess(payload)
    commit('setProcess', data)
    return data
  },

  async createProcessTemplate ({ commit }, { processId, payload }) {
    const data = await api.createProcessTemplate({ id: processId, payload })
    commit('setProcess', data)
    return data
  },

  async applyProcessTemplate ({ commit }, { templateId, payload }) {
    const data = await api.applyProcessTemplate({ id: templateId, payload })
    commit('removeWorkerProcessRuns', { processId: payload.process_id })
    commit('setProcess', data)
    return data
  },

  async updateProcess ({ commit }, { processId, payload }) {
    const data = await api.updateProcess({ id: processId, payload })
    commit('setProcess', data)
    return data
  },

  async startProcess ({ commit }, { processId, payload }) {
    const data = await api.startProcess({ id: processId, payload })
    commit('setProcess', data)
  },

  async startTraining ({ commit }, payload) {
    const data = await api.createTraining(payload)
    commit('setProcess', data)
    return data
  },

  async retrieveProcess ({ commit }, processId) {
    const data = await api.retrieveProcess(processId)
    commit('setProcess', data)
  },

  async deleteProcess ({ commit }, processId) {
    const response = await api.deleteProcess(processId)
    if (response.status === 202) {
      commit(
        'notifications/notify',
        { type: 'success', text: `Deletion of process ${processId} has been recorded and will be performed soon.`, timeout: 10000 },
        { root: true }
      )
    } else {
      commit('removeProcess', processId)
    }
    return response
  },

  async retryProcess ({ commit }, processId) {
    const data = await api.retryProcess(processId)
    commit('setProcess', data)
  },

  async listProcessElements ({ state, commit }, { processId, cursor = '' }) {
    // Handle url requests for cursor pagination
    const payload = { id: processId, cursor }
    // Automatically fetch elements count if needed
    const processEltsPage = state.processElementsPage[processId]
    if (!processEltsPage || processEltsPage.count == null || !cursor) payload.with_count = true
    const response = await api.listProcessElements(payload)
    // Override the process loaded page
    commit('setProcessElementsPage', { processId, response })
  },

  async updateTask ({ commit }, { url, ...payload }) {
    try {
      const task = await api.updateTask(url, payload)
      commit('setTask', task)
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
    }
  },

  async getTaskArtifacts ({ commit }, { id, url }) {
    try {
      const data = (await axios.get(url)).data
      commit('setTaskArtifacts', { taskId: id, artifacts: data })
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
    }
  },

  async fromFiles ({ commit }, payload) {
    try {
      const data = await api.importFromFiles(payload)
      return data
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
      throw err
    }
  },

  async transkribus ({ commit }, payload) {
    const process = await api.transkribusImport(payload)
    // Add a default corpus corresponding to the created corpus (to avoid a stale read)
    commit(
      'corpora/addDefaultCorpus',
      {
        name: `Transkribus collection n°${payload.collection_id}`,
        id: process.corpus
      },
      { root: true }
    )
    return process
  },

  async stop ({ commit }, { id }) {
    const data = await api.updateProcess({ id, payload: { state: 'stopping' } })
    commit('setProcess', data)
  },

  startPolling (store, id) {
    store.commit('stopPolling')
    store.commit('setPollingProcessId', id)
    const poll = async () => {
      // Polling has been stopped or is running on another process
      if (!store.state.processTimeoutId || store.state.pollingProcessId !== id) return

      try {
        await store.dispatch('retrieveProcess', id)
      } catch (err) {
        store.commit('notifications/notify', { type: 'error', text: `Error while fetching process: ${errorParser(err)}` }, { root: true })

        // Abort polling on HTTP 4xx
        if (err.response?.status >= 400 && err.response?.status < 500) {
          store.commit('stopPolling')
          return
        }
      }

      // Check again, because the polling might have been stopped while we were awaiting the HTTP request.
      if (!store.state.processTimeoutId || store.state.pollingProcessId !== id) return

      store.commit('setProcessTimeoutId', setTimeout(poll, PROCESS_POLLING_DELAY))
    }

    // Make the first call; poll cannot be called directly due to the initial timeout ID check
    store.commit('setProcessTimeoutId', setTimeout(poll, 0))
  },

  startTaskPolling (store, id) {
    store.commit('stopTaskPolling', id)
    const poll = async () => {
      const task = store.state.tasks[id]
      // Polling has been stopped, process has changed or task was deleted
      if (!task || !task.timeoutId) return

      try {
        store.commit('setTask', (await axios.get(task.url)).data)
      } catch (err) {
        store.commit('notifications/notify', { type: 'error', text: `Error while fetching task ${task.slug}: ${errorParser(err)}` }, { root: true })

        // Abort polling on HTTP 4xx
        if (err.response.status && err.response.status >= 400 && err.response.status < 500) {
          store.commit('stopTaskPolling', id)
          return
        }
      }

      // Check again, because the polling might have been stopped while we were awaiting the HTTP request.
      if (!store.state.tasks[id]?.timeoutId) return

      store.commit('setTaskTimeoutId', {
        taskId: id,
        timeoutId: setTimeout(poll, TASK_POLLING_DELAY)
      })
    }

    // Make the first call; poll cannot be called directly due to the initial timeout ID check
    store.commit('setTaskTimeoutId', {
      taskId: id,
      timeoutId: setTimeout(poll, 0)
    })
  },

  async selectFailures ({ dispatch, commit }, processId) {
    try {
      await api.selectProcessFailures(processId)
      dispatch('selection/get', {}, { root: true })
      commit('notifications/notify', { type: 'success', text: 'Elements with failures have been added to your selection' }, { root: true })
    } catch (err) {
      commit('notifications/notify', { type: 'error', text: errorParser(err) }, { root: true })
    }
  }
}

export const getters = {
  getWorkerVersions: state => workerId => Object.values(state.workerVersions).filter(version => version.worker.id === workerId)
}

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  actions,
  getters
}
