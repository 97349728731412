<template>
  <div class="html-metadata">
    <div v-for="meta in data" :key="meta.id" class="is-marginless">
      <WorkerRunSummary
        class="is-pulled-right"
        v-if="meta.worker_run"
        :worker-run-details="meta.worker_run"
        icon-only
      />
      <WorkerVersionDetails
        class="is-pulled-right"
        v-else-if="meta.worker_version"
        :worker-version-id="meta.worker_version"
        has-dropdown-title
      />
      <!-- Pass v-bind as the last attribute, allowing to override the metadata value -->
      <MetadataActions
        class="value is-actions is-paddingless"
        :metadata="meta"
        v-bind="$attrs"
      />
      <div class="content">
        <h4 class="value is-marginless">{{ meta.name }}</h4>
        <div class="value">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <blockquote v-html="md.render(meta.value)"></blockquote>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MarkdownIt from 'markdown-it'
import WorkerRunSummary from '@/components/Process/Workers/WorkerRuns/WorkerRunSummary.vue'
import WorkerVersionDetails from '@/components/Process/Workers/Versions/Details.vue'
import MetadataActions from './MetadataActions'

export default {
  components: {
    MetadataActions,
    WorkerRunSummary,
    WorkerVersionDetails
  },
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    md: MarkdownIt({ html: true })
  })
}
</script>

<style scoped>
.html-metadata {
  margin-bottom: 1rem;
}
.content:hover > .is-actions {
  display: block !important;
}
.value {
  padding-bottom: 0.3em;
}
.content:hover > h4 {
  background-color: #fafafa;
}
</style>
